import Button from "./Button";
import joinClasses from "helpers/joinClasses";
import { useEffect } from "react";

export function ShowMenuSideButton({
  className = "",
  onClick,
  children,
  stateOpen,
  ...props
}) {
  return (
    <Button
      {...props}
      className={joinClasses(
        className,
        "fixed bottom-5 right-5  h-10 w-10 rounded-full"
      )}
      onClick={onClick}
    >
      {children}
    </Button>
  );
}
