import "../styles/globals.css";

function LoaderPage() {
  return (
    <div className="fixed z-50 flex h-screen w-screen flex-col items-center justify-center overflow-hidden bg-yellow">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LoaderPage;
