import { MainNavbar } from "components";
import { BasicFooter } from "components";
import joinClasses from "helpers/joinClasses";

export function YellowTemplate({
  children,
  isConnected = true,
  onClick,
  className,
  title = "",
  ...props
}) {
  if (title.length) {
    window.document.title = `Bonappli - ${title}`;
  } else {
    window.document.title = "Bonappli - Dashboard";
  }

  return (
    <div
      {...props}
      className={joinClasses(
        className,
        "flex min-h-screen flex-col bg-yellow-lightest font-poppins"
      )}
    >
      <header>
        <MainNavbar onClick={onClick} isConnected={isConnected} />
      </header>
      <main className="mb-auto animate__animated animate__faster animate__fadeIn">
        {children}
      </main>
      <footer className="mt-7  w-full md:mt-10">
        <BasicFooter />
      </footer>
    </div>
  );
}
