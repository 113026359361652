import joinClasses from "helpers/joinClasses";

export function Card({ children, className = "", style }) {
  return (
    <div
      style={style}
      className={joinClasses(className, "rounded-xl bg-white shadow-light")}
    >
      {children}
    </div>
  );
}

export function SmoothCard({ children, padding, className = "", style, id }) {
  return (
    <div
      style={style}
      id={id}
      className={joinClasses(
        className,
        `${padding && "p-7"} rounded-xl bg-white shadow-smooth`
      )}
    >
      {children}
    </div>
  );
}
