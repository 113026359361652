// /!\ don't add font on this list /!\

const googleFontsList = [
  {
    family: "Roboto",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
    },
  },
  {
    family: "Open Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
    },
  },
  {
    family: "Noto Sans JP",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/notosansjp/v40/-F62fjtqLzI2JPCgQBnw7HFowAIO2lZ9hg.otf",
    },
  },
  {
    family: "Lato",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/lato/v22/S6uyw4BMUTPHvxk6XweuBCY.ttf",
    },
  },
  {
    family: "Montserrat",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/montserrat/v23/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
    },
  },
  {
    family: "Poppins",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/poppins/v19/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
    },
  },
  {
    family: "Roboto Condensed",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/robotocondensed/v24/ieVl2ZhZI2eCN5jzbjEETS9weq8-59WxDCs5cvI.ttf",
    },
  },
  {
    family: "Source Sans Pro",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/sourcesanspro/v19/6xK3dSBYKcSV-LCoeQqfX1RYOo3aP6TkmDZz9g.ttf",
    },
  },
  {
    family: "Oswald",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/oswald/v41/TK3_WkUHHAIjg75cFRf3bXL8LICs1_FvgUFoZAaRliE.ttf",
    },
  },
  {
    family: "Roboto Mono",
    category: "monospace",
    files: {
      regular:
        "https://fonts.gstatic.com/s/robotomono/v13/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf",
    },
  },
  {
    family: "Noto Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/notosans/v25/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf",
    },
  },
  {
    family: "Raleway",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/raleway/v26/1Ptxg8zYS_SKggPN4iEgvnHyvveLxVvaooCPNLA3JC9c.ttf",
    },
  },
  {
    family: "Ubuntu",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/ubuntu/v19/4iCs6KVjbNBYlgo6eAT3v02QFg.ttf",
    },
  },
  {
    family: "Nunito",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/nunito/v22/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshRTM9jo7eTWk.ttf",
    },
  },
  {
    family: "PT Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/ptsans/v16/jizaRExUiTo99u79P0WOxOGMMDQ.ttf",
    },
  },
  {
    family: "Roboto Slab",
    category: "serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/robotoslab/v22/BngbUXZYTXPIvIBgJJSb6s3BzlRRfKOFbvjojISWaG5iddG-1A.ttf",
    },
  },
  {
    family: "Merriweather",
    category: "serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/merriweather/v28/u-440qyriQwlOrhSvowK_l5OeyxNV-bnrw.ttf",
    },
  },
  {
    family: "Playfair Display",
    category: "serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/playfairdisplay/v28/nuFvD-vYSZviVYUb_rj3ij__anPXJzDwcbmjWBN2PKdFvUDQZNLo_U2r.ttf",
    },
  },
  {
    family: "Inter",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/inter/v8/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
    },
  },
  {
    family: "Noto Sans KR",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/notosanskr/v25/PbykFmXiEBPT4ITbgNA5Cgm20HTs4JMMuA.otf",
    },
  },
  {
    family: "Rubik",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/rubik/v18/iJWZBXyIfDnIV5PNhY1KTN7Z-Yh-B4i1UE80V4bVkA.ttf",
    },
  },
  {
    family: "Lora",
    category: "serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/lora/v23/0QI6MX1D_JOuGQbT0gvTJPa787weuyJGmKxemMeZ.ttf",
    },
  },
  {
    family: "Mukta",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/mukta/v12/iJWKBXyXfDDVXYnGp32S0H3f.ttf",
    },
  },
  {
    family: "Nunito Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/nunitosans/v11/pe0qMImSLYBIv1o4X1M8cfe6Kdpickwp.ttf",
    },
  },
  {
    family: "Work Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/worksans/v16/QGY_z_wNahGAdqQ43RhVcIgYT2Xz5u32K0nXNigDp6_cOyA.ttf",
    },
  },
  {
    family: "Nanum Gothic",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/nanumgothic/v17/PN_3Rfi-oW3hYwmKDpxS7F_z_tLfxno73g.ttf",
    },
  },
  {
    family: "Fira Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/firasans/v15/va9E4kDNxMZdWfMOD5VfkILKSTbndQ.ttf",
    },
  },
  {
    family: "Quicksand",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/quicksand/v28/6xK-dSZaM9iE8KbpRA_LJ3z8mH9BOJvgkP8o18G0wx40QDw.ttf",
    },
  },
  {
    family: "PT Serif",
    category: "serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/ptserif/v16/EJRVQgYoZZY2vCFuvDFRxL6ddjb-.ttf",
    },
  },
  {
    family: "Hind Siliguri",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/hindsiliguri/v11/ijwTs5juQtsyLLR5jN4cxBEofJvQxuk0Nig.ttf",
    },
  },
  {
    family: "Titillium Web",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/titilliumweb/v14/NaPecZTIAOhVxoMyOr9n_E7fRMTsDIRSfr0.ttf",
    },
  },
  {
    family: "Noto Sans TC",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/notosanstc/v24/-nF7OG829Oofr2wohFbTp9iFOSsLA_ZJ1g.otf",
    },
  },
  {
    family: "Inconsolata",
    category: "monospace",
    files: {
      regular:
        "https://fonts.gstatic.com/s/inconsolata/v21/QldgNThLqRwH-OJ1UHjlKENVzkWGVkL3GZQmAwLYxYWI2qfdm7Lpp4U8aRr8lleY2co.ttf",
    },
  },
  {
    family: "Noto Serif",
    category: "serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/notoserif/v20/ga6Iaw1J5X9T9RW6j9bNTFAcaRi_bMQ.ttf",
    },
  },
  {
    family: "Barlow",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/barlow/v11/7cHpv4kjgoGqM7EPC8E46HsxnA.ttf",
    },
  },
  {
    family: "Karla",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/karla/v21/qkBIXvYC6trAT55ZBi1ueQVIjQTD-JqqFENLR7fHGw.ttf",
    },
  },
  {
    family: "Heebo",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/heebo/v17/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSycckOnz02SXQ.ttf",
    },
  },
  {
    family: "Libre Franklin",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/librefranklin/v11/jizOREVItHgc8qDIbSTKq4XkRg8T88bjFuXOnduhLsWUB9rIb-JH1g.ttf",
    },
  },
  {
    family: "Josefin Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/josefinsans/v23/Qw3PZQNVED7rKGKxtqIqX5E-AVSJrOCfjY46_DjQXMFrLgTsQV0.ttf",
    },
  },
  {
    family: "Libre Baskerville",
    category: "serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/librebaskerville/v13/kmKnZrc3Hgbbcjq75U4uslyuy4kn0pNeYRI4CN2V.ttf",
    },
  },
  {
    family: "Arimo",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/arimo/v24/P5sfzZCDf9_T_3cV7NCUECyoxNk37cxsBxDAVQI4aA.ttf",
    },
  },
  {
    family: "Dosis",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/dosis/v25/HhyJU5sn9vOmLxNkIwRSjTVNWLEJN7MV3BkFTq4EPw.ttf",
    },
  },
  {
    family: "Oxygen",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/oxygen/v14/2sDfZG1Wl4Lcnbu6iUcnZ0SkAg.ttf",
    },
  },
  {
    family: "PT Sans Narrow",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/ptsansnarrow/v16/BngRUXNadjH0qYEzV7ab-oWlsYCByxyKeuDp.ttf",
    },
  },
  {
    family: "Mulish",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/mulish/v10/1Ptyg83HX_SGhgqO0yLcmjzUAuWexZNRwaClGrw-PTY.ttf",
    },
  },
  {
    family: "IBM Plex Sans",
    category: "sans-serif",
    files: {
      regular:
        "https://fonts.gstatic.com/s/ibmplexsans/v13/zYXgKVElMYYaJe8bpLHnCwDKtdbUFI5NadY.ttf",
    },
  },
  {
    family: "Source Code Pro",
    category: "monospace",
    files: {
      regular:
        "https://fonts.gstatic.com/s/sourcecodepro/v20/HI_diYsKILxRpg3hIP6sJ7fM7PqPMcMnZFqUwX28DMyQhM5hTXUcdJg.ttf",
    },
  },
  {
    family: "Bebas Neue",
    category: "display",
    files: {
      regular:
        "https://fonts.gstatic.com/s/bebasneue/v8/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf",
    },
  },
  {
    family: "Lobster",
    category: "display",
    files: {
      regular:
        "https://fonts.gstatic.com/s/lobster/v27/neILzCirqoswsqX9_oWsMqEzSJQ.ttf",
    },
  },
  {
    family: "Cormorant Garamond",
    category: "serif",
    files: {
      regular:
        "http://fonts.gstatic.com/s/cormorantgaramond/v14/co3bmX5slCNuHLi8bLeY9MK7whWMhyjornFLsS6V7w.ttf",
    },
  },
  {
    family: "Abril Fatface",
    category: "display",
    files: {
      regular:
        "http://fonts.gstatic.com/s/abrilfatface/v18/zOL64pLDlL1D99S8g8PtiKchm-BsjOLhZBY.ttf",
    },
  },
  {
    family: "Shrikhand",
    category: "display",
    files: {
      regular:
        "http://fonts.gstatic.com/s/shrikhand/v9/a8IbNovtLWfR7T7bMJwbBIiQ0zhMtA.ttf",
    },
  },
  {
    family: "Sen",
    category: "sans-serif",
    files: {
      regular: "http://fonts.gstatic.com/s/sen/v5/6xKjdSxYI9_Hm_-MImrpLQ.ttf",
    },
  },
  {
    family: "Supermercado One",
    category: "display",
    files: {
      regular:
        "http://fonts.gstatic.com/s/supermercadoone/v20/OpNXnpQWg8jc_xps_Gi14kVVEXOn60b3MClBRTs.ttf",
    },
  },
  {
    family: "Jua",
    category: "sans-serif",
    files: {
      regular: "http://fonts.gstatic.com/s/jua/v11/co3KmW9ljjAjc-DZCsKgsg.ttf",
    },
  },
  {
    family: "Fredoka One",
    category: "display",
    files: {
      regular:
        "http://fonts.gstatic.com/s/fredokaone/v12/k3kUo8kEI-tA1RRcTZGmTmHBA6aF8Bf_.ttf",
    },
  },
  {
    family: "Space Mono",
    category: "monospace",
    files: {
      regular:
        "http://fonts.gstatic.com/s/spacemono/v10/i7dPIFZifjKcF5UAWdDRUEZ2RFq7AwU.ttf",
    },
  },
  {
    family: "Cutive Mono",
    category: "monospace",
    files: {
      regular:
        "http://fonts.gstatic.com/s/cutivemono/v12/m8JWjfRfY7WVjVi2E-K9H5RFRG-K3Mud.ttf",
    },
  },
  {
    family: "Open Sans Condensed",
    category: "sans-serif",
    files: {
      regular:
        "http://fonts.gstatic.com/s/opensanscondensed/v21/z7NFdQDnbTkabZAIOl9il_O6KJj73e7Ff1GhPuLGRpWRyAs.ttf",
    },
  },
  {
    family: "Limelight",
    category: "display",
    files: {
      regular:
        "http://fonts.gstatic.com/s/limelight/v14/XLYkIZL7aopJVbZJHDuYPeNGrnY2TA.ttf",
    },
  },
  {
    family: "Gravitas One",
    category: "display",
    files: {
      regular:
        "http://fonts.gstatic.com/s/gravitasone/v13/5h1diZ4hJ3cblKy3LWakKQmaDWRNr3DzbQ.ttf",
    },
  },
  {
    family: "Amatic SC",
    category: "handwriting",
    files: {
      regular:
        "http://fonts.gstatic.com/s/amaticsc/v22/TUZyzwprpvBS1izr_vO0De6ecZQf1A.ttf",
    },
  },
  {
    family: "Dancing Script",
    category: "handwriting",
    files: {
      regular:
        "http://fonts.gstatic.com/s/dancingscript/v22/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTeB9ptDqpw.ttf",
    },
  },
  {
    family: "Mrs Saint Delafield",
    category: "handwriting",
    files: {
      regular:
        "http://fonts.gstatic.com/s/mrssaintdelafield/v11/v6-IGZDIOVXH9xtmTZfRagunqBw5WC62cK4tLsubB2w.ttf",
    },
  },
  {
    family: "Nanum Pen Script",
    category: "handwriting",
    files: {
      regular:
        "http://fonts.gstatic.com/s/nanumpenscript/v15/daaDSSYiLGqEal3MvdA_FOL_3FkN2z7-aMFCcTU.ttf",
    },
  },
  {
    family: "Sedgwick Ave",
    category: "handwriting",
    files: {
      regular:
        "http://fonts.gstatic.com/s/sedgwickave/v10/uK_04rKEYuguzAcSYRdWTJq8Xmg1Vcf5JA.ttf",
    },
  },
];

export default googleFontsList;
