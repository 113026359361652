import { Card, DefaultButton, LogoPicker } from "components/atoms";
import { MenuEditor } from "components";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { PopupFormule } from "../Popup/PopupFormule";
import { StepFooter } from "components";
import { findFont } from "helpers/findDataCustom";

export function AddCategorieFormule({
  className,
  selectedBrand,
  selectedBrandId,
  loadData = () => {},
  custom,
}) {
  const [sexlength, setSexlength] = useState(0);
  const [formulelength, setFormulelength] = useState(0);
  const [upFormule, setUpFormule] = useState(false);

  useEffect(() => {
    if (
      selectedBrand &&
      selectedBrand.Menu &&
      selectedBrand.Menu[0] &&
      selectedBrand.Menu[0].MenuSection
    ) {
      setSexlength(selectedBrand.Menu[0].MenuSection.length);
    }
    if (
      selectedBrand &&
      selectedBrand.Menu &&
      selectedBrand.Menu[0] &&
      selectedBrand.Menu[0].Formule
    ) {
      setFormulelength(selectedBrand.Menu[0].Formule.length);
    }
  }, []);

  return (
    <div className="m-auto mt-4 mb-16 flex w-[full] flex-col items-center">
      {upFormule && (
        <PopupFormule
          menuId={selectedBrand.Menu[0].id}
          close={() => setUpFormule(false)}
          loadData={() => loadData()}
        />
      )}
      <Card
        className={`w-full md:w-${
          sexlength === 0 && formulelength === 0 ? "900" : "full"
        }`}
        style={{
          backgroundColor: custom && custom.color[0].custom.color,
        }}
      >
        <div className=" flex flex-col items-center md:mx-0">
          <div className="m-auto mt-10">
            {selectedBrand.avatar ? (
              <img src={selectedBrand.avatar} className="h-28" />
            ) : (
              ""
            )}
          </div>
          <p
            className={`mb-2 font-poppins  text-2xl ${
              selectedBrand.city ? "border-b border-solid border-grey-dark" : ""
            } ${
              selectedBrand.avatar ? "mt-3" : ""
            } w-full pb-2 text-center md:w-1/3
                ${
                  custom &&
                  findFont(
                    selectedBrand.avatar
                      ? custom && custom.police[2].custom.fontFamily
                      : custom && custom.police[0].custom.fontFamily
                  )
                }
                ${
                  selectedBrand.avatar
                    ? custom && custom.police[2].custom.bold
                      ? "font-bold"
                      : ""
                    : custom && custom.police[0].custom.bold
                    ? "font-bold"
                    : ""
                }
                ${
                  selectedBrand.avatar
                    ? custom && custom.police[2].custom.italic
                      ? "italic"
                      : ""
                    : custom && custom.police[0].custom.italic
                }
              `}
            style={{
              fontSize: `${
                selectedBrand.avatar
                  ? custom && custom.police[2].custom.fontSize
                  : custom && custom.police[0].custom.fontSize
              }px`,

              letterSpacing: `${
                selectedBrand.avatar
                  ? custom && custom.police[2].custom.letterSpacing
                  : custom && custom.police[0].custom.letterSpacing
              }px`,
              color: selectedBrand.avatar
                ? custom && custom.color[2].custom.color
                : custom && custom.color[1].custom.color,
              borderColor: custom && custom.color[3].custom.color,
            }}
          >
            {selectedBrand.name}
          </p>
          <p
            className={`mb-5 font-poppins text-sm
                ${
                  custom &&
                  findFont(custom && custom.police[3].custom.fontFamily)
                }
                ${custom && custom.police[3].custom.bold ? "font-bold" : ""}
                ${custom && custom.police[3].custom.italic ? "italic" : ""}
              `}
            style={{
              fontSize: `${custom && custom.police[3].custom.fontSize}px`,
              letterSpacing: `${
                custom && custom.police[3].custom.letterSpacing
              }px`,
              color: custom && custom.color[2].custom.color,
            }}
          >
            {selectedBrand.city}
          </p>

          <MenuEditor brand={selectedBrand} />
        </div>
        {/* BUTTONS */}
        <div className="mt-5 flex flex-col items-center gap-5 md:mt-10 md:mb-3 md:flex md:w-full md:flex-col md:items-center md:justify-around">
          <Link to={`/pages/dashboard/categories/${selectedBrandId}`}>
            <div className="mb-3 flex flex-col items-center  ">
              <DefaultButton className="!mb-2">
                Ajouter une catégorie
              </DefaultButton>
              <p
                style={{ color: custom && custom.color[2].custom.color }}
                className="font-poppins text-xs"
              >
                (Ex : Entrées ou plats ou desserts)
              </p>
            </div>
          </Link>
          <div className="mb-3 flex flex-col items-center ">
            <DefaultButton
              onClick={() => setUpFormule(true)}
              className=" !mb-2"
            >
              Ajouter une formule
            </DefaultButton>
            <p
              style={{ color: custom && custom.color[2].custom.color }}
              className="font-poppins text-xs"
            >
              (Ex : Menu enfants, Menu midi)
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}

{
  /*{upFormule && (
<PopupFormule
  menuId={selectedBrand.Menu[0].id}
  close={() => setUpFormule(false)}
  loadData={() => loadData()}
/>
)} */
}
