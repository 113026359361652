import joinClasses from "helpers/joinClasses";

export function SubTitle({ children, className = "", TextSize }) {
  if (TextSize === "smaller") {
    return (
      <h1
        className={joinClasses(className, "font-poppins  font-semibold")}
        style={{ fontSize: "16px" }}
      >
        {children}
      </h1>
    );
  }
  return (
    <h2
      className={joinClasses(className, "font-poppins font-medium")}
      style={{ fontSize: "35px" }}
    >
      {children}
    </h2>
  );
}
