import { useEffect, useState } from "react";
import {
  Card,
  Icons,
  EditFontRestau,
  EditColorRestau,
  EditDisplayRestau,
  BorderedButton,
} from "components/atoms";
import editFont from "utils/editFont";
import { useSelector, useDispatch } from "react-redux";
import {
  customSelector,
  fetchAllCustom,
  fetchEditCustom,
} from "store/models/customInfo";
import { userSelector } from "store/models/userInfo";
import { useLocation } from "react-router-dom";
import editColor from "utils/editColor";
import editDisplay from "utils/editDisplay";

export function CustomMenuCard({
  isOpen = false,
  setIsOpen = () => {},
  brandId,
}) {
  const custom = useSelector(customSelector);
  const { user } = useSelector(userSelector);
  const location = useLocation();
  const dispatch = useDispatch();

  const [isFirstInit, setIsFirstInit] = useState(true);
  const [curretTab, setCurrentTab] = useState(1);
  const [success, setSuccess] = useState(false);

  // state for edit form
  const [editingFont, setEditingFont] = useState([]);
  const [editingColor, setEditingColor] = useState([]);
  const [editingDisplay, setEditingDisplay] = useState([]);

  // fetch all customs for user after get user info
  useEffect(() => {
    if (user.id) {
      dispatch(fetchAllCustom(user.id));
    }
  }, []);

  useEffect(() => {
    // if customs of store is loaded and is the first call of this function
    if (custom.header.status === "success" && isFirstInit) {
      // get index of current custom in the store
      const index = custom.customs.findIndex(
        (item) => item.brandId === Number(brandId)
      );

      // if this custom is found in the store
      if (custom.customs[index]) {
        // set the store default values for edit form
        setEditingFont(custom.customs[index].police);
        setEditingColor(custom.customs[index].color);
        setEditingDisplay(custom.customs[index].display);
      } else {
        // set default values for edit form
        setEditingFont(editFont);
        setEditingColor(editColor);
        setEditingDisplay(editDisplay);
      }
      // set first initialisation to false
      setIsFirstInit(false);
    }
  }, [custom]);

  // useEffect(() => {
  //   // if form is not empty and is not the first initialisation
  //   if (
  //     editingFont.length &&
  //     editingColor.length &&
  //     editingDisplay.length &&
  //     !isFirstInit
  //   ) {
  //     dispatch(
  //       fetchEditCustom({
  //         police: editingFont,
  //         brandId: brandId,
  //         color: editingColor,
  //         display: editingDisplay,
  //         userId: user.id,
  //       })
  //     );
  //   }
  // }, [editingFont, editingColor, editingDisplay]);

  async function handleChangeCustom(name, value) {
    const editContainer = {
      police: editingFont,
      brandId: brandId,
      color: editingColor,
      display: editingDisplay,
      userId: user.id,
    };

    if (JSON.stringify([...editContainer[name]]) === JSON.stringify(value))
      return;

    const editContainerUpdated = {
      ...editContainer,
      [name]: value,
    };
    try {
      await dispatch(fetchEditCustom(editContainerUpdated));
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 1500);
    } catch (e) {
      console.log("error -> ", e);
    }
  }

  const tabItems = [
    {
      id: 1,
      title: "Polices",
      icon: "font-family",
    },
    {
      id: 2,
      title: "Couleurs",
      icon: "color-picker",
    },
    {
      id: 3,
      title: "Affichage",
    },
  ];

  return (
    <>
      {success && (
        <div className="fixed left-5 bottom-5 z-50 flex items-center gap-4 bg-green p-4 text-white animate__animated animate__faster animate__fadeInLeft">
          <Icons name="valide" height={13} />
          <p>Personnalisation mis à jour</p>
        </div>
      )}
      <Card
        className={`fixed right-0 top-1/2 -translate-y-2/4 transform ${
          !isOpen && "hidden"
        } z-50 w-full bg-white shadow-special transition-all  md:w-[400px]`}
      >
        {/* header title */}
        <div className="flex justify-between gap-8">
          <h3 className="flex items-center gap-2 p-5 font-poppins text-[15px] font-bold">
            <Icons name="brush-fill" height={35} className="cursor-default" />
            Personnaliser votre carte
          </h3>
          <button
            className="h-[50px] w-[50px] bg-yellow"
            onClick={() => setIsOpen(false)}
          >
            <Icons
              name="add"
              height={25}
              className="block  rotate-45 transform"
            />
          </button>
        </div>
        <div className="max-h-[650px] overflow-y-auto overflow-x-hidden">
          {/* navbar of tabs */}
          <nav className="grid grid-cols-3 gap-4 px-3">
            {tabItems.map((item, index) => (
              <div
                key={index}
                className={`border ${
                  curretTab === item.id
                    ? "border-yellow bg-yellow"
                    : "border-black"
                } flex cursor-pointer items-center justify-center gap-3 rounded-full py-2 text-center font-poppins text-sm`}
                onClick={() => setCurrentTab(item.id)}
              >
                <p>{item.title}</p>
                {item.icon && <Icons name={item.icon} height="15" />}
              </div>
            ))}
          </nav>

          {/* tab of editing fonts */}
          {curretTab === 1 && (
            <div className="mx-3 mt-8 flex flex-col gap-3">
              {editingFont.map((arg, index) => {
                return (
                  <EditFontRestau
                    key={arg.id}
                    index={index}
                    editingFont={editingFont}
                    setEditingFont={setEditingFont}
                    fonts={custom.fonts}
                    handleChange={handleChangeCustom}
                  />
                );
              })}
            </div>
          )}

          {/* tab of editing colors */}
          {curretTab === 2 && (
            <div className="mx-3 mt-8 flex flex-col gap-3">
              {editColor.map((arg, index) => (
                <EditColorRestau
                  key={index}
                  index={index}
                  editingColor={editingColor}
                  setEditingColor={setEditingColor}
                  handleChange={handleChangeCustom}
                />
              ))}
            </div>
          )}

          {/* tab of editing display */}
          {curretTab === 3 && (
            <div className="mx-3 mt-8 flex flex-col gap-3">
              {editDisplay.map((arg, index) => (
                <EditDisplayRestau
                  key={index}
                  index={index}
                  editingDisplay={editingDisplay}
                  setEditingDisplay={setEditingDisplay}
                  handleChange={handleChangeCustom}
                />
              ))}
            </div>
          )}

          <div className="py-5">
            <p className="m-auto mb-2 w-2/3 text-center text-xs font-semibold">
              Vous souhaitez aller plus loin dans la personnalisation de votre
              carte ?
            </p>
            <a href="mailto:nathan@agencerjs.com?subject=Aller plus loin dans la personnalisation de ma carte">
              <BorderedButton borderType="yellow" className=" m-auto ">
                Contactez-nous
              </BorderedButton>
            </a>
          </div>
        </div>
      </Card>
    </>
  );
}
