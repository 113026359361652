/**
 * USAGE:
 * --------------------------------
useEffect(() => {
    window.addEventListener("scroll", function () {
        let closeSection = 0;
        (closeSection = getCloseSection()) && <SETTER STATE>(closeSection);
    });
}, []);
 * --------------------------------
 */

import { checkUserToken } from "store/models/oneRestaurantInfo";

export function getCloseSection() {
  const sectionsElems = document.querySelectorAll(
    `div[trigger^="section"], div[trigger^="formules"]`
  );
  let sectionsPosition = [];

  sectionsElems.forEach((element) => {
    if (sectionsElems.length) {
      sectionsPosition.push({
        id: element.attributes.trigger.value,
        top: element.offsetTop,
        bottom: element.offsetTop + element.offsetHeight,
      });
    }
  });
  const scrollPosition = window.scrollY;
  const middleScrollPosition = scrollPosition + window.innerHeight / 2;
  sectionsPosition = sectionsPosition.filter(
    (element) => element.top > 0 && element.bottom > 0
  );

  let closeSection;
  for (const key in sectionsPosition) {
    if (
      middleScrollPosition > sectionsPosition[key].top &&
      middleScrollPosition < sectionsPosition[key].bottom
    ) {
      closeSection = sectionsPosition[key].id;
    }
  }
  return closeSection;
}
