// COMPONENTS
import { Icons, BorderedButton } from "components/atoms";

//
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import joinClasses from "helpers/joinClasses";

export function MainNavbar({ onClick, isConnected = true }) {
  const { user } = useSelector(userSelector);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  return (
    <>
      <nav className="w-full bg-white">
        <div className=" ml-10 flex h-[85px] items-center justify-between sm:ml-14  sm:mr-10 sm:flex sm:h-[66px] sm:items-center sm:justify-between">
          <Link to={"/pages/dashboard"}>
            <div className="flex h-full">
              <img src="/assets/brand/logo.svg" alt="logo-menu" />
            </div>
          </Link>
          <div
            onClick={() => setIsMobileOpen(!isMobileOpen)}
            className={joinClasses(
              "right-8 cursor-pointer  sm:hidden",
              isMobileOpen ? "fixed top-9 z-20 " : "absolute top-8"
            )}
          >
            <span
              className={`my-1 block h-0.5 w-5 transform bg-black transition-all ${
                isMobileOpen && "absolute translate-y-0.5 rotate-45 bg-black "
              }`}
            ></span>
            <span
              className={`my-1 block h-0.5 w-5 bg-black transition-all ${
                isMobileOpen && "opacity-0"
              }`}
            ></span>
            <span
              className={`my-1 block h-0.5 w-5 transform bg-black transition-all ${
                isMobileOpen && "absolute -translate-y-2 -rotate-45 bg-black "
              }`}
            ></span>
          </div>

          <div className="hidden sm:my-3 sm:flex sm:items-center sm:gap-4 md:gap-8">
            {isConnected ? (
              <>
                <a
                  className="font-poppins text-smallest font-medium underline md:text-sm"
                  href="mailto:nathan@agencerjs.com?subject=Remontée de bug Bonappli.fr&body=Pouvez-vous nous décrire précisément le bug rencontré.%0D%0AVous pouvez joindre des captures d’écrans, cela peut nous aider :)%0D%0A%0D%0ADESCRIPTION DU BUG RENCONTRÉ :"
                >
                  Signaler un bug
                </a>
                <Link to={"/pages/dashboard"} className="flex">
                  <div>
                    <BorderedButton className="!py-2.5 !px-7">
                      Mes restaurants
                    </BorderedButton>
                  </div>
                </Link>
                <Link
                  to="/pages/account"
                  className="flex gap-2 md:flex-col md:items-center md:gap-0"
                >
                  <Icons name="user" height="21" />
                  <p className="text-center font-poppins text-xs font-light">
                    {user.firstname} {user.lastname}
                  </p>
                </Link>
              </>
            ) : (
              <>
                <Link
                  className="font-poppins text-smallest font-medium underline md:text-sm"
                  to={"/"}
                >
                  Se connecter
                </Link>
                <Link to={"/auth/register"} className="flex">
                  <div>
                    <BorderedButton className="!py-2.5 !px-7">
                      Essayer gratuitement
                    </BorderedButton>
                  </div>
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
      <nav
        className={`fixed top-0 z-10 h-screen w-screen transform bg-yellow-lightest transition-all sm:hidden ${
          isMobileOpen ? "-translate-x-0" : "translate-x-full"
        }`}
      >
        <ul className="flex h-full w-full flex-col items-center justify-center gap-10">
          {isConnected ? (
            <>
              <li>
                <a
                  className="font-poppins text-sm font-medium underline"
                  href="mailto:nathan@agencerjs.com?subject=Remontée de bug Bonappli.fr&body=Pouvez-vous nous décrire précisément le bug rencontré.%0D%0AVous pouvez joindre des captures d’écrans, cela peut nous aider :)%0D%0A%0D%0ADESCRIPTION DU BUG RENCONTRÉ :"
                >
                  Signaler un bug{" "}
                </a>
              </li>
              <li>
                <Link to={"/pages/dashboard"} className="flex ">
                  <div>
                    <BorderedButton className="!py-2.5 !px-7 !text-sm">
                      Mes restaurants
                    </BorderedButton>
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/pages/account"
                  className="flex gap-2 md:flex-col md:items-center md:gap-0 "
                >
                  <Icons name="user" height="24" />
                  <p className="text-center font-poppins text-sm font-light">
                    {user.firstname} {user.lastname}
                  </p>
                </Link>
              </li>
            </>
          ) : (
            <>
              <li>
                <Link
                  to={"/"}
                  className="text-md font-poppins font-medium underline"
                >
                  Se connecter
                </Link>
              </li>
              <li>
                <Link to={"/auth/register"} className="flex">
                  <div>
                    <BorderedButton className="!py-2.5 !px-7">
                      Essayer gratuitement
                    </BorderedButton>
                  </div>
                </Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </>
  );
}
