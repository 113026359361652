// COMPONENTS
import {
  Card,
  RestoInput,
  LogoPicker,
  DefaultButton,
  BorderedButton,
} from "components/atoms";

import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { fetchSelectedBrand, sendBrand, updateBrand } from "api/brand/brand";
import { sendBrandLogo } from "api/brand/brand";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { Link } from "react-router-dom";
import { DeleteAvatar } from "api/images/images";

export function DashboardCard({ selectedBrandId }) {
  const history = useHistory();
  const [restaurantInfo, setRestaurantInfo] = useState({
    address1: "",
    name: "",
    phone: "",
    zip: "",
    city: "",
    complement: "",
  });
  const [logo, setLogo] = useState(null);

  const [loading, setLoading] = useState(false);
  const { user } = useSelector(userSelector);

  useEffect(() => {
    getBrandById(selectedBrandId);
  }, []);

  async function getBrandById(selectedBrandId) {
    const brand = await fetchSelectedBrand({ id: selectedBrandId });
    setRestaurantInfo(brand);
  }

  async function sendUpdatedBrand(id, payload) {
    if (logo) await sendBrandLogo(id, user.id, logo);

    const updatedBrand = await updateBrand(id, {
      address1: payload.address1,
      address2: payload.address2,
      name: payload.name,
      phone: payload.phone,
      city: payload.city,
      zip: payload.zip,
    });

    setLogo(null);
    setRestaurantInfo(updatedBrand);
  }

  async function deleteAvatar(logo, userId, type) {
    setLoading(true);
    await DeleteAvatar(logo, userId, type);
    getBrandById(selectedBrandId);
    setLoading(false);
  }

  async function uploadAvatar(id, userId, logo) {
    setLoading(true);
    await sendBrandLogo(id, userId, logo);
    setRestaurantInfo({ ...restaurantInfo });
    setRestaurantInfo(await fetchSelectedBrand({ id: selectedBrandId }));
    setLoading(false);
  }

  return (
    <div className="mt-4 flex justify-center px-3 pb-5 md:p-0">
      <Card className="w-full md:w-[900px]">
        <LogoPicker
          defaultLogo={restaurantInfo.avatar}
          setLogo={(e) => {
            setLogo(e), uploadAvatar(selectedBrandId, user.id, e);
          }}
          wh={48}
          className="!max-w-[120px]"
          onClick={() => deleteAvatar(restaurantInfo.avatar, user.id, "brand")}
        />
        <div className="justify-content flex text-2xl   md:px-0 ">
          <input
            required
            onBlur={(e) => {
              setRestaurantInfo({ ...restaurantInfo, name: e.target.value });
            }}
            placeholder={"Nom du restaurant"}
            defaultValue={restaurantInfo.name}
            className="active-none relative m-auto w-5/6 border-none pb-2 text-center font-poppins text-2xl placeholder-grey-medium  focus:outline-none sm:w-3/4 md:max-w-max md:px-0"
            style={{
              border: "none",
              borderBottom: `1px solid`,
              borderColor: "#B3B3B3",
            }}
          />
        </div>
        <div className="grid grid-cols-1 md:flex md:justify-center   ">
          <div className=" mx-5 mt-12 md:mx-0 md:ml-0 ">
            <RestoInput
              required
              onBlur={(e) => {
                setRestaurantInfo({
                  ...restaurantInfo,
                  address1: e.target.value,
                });
              }}
              placeholder={"N° + Rue *"}
              defaultValue={restaurantInfo.address1}
            />

            <RestoInput
              onBlur={(e) => {
                setRestaurantInfo({
                  ...restaurantInfo,
                  address2: e.target.value,
                });
              }}
              placeholder={"Complément"}
              defaultValue={restaurantInfo.address2}
            />

            <div className=" flex flex-col md:flex md:flex-row">
              <RestoInput
                className="w-1/2 md:mb-0  md:w-3/4"
                required
                onBlur={(e) => {
                  setRestaurantInfo({ ...restaurantInfo, zip: e.target.value });
                }}
                placeholder={"Code postal *"}
                defaultValue={restaurantInfo.zip}
              />
              <RestoInput
                className="pr-9"
                required
                onBlur={(e) => {
                  setRestaurantInfo({
                    ...restaurantInfo,
                    city: e.target.value,
                  });
                }}
                placeholder={"Ville *"}
                defaultValue={restaurantInfo.city}
              />
            </div>
            <div className="mb-12">
              <RestoInput
                onBlur={(e) => {
                  setRestaurantInfo({
                    ...restaurantInfo,
                    phone: e.target.value,
                  });
                }}
                placeholder={"Téléphone"}
                type="tel"
                defaultValue={restaurantInfo.phone}
              />
            </div>
            <div className="mb-10 flex flex-col items-center md:mb-7  md:flex md:flex-row ">
              <BorderedButton
                onClick={async () => {
                  await sendUpdatedBrand(restaurantInfo.id, restaurantInfo);
                  history.push("/pages/dashboard");
                }}
                className="mb-4 md:mb-0 md:mr-4"
              >
                Valider
              </BorderedButton>

              <DefaultButton
                onClick={async () => {
                  await sendUpdatedBrand(restaurantInfo.id, restaurantInfo);
                  history.push(`/pages/dashboard/menu/${selectedBrandId}`);
                }}
              >
                Commencer mon menu
              </DefaultButton>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
