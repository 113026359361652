import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "./menu.css";

// ATOMS
import {
  LogoPicker,
  DefaultButton,
  CategorieSeparation,
  HorizontalLine,
  ThinHorizontalLine,
  Icons,
  SmoothCard,
  BorderedButton,
} from "components/atoms";

// COMPONENTS
import {
  DishCreationPatron,
  DrinkVolumesEditor,
  FormuleCreationPatron,
  CommentCreationPatron,
  DrinkCreationPatron,
} from "components";

// API
import { createDish } from "api/dish/dish";
import { fetchOneMenu } from "api/menu/menu";
import { sendMenuSexLogo, updateMenuSex } from "api/menuSex/menuSex";
import { createComment } from "api/comment/comment";
import { createDrinkCategory } from "api/drinkCategory/drinkCategory";
import { positionDown, positionUp } from "api/position";

// STORE
import { customSelector } from "store/models/customInfo";
import { userSelector } from "store/models/userInfo";

// HELPERS
import { findDataCustom, findFont } from "helpers/findDataCustom";
import { DeleteAvatar } from "api/images/images";
import { focusInput } from "helpers/focusInput";
import { getCloseSection } from "helpers/getCloseSection";
import { useLocation } from "react-router";

export function MenuEditor({ brand }) {
  // USESTATES
  const [section, setSection] = useState(null);
  const [formules, setFormules] = useState(null);
  const [menuId, setMenuId] = useState(null);
  const [menu, setMenu] = useState(null);
  const [sexSelectedIndex, setSexSelectedIndex] = useState(null);
  const [logo, setLogo] = useState(null);
  const [idSection, setIdSection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [drinkVolumes, setDrinkVolumes] = useState(null);
  const [customData, setCustomData] = useState(null);
  const [isScrolled, setIsScrolled] = useState(false);

  // USESELECTOR
  // Récuperer les infos du user
  const { user } = useSelector(userSelector);
  // Récuperer les infos du custom
  const custom = useSelector(customSelector);

  const location = useLocation();

  // REFS
  const navBarParent = useRef(null);
  const navBarScroll = useRef(null);

  // USEEFFECTS
  // Prendre en compte les changer du tableau custom et les appliquer
  useEffect(() => {
    if (custom) {
      setCustomData(findDataCustom(custom.customs, brand.id));
    }
  }),
    [custom];

  useEffect(() => {
    if (brand && brand.Menu && brand.Menu[0] && brand.Menu[0].MenuSection) {
      setSection(brand.Menu[0].MenuSection);
      setMenuId(brand.Menu[0].id);
    }
    if (brand && brand.Menu && brand.Menu[0] && brand.Menu[0].Formule) {
      setFormules(brand.Menu[0].Formule);
    }
  }, [brand]);

  useEffect(() => {
    if (menu && menu.MenuSection) {
      setSection(menu.MenuSection);
    }
    if (menu && menu.Formule) {
      setFormules(menu.Formule);
    }
  }, [menu]);

  useEffect(() => {
    if (section && !idSection) {
      const { hash } = window.location;
      if (hash !== "") {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }
    }
  }, [window.location, section]);

  useEffect(() => {
    getScrollNavBarWidth();
    if (navBarParent.current) {
      navBarParent.current.addEventListener("click", function (e) {
        const trigger = `div[trigger='${e.target.id
          .replace("#", "")
          .replace("-trigger", "")}']`;
        window.scroll(0, document.querySelector(trigger).offsetTop);
        if (!isScrolled) setIsScrolled(true);
      });
    }
    if (location.state && location.state.section && !isScrolled) {
      setSexSelectedIndex(location.state.section);
      const section = document.querySelector(
        `div[trigger='${location.state.section}']`
      );
      if (section && !isScrolled) {
        window.scrollTo(0, section.offsetTop - window.innerHeight / 2);
        setIsScrolled(true);
      }
    }
  });

  useEffect(() => {
    window.addEventListener("resize", getScrollNavBarWidth);

    window.addEventListener("scroll", function () {
      let closeSection = 0;
      (closeSection = getCloseSection()) && setSexSelectedIndex(closeSection);
    });
  }, []);

  useEffect(() => {}, [navBarParent.current]);

  useEffect(() => {
    let closeSection = "";

    window.addEventListener("scroll", function () {
      closeSection = getCloseSection();
      if (closeSection && closeSection.length) {
        setSexSelectedIndex(closeSection);
      }
    });
  }, [location.state]);

  // FUNCTIONS
  // RECUPERER TOUTES LES INFOS DU MENU
  const getOneMenu = async () => {
    setLoading(true);
    setMenu(await fetchOneMenu({ id: menuId }));
    setLoading(false);
  };

  // CREER UN DISH
  const sendDish = async (id, e) => {
    const { data } = await createDish(id);
    await getOneMenu();
    focusInput("dish", data.dish.id);
  };

  //CREATION UNE SOUS-CATEGORIE DE DRINK
  const sendDrinkCategory = async (id) => {
    const { data } = await createDrinkCategory(user.token, id);
    await getOneMenu();
    focusInput("drink-category", data.drinkCategory.id);
  };

  // CREER UN COMMENTAIRE
  const sendComment = async (id) => {
    const { data } = await createComment(user.token, id);
    await getOneMenu();
    focusInput("comment", data.comment.id);
  };

  // CHANGER LE NOM D'UNE SECTION
  const changeMenuSection = async (payload, id) => {
    await updateMenuSex(id, payload);
    getOneMenu();
  };

  const removeProperty = async (one, two) => {
    setMenu(await fetchOneMenu({ id: menuId }));
    one.style.transition = null;
    one.style.transform = null;
    two.style.transition = null;
    two.style.transform = null;
    one.style.backgroundColor = null;
  };

  // CHANGER UNE SECTION DE POSITION
  const changeSectionPosition = async (section, action) => {
    if (action === "up") {
      const firstSection = document.getElementById(section.positionId - 1);
      const secondSection = document.getElementById(section.positionId - 2);
      firstSection.style.transition = "transform";
      firstSection.style.transitionDuration = "0.5s";
      firstSection.style.transitionTimingFunction = "forwards";
      secondSection.style.transitionDuration = "0.5s";
      secondSection.style.transitionTimingFunction = "forwards";
      firstSection.style.transform = `translateY(-${
        secondSection.offsetHeight + 80
      }px)`;
      secondSection.style.transform = `translateY(${
        firstSection.offsetHeight + 80
      }px)`;

      await positionDown(section.id, "menusection");
      setTimeout(function () {
        removeProperty(firstSection, secondSection);
      }, 500);
      secondSection.scrollIntoView({ behavior: "smooth" });
    }

    if (action === "down") {
      const firstSection = document.getElementById(section.positionId - 1);
      const secondSection = document.getElementById(section.positionId);

      firstSection.style.transition = "transform";
      firstSection.style.transitionDuration = "0.5s";
      firstSection.style.transitionTimingFunction = "forwards";
      secondSection.style.transitionDuration = "0.5s";
      secondSection.style.transitionTimingFunction = "forwards";
      firstSection.style.transform = `translateY(${
        secondSection.offsetHeight + 80
      }px)`;
      secondSection.style.transform = `translateY(-${
        firstSection.offsetHeight + 80
      }px)`;
      await positionUp(section.id, "menusection");
      setTimeout(function () {
        removeProperty(firstSection, secondSection);
      }, 500);
      secondSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // SUPPRIMER UNE SECTION
  const removeMenuSection = async (id) => {
    setLoading(true);
    await updateMenuSex(id, { isSelected: false });
    getOneMenu();
  };

  // SUPPRIMER UNE IMAGE DE CATEGORIE
  async function deleteAvatar(avatar, userId, type) {
    setLoading(true);
    await DeleteAvatar(avatar, userId, type);
    getOneMenu();
    setLoading(false);
  }

  async function uploadAvatar(idSection, userId, logo) {
    setLoading(true);
    await sendMenuSexLogo({ id: idSection, userId: userId, logo });
    getOneMenu();
    setLoading(false);
  }

  // ADAPTER LE STYLE DE LA NAVBAR EN FONCTION DE LA TAILLE DE L'ECRAN
  const getScrollNavBarWidth = () => {
    if (
      navBarScroll &&
      navBarScroll.current &&
      navBarParent &&
      navBarParent.current
    ) {
      if (navBarScroll.current.clientWidth > window.screen.width) {
        navBarParent.current.style.width = "100vw";
        navBarParent.current.style.overflow = "auto";
        navBarParent.current.style.display = "flex";
        navBarParent.current.style.justifyContent = "flex-start";
      } else {
        navBarParent.current.style.display = "flex";
        navBarParent.current.style.overflow = "none";
        navBarParent.current.style.justifyContent = "flex-start";
      }
    }
  };

  // COULEUR DE LA NAVBAR
  const getColorforNavbar = () => {
    return {
      // si la section est sélectionnée
      active: {
        backgroundColor:
          (customData && customData.color[1].custom.color) || "#fff",
        color: (customData && customData.color[0].custom.color) || "#000",
      },
      // si elle ne l'est pas
      inactive: {
        backgroundColor: "transparent",
        color: (customData && customData.color[1].custom.color) || "#fff",
      },
    };
  };

  return (
    <>
      <style>
        {location.state && location.state.section && !isScrolled
          ? `html {scroll-behavior: initial;}`
          : ""}
      </style>
      <div className="m-auto flex w-full flex-col items-center">
        {((section && section.length > 0) ||
          (formules && formules.length > 0)) && (
          <div
            ref={navBarParent}
            className="hideScrollBar anim-scroll sticky top-0 z-10 m-auto flex w-full justify-start overflow-auto    border-b-2 border-solid border-black"
            style={{
              borderColor: customData && customData.color[1].custom.color,
              backgroundColor: customData && customData.color[0].custom.color,
            }}
          >
            <div
              ref={navBarScroll}
              className={`   sticky top-0 z-10 m-auto flex h-[40px] items-end bg-white text-center
            ${customData && findFont(customData.police[0].custom.fontFamily)}
                ${
                  customData && customData.police[0].custom.bold
                    ? "font-bold"
                    : "font-normal"
                }
                ${
                  customData && customData.police[0].custom.italic
                    ? "italic"
                    : ""
                }
            `}
              style={{
                backgroundColor: customData && customData.color[0].custom.color,
                borderColor: customData && customData.color[1].custom.color,
              }}
            >
              {section &&
                section.map((row, index) => (
                  <a
                    key={index}
                    className={`flex h-[40px] min-w-max items-center px-3   ${
                      ((!sexSelectedIndex && index === 0) ||
                        sexSelectedIndex === `section-${row.id}`) &&
                      "bg-black text-white "
                    }  cursor-pointer `}
                    style={
                      (!sexSelectedIndex && index === 0) ||
                      sexSelectedIndex === `section-${row.id}`
                        ? { ...getColorforNavbar().active }
                        : { ...getColorforNavbar().inactive }
                    }
                    id={`#section-${row.id}-trigger`}
                  >
                    {row.title}
                  </a>
                ))}
              {formules &&
                formules.map((row, index) => (
                  <a
                    key={index}
                    className={`flex h-[40px] min-w-max cursor-pointer items-center  px-3 `}
                    style={
                      sexSelectedIndex === `formules${row.id}`
                        ? { ...getColorforNavbar().active }
                        : { ...getColorforNavbar().inactive }
                    }
                    id={`#formules${row.id}-trigger`}
                  >
                    {row.name}
                  </a>
                ))}
            </div>
          </div>
        )}
        <div className="  flex w-11/12  max-w-[1400px] flex-col items-center">
          {section &&
            section.map((row, index) => (
              <SmoothCard
                padding={row.title === "Commentaires"}
                className="my-10 w-full border border-white bg-transparent xl:w-4/5 "
                id={index}
                key={row.id}
              >
                <div
                  id={`section-${row.id}`}
                  trigger={`section-${row.id}`}
                  className="mb-5 w-full md:pb-7"
                  style={{ transition: "all 1s forwards" }}
                  onClick={() => {
                    setIdSection(row.id);
                  }}
                >
                  <div className="m-auto w-11/12 ">
                    {row.title !== "Commentaires" && (
                      <LogoPicker
                        key={index}
                        defaultLogo={row.avatar}
                        setLogo={(e) => {
                          setLogo(e), uploadAvatar(idSection, user.id, e);
                        }}
                        visual="smoothCard"
                        ratio={9 / 3}
                        wh={0}
                        iconBg={
                          customData &&
                          customData.color[0].custom.color === "#ffffff"
                            ? { color: "#000000" }
                            : {
                                color:
                                  customData &&
                                  customData.color[2].custom.color,
                              }
                        }
                        lib={"Ajouter un visuel à la catégorie (Facultatif)"}
                        className="w-full "
                        libColor={
                          customData && customData.color[1].custom.color
                        }
                        onClick={() =>
                          deleteAvatar(
                            section[index].avatar,
                            user.id,
                            "menuSection"
                          )
                        }
                      />
                    )}

                    <div className="flex items-center justify-between ">
                      <input
                        defaultValue={row.title}
                        id={`section${row.id}`}
                        placeholder="Nom de la catégorie..."
                        className={`bg-transparent
                  
                  ${
                    row.title === ""
                      ? "w-8/12 border border-grey-disabled pl-2 text-xl md:w-1/3"
                      : "w-8/12 truncate border-none pl-2 text-xl md:w-3/4"
                  }
                  ${
                    customData &&
                    findFont(customData.police[0].custom.fontFamily)
                  }
                  ${
                    customData &&
                    customData.police[0].custom.bold &&
                    "font-bold"
                  }
                  ${
                    customData && customData.police[0].custom.italic && "italic"
                  }
                `}
                        style={{
                          fontSize:
                            customData && customData.police[0].custom.fontSize,
                          letterSpacing:
                            customData &&
                            customData.police[0].custom.letterSpacing,
                          color: customData && customData.color[1].custom.color,
                          height: "1.8em",
                        }}
                        onBlur={(e) =>
                          changeMenuSection(
                            { title: e.target.value.trim() },
                            row.id
                          )
                        }
                      />

                      <div className="flex items-center">
                        <div className="mr-5 flex items-center hover:opacity-50">
                          <Icons
                            name="crayon-1"
                            style={{
                              color:
                                customData && customData.color[1].custom.color,
                            }}
                            height="20"
                          />
                          <Icons
                            name="crayon-2"
                            style={{
                              color:
                                customData && customData.color[1].custom.color,
                            }}
                            onClick={() =>
                              document
                                .getElementById(`section${row.id}`)
                                .focus()
                            }
                            height="20"
                          />
                        </div>
                        <div className="flex flex-col">
                          {section.length !== 1 && (
                            <>
                              {index != 0 && (
                                <Icons
                                  name="arrow-up"
                                  height="20"
                                  style={{
                                    color:
                                      customData &&
                                      customData.color[1].custom.color,
                                  }}
                                  className="mr-3 hover:opacity-50 active:opacity-100 md:mr-5"
                                  onClick={() =>
                                    changeSectionPosition(row, "up")
                                  }
                                />
                              )}
                              {index + 1 != section.length && (
                                <Icons
                                  name="arrow-down"
                                  height="20"
                                  style={{
                                    color:
                                      customData &&
                                      customData.color[1].custom.color,
                                  }}
                                  className="mr-3 hover:opacity-50 active:opacity-100 md:mr-5"
                                  onClick={() =>
                                    changeSectionPosition(row, "down")
                                  }
                                />
                              )}
                            </>
                          )}
                        </div>
                        <Icons
                          name="trash"
                          height="20"
                          style={{
                            color:
                              customData && customData.color[1].custom.color,
                          }}
                          onClick={() => {
                            removeMenuSection(row.id);
                          }}
                          className="px-2.5"
                        />
                      </div>
                    </div>
                    <HorizontalLine
                      className=" mt-2 mb-3 border-[1px]"
                      style={{
                        borderColor:
                          customData && customData.color[3].custom.color,
                      }}
                    />
                    <div>
                      <div>
                        {row &&
                          row.Dish &&
                          row.Dish.map((dish, index) => {
                            return (
                              <DishCreationPatron
                                dishData={dish}
                                dishId={dish.id}
                                dishList={row.Dish}
                                loadData={() => getOneMenu()}
                                custom={customData}
                                setLoading={setLoading}
                                index={index}
                                key={index}
                                length={row.Dish.length}
                              />
                            );
                          })}
                        {row.Dish.length > 0 ? (
                          <ThinHorizontalLine
                            className="mb-5 mt-5  md:mb-3 md:mt-0"
                            style={{
                              borderColor:
                                customData && customData.color[3].custom.color,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {row && row.DrinkVolume && row.DrinkVolume[0] && (
                          <DrinkVolumesEditor
                            volume={row.DrinkVolume[0]}
                            callback={(data) => setDrinkVolumes(data)}
                            custom={customData}
                          />
                        )}
                        {row &&
                          row.DrinkVolume &&
                          row.DrinkVolume[0] &&
                          row.DrinkVolume[0].DrinkCategory &&
                          row.DrinkVolume[0].DrinkCategory.map(
                            (drinkK, index) => (
                              <DrinkCreationPatron
                                drinkCategory={drinkK}
                                drinkCategoryId={drinkK.id}
                                menuSectionName={row.title}
                                loadData={() => getOneMenu()}
                                custom={customData}
                                setLoading={setLoading}
                                index={index}
                                key={index}
                                length={row.DrinkVolume[0].DrinkCategory.length}
                                volumes={
                                  drinkVolumes || {
                                    "15cl": row.DrinkVolume[0].x15cl,
                                    "25cl": row.DrinkVolume[0].x25cl,
                                    "37,5cl": row.DrinkVolume[0].x375cl,
                                    "50cl": row.DrinkVolume[0].x50cl,
                                    "75cl": row.DrinkVolume[0].x75cl,
                                  }
                                }
                              />
                            )
                          )}
                        {row &&
                        row.DrinkVolume &&
                        row.DrinkVolume[0] &&
                        row.DrinkVolume[0].DrinkCategory &&
                        row.DrinkVolume[0].DrinkCategory.length > 0 ? (
                          <ThinHorizontalLine
                            className="mb-5 mt-5  md:mb-3 md:mt-0"
                            style={{
                              borderColor:
                                customData && customData.color[3].custom.color,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {row &&
                          row.Comment &&
                          row.Comment.map((comment, index) => (
                            <CommentCreationPatron
                              loadData={() => getOneMenu()}
                              commentData={comment}
                              commentId={comment.id}
                              custom={customData}
                              index={index}
                              key={index}
                              length={row.Comment.length}
                              setLoading={setLoading}
                            />
                          ))}
                        {row.Comment.length > 0 ? (
                          <ThinHorizontalLine
                            className="mb-5 mt-5  md:mb-3 md:mt-0"
                            style={{
                              borderColor:
                                customData && customData.color[3].custom.color,
                            }}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <ThinHorizontalLine className="mb-5 md:mb-3  md:mt-0 mt-5" style={{ borderColor: customData && customData.color[3].custom.color,}}/> */}

                      <div className="flex flex-col items-center pt-3 md:block">
                        {row.isGlobal && row.title === "Vins" ? (
                          <DefaultButton
                            onClick={() => {
                              sendDrinkCategory(row.DrinkVolume[0].id);
                            }}
                            className=" "
                          >
                            Ajouter une sous catégorie de {row.title}
                          </DefaultButton>
                        ) : row.title === "Commentaires" ? (
                          <BorderedButton
                            onClick={() => {
                              sendComment(row.id);
                            }}
                            className=" mb-7  md:mb-0"
                            style={{
                              borderColor:
                                customData && customData.color[1].custom.color,
                              color:
                                customData && customData.color[1].custom.color,
                            }}
                          >
                            Ajouter un commentaire
                          </BorderedButton>
                        ) : (
                          <>
                            <DefaultButton
                              onClick={(e) => {
                                sendDish(row.id, e);
                              }}
                              className="mb-5 md:mb-0"
                            >
                              Ajouter un plat
                            </DefaultButton>
                            <BorderedButton
                              onClick={() => {
                                sendComment(row.id);
                              }}
                              className=" font-medium  md:ml-[35px]   md:mb-0"
                              style={{
                                borderColor:
                                  customData &&
                                  customData.color[1].custom.color,
                                color:
                                  customData &&
                                  customData.color[1].custom.color,
                              }}
                            >
                              Ajouter un commentaire
                            </BorderedButton>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </SmoothCard>
            ))}

          {formules &&
            formules.map((formule, index) => (
              <div
                key={formule.id}
                id={`formules${index}`}
                trigger={`formules${formule.id}`}
                className="flex w-full flex-col items-center md:my-8 "
              >
                <FormuleCreationPatron
                  formule={formule}
                  formuleId={formule.id}
                  loadData={() => getOneMenu()}
                  removeProperty={(one, two) => removeProperty(one, two)}
                  custom={customData}
                  formuleList={formules}
                  index={index}
                  length={formules.length}
                  setLoading={setLoading}
                />
                <CategorieSeparation
                  color={customData && customData.color[3].custom.color}
                />
              </div>
            ))}
          {loading && (
            <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50">
              <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
