import { useState, useEffect } from "react";
// * REDUX * //
import { useSelector } from "react-redux";
// * REQUETES * //
import { userSelector } from "store/models/userInfo";
import { fetchOneMenuByUrl } from "api/menu/menu";
import { findDataCustom } from "helpers/findDataCustom";
import { MenuPreview } from "components";

export default function Carte(props) {
  const [menu, setMenu] = useState(null);
  const [custom, setCustom] = useState(null);

  async function getOneMenu(url) {
    const menuResponse = await fetchOneMenuByUrl({ url });
    setMenu(menuResponse);
    setCustom(findDataCustom(menuResponse.Brand.Custom, menuResponse.Brand.id));
  }

  useEffect(() => {
    const url = props.match.params.url;
    if (url) {
      getOneMenu(url);
    }
  }, []);

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex w-full flex-col justify-center">
        <header className="mb-4 flex justify-center ">
          <img src={menu && menu.Brand.avatar} alt="brand-avatar" />
        </header>
        <div className="w-full">
          {custom && menu && <MenuPreview data={menu} custom={custom} />}
        </div>
      </div>
    </div>
  );
}
