import { useState, useEffect } from "react";
// * Composants * //
import { YellowTemplate, SearchBar } from "components";
import { HorizontalLine } from "components/atoms";
import { CreateOneBrandCard } from "components";
// * REDUX * //
import { useSelector } from "react-redux";
// * REQUETES * //
import { brandSelector } from "store/models/restaurantInfo";
import Search from "components/atoms/Search/SearchFilter";

export default function RestaurantPage() {
  const { brand } = useSelector(brandSelector);

  const [publishedBrand, setPublishedBrand] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");

  const filteredBrands = brand.filter(
    ({ name, city }) =>
      name == null ||
      name.toLowerCase().includes(searchFilter) ||
      city == null ||
      city.toLowerCase().includes(searchFilter)
  );

  useEffect(() => {
    const publishedBrands = brand.filter(
      ({ status }) => status === "PUBLISHED"
    );
    setPublishedBrand(publishedBrands);
  }, [brand]);

  return (
    <YellowTemplate>
      <h1 className="mt-7 text-center text-xl font-medium uppercase">
        Mes restaurants
      </h1>
      <div className="mx-11  sm:m-auto sm:w-9/12 md:w-3/4">
        <div className="flex flex-col-reverse md:flex md:flex-row md:justify-between ">
          {publishedBrand && publishedBrand.length > 1 ? (
            <p className="mb-2.5 mt-10 font-poppins text-sm font-medium md:mb-5 md:mt-10 md:text-xl">
              {publishedBrand && publishedBrand.length} restaurants publiés
            </p>
          ) : (
            <p className="mb-2.5 mt-10 font-poppins text-sm font-medium md:mb-5 md:mt-10 md:text-xl">
              {publishedBrand && publishedBrand.length} restaurant publié
            </p>
          )}

          <div>
            <label className="text-gray-400 focus-within:text-gray-600 relative mt-8 block ">
              <i
                className="icon-loupe pointer-events-none absolute top-1/2 left-3 -translate-y-1/2 transform text-black"
                style={{ fontSize: "25px" }}
              ></i>
              <Search
                placeholder="Rechercher "
                onChange={(filter) => setSearchFilter(filter.toLowerCase())}
                className="form-input border-gray-900 placeholder-gray-400 text-gray-500 block w-full appearance-none rounded border bg-transparent py-3 px-4 pl-14 focus:outline-none"
              />
            </label>
          </div>
        </div>
        <HorizontalLine className="mb-5" />
        <div className="mb-16 ">
          <CreateOneBrandCard restaurantList={filteredBrands} />
        </div>
      </div>
    </YellowTemplate>
  );
}
