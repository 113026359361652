import { Link } from "react-router-dom";

export function StepFooter({ selectedBrandId, linkNext, linkBack }) {
  return (
    <div
      className="m-auto mt-10 flex w-4/5  justify-between py-8 px-5 md:w-3/4 "
      style={{
        backgroundImage:
          "linear-gradient(to right, #333 10%, rgba(255, 255, 255, 0) 0%)",
        backgroundPosition: "top",
        backgroundSize: "15px 1.5px",
        backgroundRepeat: "repeat-x",
      }}
    >
      {linkBack && (
        <Link to={`/pages/dashboard/${linkBack}`}>
          <div className="flex items-center">
            <img src="/assets/arrowstep.svg" className="h-9 md:h-10" />
            <p className="ml-8 hidden font-medium uppercase md:block md:text-sm">
              {`étape précédente`}
            </p>
          </div>
        </Link>
      )}
      {linkNext && (
        <Link to={`/pages/dashboard/${linkNext}`}>
          <div className="flex items-center">
            <p className="mr-8 hidden font-medium uppercase md:block md:text-sm">
              {`étape suivante`}
            </p>
            <img
              src="/assets/arrowstep.svg"
              className="h-9 rotate-180 md:h-10"
            />
          </div>
        </Link>
      )}
    </div>
  );
}
