// COMPONENTS
import { Card, NavStepsButton } from "components/atoms";

import { Link } from "react-router-dom";

export function BrandStepsNavbar({ etape, selectedBrand }) {
  const navItems = [
    {
      number: "1",
      name: "Informations",
      link: "/pages/dashboard/",
      dots: "· · · · · · · · · · · · · · · · ·",
      smallDots: "· · · · ·",
    },
    {
      number: "2",
      name: "votre menu",
      link: "/pages/dashboard/menu/",
      dots: "· · · · · · · · · · · · · · · · ·",
      smallDots: "· · · · ·",
    },
    {
      number: "3",
      link: "/pages/dashboard/qrcode/",
      name: "Lancement",
      smallDots: "",
    },
  ];

  return (
    <nav className=" md:m-auto md:mt-4 md:flex md:items-center md:justify-center md:gap-5">
      {selectedBrand &&  selectedBrand.name || selectedBrand && selectedBrand.city ?
      <Card className="mx-5 mt-6 py-3 md:hidden">
        <div className="pl-2 text-center ">
          <p className="truncate uppercase">{selectedBrand.name}</p>
          <p className="truncate text-sm">{selectedBrand.city}</p>
        </div>
      </Card> : ""}

      <div className="hidden w-[250px] pl-2 text-center md:flex md:flex-col ">
        <p className="truncate whitespace-nowrap uppercase ">
          {selectedBrand.name}
        </p>
        <p className="truncate whitespace-nowrap text-sm">
          {selectedBrand.city}
        </p>
      </div>
      <div className=" pointer-events-none ml-3 mt-5 flex justify-center md:mt-0 md:ml-0 md:flex md:justify-center md:pr-6 ">
        {navItems.map((item, index) => (
          <Link to={`${item.link}${selectedBrand.id}`} key={index}>
            <NavStepsButton
              ButtonType={etape == item.number ? "validated" : ""}
              data={item}
            />
          </Link>
        ))}
      </div>
    </nav>
  );
}
