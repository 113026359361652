// COMPONENTS
import { Card, DefaultButton, Icons } from "components/atoms";
import { DeletePopUp } from "../Popup/DeletePopUp";
import { useState } from "react";
import { updateBrand } from "api/brand/brand";
import { getBrand } from "store/models/restaurantInfo";
import { deleteBrand } from "api/brand/brand";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { Link } from "react-router-dom";
import { Drag } from "components";
import {
  duplicateBrandContent,
  duplicateBrandCustom,
  duplicateOneBrand,
} from "api/custom-routes/duplicate";
import { fetchAllCustom } from "store/models/customInfo";
import { DuplicatePopUp } from "../Popup/DuplicatePopUp";
import { ErrorPopUp } from "../Popup/ErrorPopUp";

export function CreatedBrandCard({
  brandId,
  logo,
  name,
  city,
  position,
  status,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);
  const [drag, setDrag] = useState(false);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [showDuplicatePopUp, setShowDuplicatePopUp] = useState(false);

  async function deleteOneBrand(id) {
    await deleteBrand({ id });
    dispatch(getBrand());
  }

  async function duplicateBrand(id) {
    await duplicateOneBrand(id);
    dispatch(getBrand());
    dispatch(fetchAllCustom(user.id));
  }

  async function duplicateCustom(id, brands) {
    await duplicateBrandCustom(id, brands);
    dispatch(getBrand());
    dispatch(fetchAllCustom(user.id));
  }

  async function duplicateContent(id, brands) {
    await duplicateBrandContent(id, brands);
    dispatch(getBrand());
  }

  const changeBrand = async (data) => {
    await updateBrand(brandId, data);
    dispatch(getBrand());
  };

  return (
    <div className="md:flex md:flex-wrap md:justify-between">
      <Card className="my-10 h-[317px] md:my-2 md:h-[320px] md:w-[226px] ">
        <div className="flex w-full justify-center">
          <div
            className={`-mt-3  w-2/4 rounded text-center font-medium text-white ${
              status === "DRAFT"
                ? "bg-yellow-draft"
                : status === "PUBLISHED" && "bg-green"
            }`}
          >
            {status === "DRAFT"
              ? "En cours"
              : status === "PUBLISHED" && "Publié"}
          </div>
        </div>
        <div className=" mt-3 flex  h-[292px] flex-col items-center justify-between md:mt-5 md:h-[269px]">
          <div className="flex flex-col items-center">
            <img
              src={logo ? logo : "/assets/placeholders/restaurant.jpg"}
              className="h-20 w-20 rounded"
              alt="logo"
            />
            {name.length ? (
              <div className=" mt-4 h-[28px]">
                <div className="flex justify-center">
                  <p className="font-regular w-[210px] truncate text-center font-poppins text-xl">
                    {name}
                  </p>
                </div>
              </div>
            ) : (
              <div className="mt-5" />
            )}
            <div>
              <div className="flex justify-center">
                <p className="font-regular font-poppins text-sm">{city}</p>
              </div>
            </div>
          </div>
          <div className="mb-5 flex flex-col items-center md:mb-0">
            <div
              className="mb-5 flex items-center md:mb-0"
              style={{ marginTop: "32px" }}
            >
              <Link
                className="relative  flex"
                to={`/pages/dashboard/${brandId}`}
              >
                <Icons
                  name="crayon-1  "
                  height="20"
                  className="text-yellow hover:text-yellow-hover "
                />
                <Icons
                  name="crayon-2  "
                  height="20"
                  className="  border-black hover:text-yellow-dark"
                />
              </Link>
              <Icons
                name="copy-paste"
                height="17"
                className="mx-5 rounded-full bg-yellow p-1.5 hover:bg-yellow-hover hover:duration-200"
                onClick={() => {
                  setShowDuplicatePopUp(true);
                }}
              />

              {
                drag ? (
                  <Drag
                    position={position}
                    changePosition={(pos) => {
                      changeBrand({ positionId: Number(pos) }), setDrag(false);
                    }}
                  />
                ) : (
                  ""
                )
                //  : (
                //   <Icons
                //     className="mx-2"
                //     name="move"
                //     height="20"
                //     onClick={() => setDrag(true)}
                //   />
                // )
              }
              <Icons
                name="trash"
                height="20"
                className=" "
                onClick={() => {
                  setShowDeletePopUp(true);
                }}
              />
              {showDeletePopUp && (
                <DeletePopUp
                  onValidate={() => {
                    deleteOneBrand(brandId);
                    setShowDeletePopUp(false);
                  }}
                  onCancel={() => setShowDeletePopUp(false)}
                >
                  Etes vous sûr de vouloir supprimer le restaurant&nbsp;
                  <label className="font-bold">{name}</label>
                </DeletePopUp>
              )}
              {showDuplicatePopUp && (
                <DuplicatePopUp
                  button="Dupliquer"
                  onDuplicateAll={() => {
                    duplicateBrand(brandId);
                    setShowDuplicatePopUp(false);
                  }}
                  onDuplicateStyle={(brands) => {
                    duplicateCustom(brandId, brands);
                    setShowDuplicatePopUp(false);
                  }}
                  onDuplicateContent={(brands) => {
                    duplicateContent(brandId, brands);
                    setShowDuplicatePopUp(false);
                  }}
                  onCancel={() => setShowDuplicatePopUp(false)}
                />
              )}
            </div>
            <Link to={`/pages/dashboard/menu/${brandId}`}>
              <DefaultButton className=" md:mt-2">Editer Menu</DefaultButton>
            </Link>
          </div>
        </div>
      </Card>
    </div>
  );
}
