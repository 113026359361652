import joinClasses from "helpers/joinClasses";

export function ThinHorizontalLine({ className = "", ...props }) {
  return (
    <div
      className={joinClasses(
        className,
        "m-auto w-full w-auto rounded-full border-[0.5px] border-grey-disabled"
      )}
      {...props}
    ></div>
  );
}
