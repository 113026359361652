import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";
import LoginPage from "../pages/auth/login";
import RegisterPage from "pages/auth/register/register";
import Carte from "pages/carte";
import { LegalNotice } from "pages/LegalNotice";
import { CGU } from "pages/CGU";

export default function AuthenticationRouter() {
  return (
    <BrowserRouter>
      <Router>
        <Switch>
          <Route exact path="/auth/register" component={RegisterPage} />
          <Route exact path="/carte/:url" component={Carte} />
          <Route exact path="/mentions-legales" component={LegalNotice} />
          <Route exact path="/CGU" component={CGU} />
          <Route path="/" component={LoginPage} />
        </Switch>
      </Router>
    </BrowserRouter>
  );
}
