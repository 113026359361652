// COMPONENTS
import { YellowTemplate } from "components";
import { useEffect, useRef, useState } from "react";

import ReCAPTCHA from "react-google-recaptcha";
import EmailStep from "./components/EmailStep";
import PasswordStep from "./components/PasswordStep";
import InformationsStep from "./components/InformationsStep";
import { useDispatch, useSelector } from "react-redux";
import { fetchResgister, userSelector } from "store/models/userInfo";

export default function RegisterPage() {
  const [userInfo, setUserInfo] = useState({});
  const recaptchaRef = useRef();
  const [step, setStep] = useState(1);
  const dispatch = useDispatch();

  const sendRegistration = async (form, token) => {
    const { payload } = await dispatch(
      fetchResgister({
        ...form,
        token: token,
        country: "FR",
      })
    );

    if (payload.success === false) {
      setStep(3);
      if (payload.code === "E040") {
        return { success: false, error: "Ce compte existe déjà." };
      } else if (payload.code === "RECAPTCHA_FAILED") {
        return {
          success: false,
          error: "Une erreur est survenue. Veuillez réessayer.",
        };
      } else {
        return { success: false, error: payload.message };
      }
    }

    return { success: true };
  };

  return (
    <YellowTemplate
      isConnected={false}
      className="max-w-screen overflow-hidden"
    >
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
      />

      <EmailStep
        show={step === 1}
        setStep={setStep}
        form={userInfo}
        setForm={setUserInfo}
      />

      <PasswordStep
        show={step === 2}
        setStep={setStep}
        form={userInfo}
        setForm={setUserInfo}
      />

      <InformationsStep
        show={step === 3}
        setStep={setStep}
        form={userInfo}
        setForm={setUserInfo}
        recaptcha={recaptchaRef}
        sendRegistration={sendRegistration}
      />
    </YellowTemplate>
  );
}
