import { Card, DefaultButton, Icons, LogoPicker } from "components/atoms";
import { useEffect, useState } from "react";
import { TimePatron } from "../../molecules/Patrons/TimePatron";
import "../../molecules/Cards/switch.css";
import addAlpha from "helpers/addAlpha";
import { fetchSelectedBrand, updateBrand } from "api/brand/brand";
import { Link } from "react-router-dom";

export function BrandInfoPratiquePreview({
  customData,
  isInfoOpen = false,
  setIsInfoOpen = () => {},
  selectedBrand,
  currentSection = false,
  setCurrentSection = () => {},
}) {
  // const { user } = useSelector(userSelector);
  // const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [schedule, setSchedule] = useState({
    Lundi: null,
    Mardi: null,
    Mercredi: null,
    Jeudi: null,
    Vendredi: null,
    Samedi: null,
    Dimanche: null,
  });
  const [links, setLinks] = useState(null);

  useEffect(() => {
    if (selectedBrand) {
      setDisabled(selectedBrand.isSchedule);
      setSchedule({
        Lundi: selectedBrand.schedule && selectedBrand.schedule.Lundi,
        Mardi: selectedBrand.schedule && selectedBrand.schedule.Mardi,
        Mercredi: selectedBrand.schedule && selectedBrand.schedule.Mercredi,
        Jeudi: selectedBrand.schedule && selectedBrand.schedule.Jeudi,
        Vendredi: selectedBrand.schedule && selectedBrand.schedule.Vendredi,
        Samedi: selectedBrand.schedule && selectedBrand.schedule.Samedi,
        Dimanche: selectedBrand.schedule && selectedBrand.schedule.Dimanche,
      });
      const linksTab = JSON.parse(selectedBrand.links) || [];
      linksTab.length && setLinks(linksTab);
    }
  }, [selectedBrand]);

  return (
    <Card
      className={`fixed right-0 top-1/2 h-full -translate-y-2/4  transform font-poppins sm:h-auto  ${
        !isInfoOpen && "hidden"
      } z-50 w-full bg-white shadow-special  transition-all  sm:w-[400px]`}
      style={{
        backgroundColor: addAlpha(customData.color[0].custom.color, 0.85),
      }}
    >
      <div className="flex h-full flex-col justify-center">
        {/* <LogoPicker className={` w-full !my-0 relative h-[240px]`}/> */}

        <div className="w-full overflow-y-auto overflow-x-hidden  ">
          <div className="top-0 left-0 m-auto flex w-full items-center justify-between ">
            <div className="ml-4 flex gap-4">
              <Icons
                name="info"
                className=" pointer-events-none right-0 top-0"
                style={{
                  color: customData.color[2].custom.color,
                }}
                height={25}
              />
              <h3
                className="flex items-center  text-sm font-bold"
                style={{
                  color: customData.color[2].custom.color,
                }}
              >
                Infos pratiques
              </h3>
            </div>
            <button
              className="bg-yellow"
              style={{
                width: "52px",
                height: "52px",
                backgroundColor: customData.color[1].custom.color,
              }}
              onClick={() => {
                setIsInfoOpen(false), setCurrentSection(0);
              }}
            >
              <Icons
                name="add"
                height={20}
                className="block  rotate-45 transform"
                style={{
                  color: customData.color[0].custom.color,
                }}
              />
            </button>
          </div>
          <div className=" m-auto flex w-11/12 flex-col ">
            {selectedBrand &&
              (selectedBrand.name ||
                selectedBrand.address1 ||
                selectedBrand.address2 ||
                selectedBrand.zip ||
                selectedBrand.city) && (
                <div
                  className="relative m-auto  mt-3 flex max-h-[126px] w-full flex-col  justify-center rounded pl-[14px] mb-2"
                  style={{
                    color: customData.color[2].custom.color,
                  }}
                >
                  {selectedBrand && selectedBrand.name && (
                    <p className="mb-2 text-2xl font-bold">
                      {selectedBrand && selectedBrand.name}
                    </p>
                  )}
                  <p>
                    {selectedBrand && selectedBrand.address1}
                    {selectedBrand && selectedBrand.address2 && (
                      <p>{selectedBrand.address2}</p>
                    )}
                  </p>
                  <p>
                    {selectedBrand && selectedBrand.zip}{" "}{selectedBrand && selectedBrand.city}
                  </p>
                </div>
              )}
            {selectedBrand && selectedBrand.phone ? (
              <div
                className={`m-auto  mt-2.5 flex w-11/12 items-center justify-between ${
                  !selectedBrand.isSchedule && "mb-2"
                }`}
              >
                <a href={`tel:${selectedBrand && selectedBrand.phone}`}>
                  <DefaultButton
                    style={{
                      backgroundColor: customData.color[1].custom.color,
                    }}
                    className={
                      "flex w-[256px] items-center justify-between gap-5 !py-3 !pl-[15px] !pr-[41px]"
                    }
                  >
                    <Icons
                      name="phone1"
                      height={30}
                      className="pointer-events-none"
                      style={{
                        color: customData.color[0].custom.color,
                      }}
                    />
                    <p
                      className=" text-xl font-bold "
                      style={{
                        color: customData.color[0].custom.color,
                      }}
                    >
                      {selectedBrand && selectedBrand.phone}
                    </p>
                  </DefaultButton>
                </a>
              </div>
            ) : (
              ""
            )}

            {selectedBrand && selectedBrand.isSchedule && (
              <div
                className={`mt-[22px] flex flex-col pl-[14px] ${
                  !links && "mb-2"
                } `}
              >
                <div className="mb-4 flex items-center gap-2">
                  <h4
                    className="text-xs font-bold "
                    style={{
                      color: customData.color[1].custom.color,
                    }}
                  >
                    HORAIRES :
                  </h4>
                </div>
                {selectedBrand &&
                  selectedBrand.isSchedule &&
                  Object.keys(schedule).map((day, index) => (
                    <TimePatron
                      customData={customData}
                      data={schedule[day]}
                      disabled={!disabled}
                      key={index}
                      isPreview={true}
                    >
                      {day}
                    </TimePatron>
                  ))}
              </div>
            )}
            {links && (
              <div className="pl-[14px]">
                <div className="mt-4 mb-2  flex items-center gap-2">
                  <p
                    className="text-xs font-bold"
                    style={{
                      color: customData.color[1].custom.color,
                    }}
                  >
                    COMMANDER :{" "}
                  </p>
                </div>
                <div className="flex flex-col gap-1">
                  {links &&
                    links.map((value, index) => (
                      <p key={index} className="mb-2 cursor-pointer text-sm">
                        {value}
                      </p>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}
