// COMPONENTS
import { Template } from "components";
import {
  DefaultButton,
  SelectButton,
  BorderedButton,
  Title,
  Number,
  Paragraph,
  SubTitle,
  Icons,
} from "components/atoms";
//
import { useState } from "react";
export default function UiPage() {
  const [selectButton, setSelectButton] = useState(false);
  return (
    <Template>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <Title className="mb-4 border-b-2">Buttons</Title>
          <div className="flex flex-col gap-2">
            <DefaultButton>JE COMMENCE À CRÉER MA CARTE</DefaultButton>
            <br />
            <DefaultButton>Ajouter une catégorie</DefaultButton>
            <DefaultButton>Nous contacter</DefaultButton>
            <BorderedButton>Ajouter un plat</BorderedButton>
            <br />
            <BorderedButton>Ajouter les ingrédients</BorderedButton>
            <SelectButton
              isActive={selectButton}
              onClick={() => setSelectButton(!selectButton)}
            >
              Sélectionner
            </SelectButton>
          </div>
        </div>
        <div className="text-center">
          <Title className="mb-4 border-b-2">Texts</Title>
          <div className="p-5">
            <Title>Title</Title>
            <SubTitle>SubTitle</SubTitle>
            <Paragraph>Paragraph</Paragraph>
            <Number>Number</Number>
          </div>
        </div>
      </div>
    </Template>
  );
}
