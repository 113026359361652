import {
  BorderedButton,
  Card,
  DefaultButton,
  HorizontalLine,
  Icons,
  Title,
} from "components/atoms";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { brandSelector } from "store/models/restaurantInfo";

export function DuplicatePopUp({
  onDuplicateAll = () => {},
  onDuplicateStyle = () => {},
  onDuplicateContent = () => {},
  onCancel = () => {},
}) {
  const { brand } = useSelector(brandSelector);
  const [etape, setEtape] = useState(1);
  const [title, setTitle] = useState(null);
  const [brandSelected, setBrandSelected] = useState({});

  return (
    <div className="fixed left-0 top-0 z-40 flex h-full w-full justify-center">
      <div
        className="absolute top-0 h-full w-full bg-grey-disabled bg-opacity-50"
        onClick={() => onCancel()}
      ></div>

      <div className="relative my-auto w-11/12 max-w-[1400px]  ">
        <Card className=" m-auto w-full px-5 lg:w-3/4 ">
          <div className="flex">
            <img
              className="cursor-pointer "
              src={"/assets/back-arrow.svg"}
              alt="GO BACK"
              onClick={() => {
                if (etape === 2) {
                  setEtape(1);
                  setTitle(null);
                } else {
                  onCancel();
                }
              }}
            />
            <Title TextSize="smaller" className="m-auto p-4  !text-center">
              {title ? `Copier le ${title}` : "Que souhaitez-vous faire ?"}
            </Title>
          </div>
          <HorizontalLine className="ml-16" />
          {etape === 1 && (
            <div className="mt-8 flex flex-col flex-wrap content-center items-start gap-4 pb-10">
              <div className="flex flex-row items-center gap-10">
                <Icons
                  name="duplicate"
                  height={30}
                  className="pointer-events-none"
                />
                <DefaultButton onClick={() => onDuplicateAll()}>
                  Dupliquer la carte
                </DefaultButton>
              </div>
              <div className="flex flex-row items-center gap-10">
                <Icons
                  name="brush-solid"
                  height={30}
                  className="pointer-events-none"
                />
                <DefaultButton
                  onClick={() => {
                    setEtape(2);
                    setTitle("style");
                  }}
                >
                  Copier le style
                </DefaultButton>
              </div>
              <div className="flex flex-row items-center gap-10">
                <Icons
                  name="copy-content"
                  height={30}
                  className="pointer-events-none"
                />

                <DefaultButton
                  onClick={() => {
                    setEtape(2);
                    setTitle("contenu");
                  }}
                >
                  Copier le contenu
                </DefaultButton>
              </div>
            </div>
          )}
          {etape === 2 && (
            <div className="mt-8 flex flex-col flex-wrap content-center items-start gap-4 pb-10">
              <p>À quel restaurant souhaitez-vous appliquer le {title} ?</p>
              <div className="flex flex-row">
                <div className="flex max-h-80 flex-col overflow-auto">
                  {brand.map((brand) => (
                    <div className="my-2 flex items-center">
                      <input
                        className="form-checkbox text-xl text-black"
                        type="checkbox"
                        id={brand.id}
                        checked={brandSelected && brandSelected[brand.id]}
                        onClick={(e) =>
                          setBrandSelected({
                            ...brandSelected,
                            [brand.id]: e.target.checked,
                          })
                        }
                      />
                      <label
                        className="flex  flex-col-reverse items-center text-xs md:flex md:flex-row md:items-center md:align-middle md:text-base"
                        for={brand.id}
                      >
                        {brand.avatar && (
                          <img
                            src={brand.avatar}
                            alt=""
                            className="ml-5 h-12 border border-solid"
                          />
                        )}
                        <div className="ml-5">
                          <p className="mx-2.5">{brand.name}</p>
                          <p className="mx-2.5">{brand.city}</p>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <DefaultButton
                onClick={(e) => {
                  if (title === "style") {
                    onDuplicateStyle(brandSelected);
                  } else if (title === "contenu") {
                    onDuplicateContent(brandSelected);
                  }
                }}
              >
                Appliquer
              </DefaultButton>
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
