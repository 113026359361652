import { registerUser } from "api/authentication";
import { Container } from "components";
import { DefaultButton, RestoInput } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { fetchResgister, userSelector } from "store/models/userInfo";

export default function InformationsStep({
  show = false,
  setStep = () => {},
  form = {},
  setForm = () => {},
  recaptcha,
  sendRegistration = async () => {},
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const sendForm = async (e) => {
    e.preventDefault();
    if (loading) return;
    const token = await recaptcha.current.executeAsync();
    if (!token) return;
    recaptcha.current.reset();

    setLoading(true);
    setErrorMessage(null);

    const result = await sendRegistration(form, token);
    setLoading(false);
    if (!result.success) return setErrorMessage(result.error);
    else {
      setSuccess(true);
      setTimeout(() => {
        history.push("/");
      }, 1000);
    }
  };

  return (
    <div
      className={joinClasses(
        show
          ? "animate__animated animate__faster animate__fadeInRight"
          : "hidden"
      )}
    >
      <Container title={"Bienvenue"} className="!max-w-[600px] p-8 pb-[25px]">
        <p className="mb-10 text-center">
          Renseignez vos informations personnelles
        </p>
        <form className="mt-7 flex flex-col items-center" onSubmit={sendForm}>
          <RestoInput
            name="lastname"
            type="text"
            placeholder="Nom"
            onChange={(e) => setForm({ ...form, lastname: e.target.value })}
            required
          />
          <RestoInput
            name="firstname"
            type="text"
            placeholder="Prénom"
            onChange={(e) => setForm({ ...form, firstname: e.target.value })}
            required
          />
          <RestoInput
            name="phone"
            type="tel"
            placeholder="Numéro de téléphone"
            onChange={(e) => setForm({ ...form, phone: e.target.value })}
            required
          />
          {errorMessage && (
            <p className="px-4 py-1 text-sm text-red animate__animated animate__faster animate__fadeIn">
              {errorMessage}
            </p>
          )}
          {success && (
            <p className="mb-3 bg-green px-4 py-1 text-sm text-white animate__animated animate__fadeIn">
              Votre compte a bien été créé !
            </p>
          )}

          <DefaultButton type="submit">
            {loading ? "Chargement..." : "Valider"}
          </DefaultButton>
        </form>
      </Container>
    </div>
  );
}
