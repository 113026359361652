import { updateDrinkVolume } from "api/drinkVolumes/drinkVolumes";
import { useState, useEffect } from "react";

export function DrinkVolumesEditor({ volume, custom, callback = () => {} }) {
  const [volumes, setVolumes] = useState([]);

  const changeDrinkVolume = async (data) => {
    await updateDrinkVolume(volume.id, data);
  };

  useEffect(() => {
    if (volume) {
      setVolumes({
        "15cl": volume.x15cl,
        "25cl": volume.x25cl,
        "37,5cl": volume.x375cl,
        "50cl": volume.x50cl,
        "75cl": volume.x75cl,
      });
    }
  }, [volume]);

  useEffect(() => {
    if (volumes) {
      callback(volumes);
      changeDrinkVolume({
        x15cl: volumes["15cl"],
        x25cl: volumes["25cl"],
        x375cl: volumes["37,5cl"],
        x50cl: volumes["50cl"],
        x75cl: volumes["75cl"],
      });
    }
  }, [volumes]);

  return (
    <div className="flex items-center pl-0  md:pl-2">
      <div className="flex sm:gap-6 md:items-center  md:justify-start md:align-middle  ">
        {[
          { cl: "15cl", width: "3", height: "7" },
          { cl: "25cl", width: "4", height: "8" },
          { cl: "37,5cl", width: "3", height: "12" },
          { cl: "50cl", width: "10", height: "10" },
          { cl: "75cl", width: "4", height: "16" },
        ].map((cl, index) => (
          <div
            key={index}
            className="flex flex-col-reverse items-center md:mx-0 md:flex md:flex-row md:items-center  md:align-middle "
          >
            <input
              className="form-checkbox cursor-pointer text-xl text-black"
              type="checkbox"
              id={`${cl.cl}${volume.id}`}
              checked={volumes[cl.cl]}
              onClick={(e) =>
                setVolumes({ ...volumes, [cl.cl]: e.target.checked })
              }
              style={{ color: custom && custom.color[1].custom.color }}
            />
            <label
              className="flex  flex-col-reverse items-center text-xs md:flex md:flex-row md:items-center md:align-middle md:text-base"
              htmlFor={`${cl.cl}${volume.id}`}
            >
              <p
                className="mx-2.5"
                style={{ color: custom && custom.color[1].custom.color }}
              >
                {cl.cl}
              </p>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
}
