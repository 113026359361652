import { DefaultButton } from "components/atoms/";

// ## QR CODE
import { useEffect, useState } from "react";
// import QRCode from "react-qr-code";
import { QRCode } from "react-qrcode-logo";

export function QrCode({ menu }) {
  const [qrcodeImg, setQrcodeImg] = useState("");

  useEffect(() => {
    if (document.getElementById("react-qrcode-logo")) {
      var canvas = document.getElementById("react-qrcode-logo");
      setQrcodeImg(canvas.toDataURL());
    }
  }, [document.getElementById("react-qrcode-logo")]);

  return (
    <div className="mt-8 mb-8 flex flex-col items-center gap-5" id="myqrcode">
      {menu && menu.url && (
        <>
          <QRCode
            size={150}
            value={`${process.env.REACT_APP_PUBLIC_URL}/carte/${menu.url}`}
            qrStyle="squares"
            eyeRadius={6}
          />
          <p className="text-xl font-medium">Votre QRcode a bien été crée !</p>
          <p className="text-sm">
            Vous pouvez le&nbsp;
            {qrcodeImg && (
              <a
                download={`qrcode-${menu && menu.url}.png`}
                href={qrcodeImg}
                className="underline"
              >
                télécharger ici.
              </a>
            )}
          </p>
        </>
      )}
    </div>
  );
}
