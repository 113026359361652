import { Container } from "components";
import { DefaultButton, RestoInput, SelectButton } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useState } from "react";
import { useHistory } from "react-router";

export default function EmailStep({
  show = false,
  setStep = () => {},
  form = {},
  setForm = () => {},
}) {
  const [errorMessage, setErrorMessage] = useState(null);
  const history = useHistory();
  const sendForm = async (e) => {
    e.preventDefault();
    if (form.email && form.email.length) {
      setErrorMessage(null);
      setStep(2);
    } else {
      setErrorMessage("Veuillez renseigner votre email");
    }
  };

  return (
    <div className={joinClasses(show ? "" : "hidden")}>
      <Container
        title={"Commencez votre essai Bonappli gratuit"}
        className="!max-w-[600px] p-8 pb-[25px]"
      >
        {/* <div className="">
              <Icons name="chevron" height={25} className={`ml-5 `} />
            </div> */}
        <div className="mt-7 flex flex-col items-center">
          <form
            className="flex w-full flex-col items-center"
            onSubmit={sendForm}
          >
            <RestoInput
              name="email"
              placeholder="Adresse e-mail"
              type="email"
              className="lowercase"
              onChange={(e) => setForm({ email: e.target.value.toLowerCase() })}
              value={form.email || ""}
            />
            {errorMessage && (
              <p className="px-4 py-1 text-sm text-red">{errorMessage}</p>
            )}
            <DefaultButton type="submit">Essayer gratuitement</DefaultButton>
          </form>

          <div className=" text-center sm:mt-10 ">
            <p className="mt-10 text-sm sm:mt-0">Vous avez déjà un compte ?</p>
            <SelectButton
              onClick={() => {
                history.push("/");
              }}
              className={`!mt-2 !mb-0 !font-medium`}
            >
              Se connecter
            </SelectButton>
          </div>
        </div>
      </Container>
    </div>
  );
}
