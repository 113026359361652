import { Icons } from "components/atoms";
import style from "./Default.module.css";

export function DefaultModal({
  children,
  isOpen,
  setIsOpen = () => {},
  title,
  className,
}) {
  return (
    <>
      <div
        className={style.default_modal__backgound_trigger}
        style={isOpen ? { display: "block" } : { display: "none" }}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <div
        className="fixed top-1/2 left-1/2 z-[9999] max-h-[90vh] min-h-[20vh] w-full max-w-[300px] -translate-x-1/2 -translate-y-1/2 transform overflow-x-auto rounded-sm rounded-lg bg-white animate__animated animate__fadeIn sm:max-w-sm md:max-w-xl"
        style={isOpen ? { display: "block" } : { display: "none" }}
      >
        <div className="m-auto mx-3 flex items-center justify-between py-3 px-2">
          {title ? <h1>{title}</h1> : ""}
          <button
            className="flex items-center"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Icons name="add" className="rotate-45 transform" height={20} />
          </button>
        </div>
        <div className="flex flex-col items-center py-5">{children}</div>
      </div>
    </>
  );
}
