import Button from "./Button";
import joinClasses from "helpers/joinClasses";

export function DefaultButton({
  children,
  ButtonType,
  className = "",
  onClick,
  isActive,
  ...props
}) {
  if (ButtonType === "smaller") {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(
          className,
          "bg-yellow py-2 px-7 font-poppins text-xs hover:bg-yellow-hover   hover:transition hover:duration-300 active:opacity-50  md:text-sm"
        )}
        {...props}
      >
        {children}
      </Button>
    );
  } else if (isActive) {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(
          className,
          "bg-yellow py-[15px]  px-10 font-poppins text-xs font-medium hover:bg-yellow-hover hover:transition hover:duration-300 active:opacity-50 md:text-sm"
        )}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      onClick={onClick}
      className={joinClasses(
        className,
        "bg-yellow py-[15px]  px-10 font-poppins text-xs font-medium hover:bg-yellow-hover hover:transition hover:duration-300 active:opacity-50 md:text-sm"
      )}
      {...props}
    >
      {children}
    </Button>
  );
}
