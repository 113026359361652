import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function sendBrand(userId, name, city) {
  try {
    let brandData = { userId: userId, name: name, city: city };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/brand`,
      brandData,
      getFormatedToken()
    );
    const { data } = response.data;
    return data;
  } catch (error) {}
}

export async function sendBrandLogo(brandId, userId, logo) {
  if (brandId && logo && userId) {
    const formData = new FormData();
    formData.append("avatar", logo);
    formData.append("userId", userId);
    const contentType = true;
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/brand/${brandId}/avatar`,
      formData,
      getFormatedToken(contentType)
    );

    const { data } = response.data;
    return data;
  } else {
    return false;
  }
}

export async function deleteBrand({ id }) {
  if (!id) {
    return;
  }
  try {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/brand/${id}`,
      getFormatedToken()
    );
  } catch (error) {}
}

export async function fetchBrand() {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/brand`,
      getFormatedToken()
    );
    const { data } = response.data;
    return data.brands;
  } catch (error) {
    return [];
  }
}

export async function fetchSelectedBrand({ id }) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/brand/${id}`,
      getFormatedToken()
    );
    const { data } = response.data;
    return data.brand;
  } catch (error) {
    return false;
  }
}

export async function updateBrand(id, payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/brand/${id}`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data;
  } catch (error) {
    return null;
  }
}

export async function updateLogo(logo, userId) {
  const formData = new FormData();
  formData.append("avatar", logo);
  formData.append("userId", userId);
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/brand/${id}/avatar`,
      formData,
      getFormatedToken()
    );

    const { data } = response.data;
    return data.brand;
  } catch (error) {}
}
