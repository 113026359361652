import { useState, useEffect } from "react";

// * Composants * //
import {
  YellowTemplate,
  BrandStepsNavbar,
  AddCategorieFormule,
  StepFooter,
} from "components";
import { Icons, ShowMenuSideButton } from "components/atoms";
// * REDUX * //
import { useSelector } from "react-redux";

// * REQUETES * //
import { userSelector } from "store/models/userInfo";
import { fetchSelectedBrand } from "api/brand/brand";
import { MenuSideButtons } from "components/molecules/Navbars/MenuSideButtons";
import { customSelector } from "store/models/customInfo";
import { findDataCustom } from "helpers/findDataCustom";
import { useLocation } from "react-router";
import { getPositionWithPercent } from "helpers/scrollConvert";

export default function OneRestaurantPageMenu(props) {
  const { user } = useSelector(userSelector);
  const location = useLocation();
  const brandId = props.match.params.id;
  const [brand, setBrand] = useState(null);
  const [isOpen, setIsOpen] = useState("basic");
  async function getOneBrand() {
    setBrand(await fetchSelectedBrand({ token: user.token, id: brandId }));
  }

  function toOpen() {
    if (isOpen === "basic") {
      setIsOpen(true);
    } else {
      setIsOpen(!isOpen);
    }
  }

  useEffect(() => {
    getOneBrand();
  }, []);

  const custom = useSelector(customSelector);
  const [customData, setCustomData] = useState(null);
  useEffect(() => {
    if (custom) {
      setCustomData(findDataCustom(custom.customs, Number(brandId)));
    }
  }, [custom]);

  useEffect(() => {
    if (location.state && location.state.isOpenSideMenu) {
      setIsOpen(location.state.isOpenSideMenu);
    }
  }, [location]);

  return (
    <>
      <YellowTemplate title={brand && brand.name ? brand.name : "votre menu"}>
        <ShowMenuSideButton
          className="z-50 flex items-center justify-center  lg:hidden"
          onClick={() => toOpen()}
          stateOpen={isOpen}
          style={{
            backgroundColor: customData && customData.color[1].custom.color,
          }}
        >
          <Icons
            name="add"
            height={25}
            className={` ${
              isOpen === "basic"
                ? ""
                : isOpen === true
                ? "rotate-45 duration-300"
                : "-rotate-0 duration-300"
            }`}
            style={{
              color: customData && customData.color[0].custom.color,
            }}
          />
        </ShowMenuSideButton>

        <MenuSideButtons
          isAppear={isOpen}
          brandId={Number(brandId)}
          selectedBrand={brand}
          historyState={{ isOpenSideMenu: isOpen }}
        />

        {brandId && brand && (
          <>
            <BrandStepsNavbar etape={2} selectedBrand={brand} />
            <AddCategorieFormule
              selectedBrand={brand}
              selectedBrandId={brandId}
              loadData={() => getOneBrand()}
              custom={customData}
            />
            <StepFooter
              selectedBrandId={brandId}
              step={2}
              linkBack={brandId}
              linkNext={`qrcode/${brandId}`}
            />
          </>
        )}
      </YellowTemplate>
    </>
  );
}
