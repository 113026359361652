import { Icons } from "components/atoms";
import { findFont } from "helpers/findDataCustom";
import { PricesPreview } from "components";
const reactStringReplace = require("react-string-replace");

export function DrinksPreview({ dish, index, custom }) {
  const bottle = [
    { name: "x15cl", cl: "15cl", width: "3", height: 28 },
    { name: "x25cl", cl: "25cl", width: "4", height: 32 },
    { name: "x375cl", cl: "37,5cl", width: "3", height: 48 },
    { name: "x50cl", cl: "50cl", width: "9", height: 40 },
    { name: "x75cl", cl: "75cl", width: "4", height: 64 },
  ];

  return (
    <div className="max-w-screen flex w-11/12 flex-col gap-2 overflow-auto md:w-2/3">
      {/*  On liste les pictos et leur label si ils sont coché (valeur à true) */}
      <table>
        <thead className="pointer-events-none flex w-full items-end  justify-around md:justify-end ">
          {custom &&
            custom.display[0].visible &&
            bottle.map(
              (b, index) =>
                dish.DrinkVolume[0][b.name] && (
                  <tr
                    key={index}
                    className={`${
                      dish.DrinkVolume[0][b.name] === true
                        ? " flex h-[96px] flex-col items-center justify-end "
                        : "opacity-0"
                    } md:w-[70px] `}
                  >
                    <td className="text-center">
                      <Icons
                        name={`drink${b.cl}`}
                        height={b.height}
                        className={`w-${b.width}`}
                        style={{ color: custom.color[1].custom.color }}
                      />
                      <p
                        className="mt-2 text-sm font-normal"
                        style={{ color: custom.color[1].custom.color }}
                      >
                        {dish.DrinkVolume[0][b.name] === true
                          ? ` ${b.cl} `
                          : "  "}
                      </p>
                    </td>
                  </tr>
                )
            )}
        </thead>
      </table>

      {/* CATEGORIE DRINKS IN DESKTOP*/}

      {dish &&
        dish.DrinkVolume[0] &&
        dish.DrinkVolume[0].DrinkCategory.sort((a, b) =>
          a.positionId > b.positionId ? 1 : -1
        ).map((drinks, index) => (
          <table
            key={index}
            className={`hidden table-fixed md:table  ${
              drinks.name === null || drinks.name === "" ? "md:hidden" : ""
            }`}
          >
            <tbody>
              <tr>
                <td
                  className={`font-bold 
                          ${
                            custom &&
                            findFont(custom.police[2].custom.fontFamily)
                          }
                          ${custom.police[2].custom.bold ? "font-bold" : ""}
                          ${custom.police[2].custom.italic ? "italic" : ""}
                        `}
                  style={{
                    fontSize: `${custom.police[2].custom.fontSize}px`,
                    letterSpacing: `${custom.police[2].custom.letterSpacing}px`,
                    color: custom.color[2].custom.color,
                  }}
                >
                  {drinks.name && drinks.name}
                </td>

                {custom &&
                  custom.display[0].visible &&
                  bottle.map(
                    (b, index) => drinks[b.name] && <td key={index} />
                  )}
              </tr>
              {drinks.Drink.map((drink, index) => (
                <tr
                  key={index}
                  style={{
                    color: custom.color[2].custom.color,
                  }}
                  className={`${
                    drink.name === null || drink.name === "" ? "hidden" : ""
                  }`}
                >
                  <td className="flex items-center justify-between gap-2">
                    <p
                      className={`min-w-max max-w-[418px] md:max-w-[160px] lg:max-w-[545px]
                            ${
                              custom &&
                              findFont(custom.police[3].custom.fontFamily)
                            }
                            ${custom.police[3].custom.bold ? "font-bold" : ""}
                            ${custom.police[3].custom.italic ? "italic" : ""}
                          `}
                      style={{
                        fontSize: `${custom.police[3].custom.fontSize}px`,
                        letterSpacing: `${custom.police[3].custom.letterSpacing}px`,
                        color: custom.color[2].custom.color,
                      }}
                    >
                      {drink.name}
                    </p>

                    {custom.display[0].visible ||
                    (!custom.display[0].visible &&
                      dish.DrinkVolume[0][bottle[0].name] === "false" &&
                      dish.DrinkVolume[1][bottle[1].name] === "false" &&
                      dish.DrinkVolume[2][bottle[2].name] === "false" &&
                      dish.DrinkVolume[3][bottle[3].name] === "false" &&
                      dish.DrinkVolume[4][bottle[4].name] === "false") ? (
                      <div
                        className="h-0 w-full border-t border-dotted border-black"
                        style={{
                          borderColor: custom.color[3].custom.color,
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </td>

                  {custom.display[0].visible && (
                    <>
                      {dish.DrinkVolume[0][bottle[0].name] === true && (
                        <PricesPreview custom={custom} table>
                          {drink[bottle[0].name] === null ||
                          drink[bottle[0].name] === "" ? (
                            <div
                              className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              -
                            </div>
                          ) : (
                            <div
                              className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              {" "}
                              {reactStringReplace(
                                `${drink[bottle[0].name]}`,
                                "€",
                                (match, i) => ""
                              )}
                              €
                            </div>
                          )}
                        </PricesPreview>
                      )}
                      {dish.DrinkVolume[0][bottle[1].name] && (
                        <PricesPreview
                          custom={custom}
                          className="text-center "
                          table
                        >
                          { drink[bottle[1].name] === null ||
                            drink[bottle[1].name] === "" ? (
                            <div
                              className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              -
                            </div>
                          ) : (
                            <div
                              className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              {" "}
                              {reactStringReplace(
                                `${drink[bottle[1].name]}`,
                                "€",
                                (match, i) => ""
                              )}
                              €
                            </div>
                          )}
                        </PricesPreview>
                      )}
                      {dish.DrinkVolume[0][bottle[2].name] && (
                        <PricesPreview
                          custom={custom}
                          className="text-center"
                          table
                        >
                          {drink[bottle[2].name] === null ||
                          drink[bottle[2].name] === "" ? (
                            <div
                              className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              -
                            </div>
                          ) : (
                            <div
                              className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              {" "}
                              {reactStringReplace(
                                `${drink[bottle[2].name]}`,
                                "€",
                                (match, i) => ""
                              )}
                              €
                            </div>
                          )}
                        </PricesPreview>
                      )}
                      {dish.DrinkVolume[0][bottle[3].name] && (
                        <PricesPreview
                          custom={custom}
                          className="text-center"
                          table
                        >
                          {drink[bottle[3].name] === null ||
                          drink[bottle[3].name] === "" ? (
                            <div
                              className={`
                          ${
                            custom &&
                            findFont(custom.police[4].custom.fontFamily)
                          }
                          ${custom.police[4].custom.bold ? "font-bold" : ""}
                          ${custom.police[4].custom.italic ? "italic" : ""}
                        `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              -
                            </div>
                          ) : (
                            <div
                              className={`
                          ${
                            custom &&
                            findFont(custom.police[4].custom.fontFamily)
                          }
                          ${custom.police[4].custom.bold ? "font-bold" : ""}
                          ${custom.police[4].custom.italic ? "italic" : ""}
                        `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              {" "}
                              {reactStringReplace(
                                `${drink[bottle[3].name]}`,
                                "€",
                                (match, i) => ""
                              )}
                              €
                            </div>
                          )}
                        </PricesPreview>
                      )}
                      {dish.DrinkVolume[0][bottle[4].name] && (
                        <PricesPreview
                          custom={custom}
                          className="text-center"
                          table
                        >
                          {drink[bottle[4].name] === null ||
                          drink[bottle[4].name] === "" ? (
                            <div
                              className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              -
                            </div>
                          ) : (
                            <div
                              className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                              style={{
                                fontSize: `${custom.police[4].custom.fontSize}px`,
                                letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              {" "}
                              {reactStringReplace(
                                `${drink[bottle[4].name]}`,
                                "€",
                                (match, i) => ""
                              )}
                              €
                            </div>
                          )}
                        </PricesPreview>
                      )}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      {/* CATEGORIE DRINKS IN MOBILE*/}
      {dish &&
        dish.DrinkVolume[0] &&
        dish.DrinkVolume[0].DrinkCategory.sort((a, b) =>
          a.positionId > b.positionId ? 1 : -1
        ).map((drinks, index) => {
          return (
            <div className="block md:hidden " key={index}>
              <div>
                <div>
                  <h3
                    className={`mt-6 mb-3 font-bold 
                          ${
                            custom &&
                            findFont(custom.police[2].custom.fontFamily)
                          }
                          ${custom.police[2].custom.bold ? "font-bold" : ""}
                          ${custom.police[2].custom.italic ? "italic" : ""}
                        `}
                    style={{
                      fontSize: `${custom.police[2].custom.fontSize}px`,
                      letterSpacing: `${custom.police[2].custom.letterSpacing}px`,
                      color: custom.color[2].custom.color,
                    }}
                  >
                    {drinks.name}
                  </h3>
                </div>
                {drinks.Drink.map((drink, index) => (
                  <div key={index}>
                    <p
                      className={` mb-2 pl-4
                            ${
                              custom &&
                              findFont(custom.police[3].custom.fontFamily)
                            }
                            ${custom.police[3].custom.bold ? "font-bold" : ""}
                            ${custom.police[3].custom.italic ? "italic" : ""}
                          `}
                      style={{
                        fontSize: `${custom.police[3].custom.fontSize}px`,
                        letterSpacing: `${custom.police[3].custom.letterSpacing}px`,
                        color: custom.color[2].custom.color,
                      }}
                    >
                      {drink.name}
                    </p>

                    {custom.display[0].visible && (
                      <div className="mb-6 flex justify-around ">
                        {dish.DrinkVolume[0][bottle[0].name] === true && (
                          <PricesPreview
                            custom={custom}
                            className="text-center"
                          >
                            {drink.x15cl === null || drink.x15cl === "" ? (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                -
                              </div>
                            ) : (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[0].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PricesPreview>
                        )}
                        {dish.DrinkVolume[0][bottle[1].name] === true && (
                          <PricesPreview
                            custom={custom}
                            className="text-center"
                          >
                            {drink[bottle[1].name] === null ||
                            drink[bottle[1].name] === "" ? (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                -
                              </div>
                            ) : (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[1].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PricesPreview>
                        )}
                        {dish.DrinkVolume[0][bottle[2].name] === true && (
                          <PricesPreview
                            custom={custom}
                            className="text-center"
                          >
                            {drink[bottle[2].name] === null ||
                            drink[bottle[2].name] === "" ? (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                -
                              </div>
                            ) : (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[2].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PricesPreview>
                        )}
                        {dish.DrinkVolume[0][bottle[3].name] === true && (
                          <PricesPreview
                            custom={custom}
                            className="text-center"
                          >
                            {drink[bottle[3].name] === null ||
                            drink[bottle[3].name] === "" ? (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                -
                              </div>
                            ) : (
                              <div
                                className={`
                              ${
                                custom &&
                                findFont(custom.police[4].custom.fontFamily)
                              }
                              ${custom.police[4].custom.bold ? "font-bold" : ""}
                              ${custom.police[4].custom.italic ? "italic" : ""}
                            `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[3].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PricesPreview>
                        )}
                        {dish.DrinkVolume[0][bottle[4].name] === true && (
                          <PricesPreview
                            custom={custom}
                            className="text-center"
                          >
                            {drink[bottle[4].name] === null ||
                            drink[bottle[4].name] === "" ? (
                              <div
                                className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                -
                              </div>
                            ) : (
                              <div
                                className={`
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                                style={{
                                  fontSize: `${custom.police[4].custom.fontSize}px`,
                                  letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
                                  color: custom.color[2].custom.color,
                                }}
                              >
                                {" "}
                                {reactStringReplace(
                                  `${drink[bottle[4].name]}`,
                                  "€",
                                  (match, i) => ""
                                )}
                                €
                              </div>
                            )}
                          </PricesPreview>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          );
        })}
    </div>
  );
}
