import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function duplicateOneBrand(brandId) {
  if (brandId) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/duplicate/${brandId}`,
      {},
      getFormatedToken()
    );

    const { data } = response.data;
    return data;
  } else {
    return false;
  }
}

export async function duplicateBrandCustom(brandId, ids) {
  if (brandId) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/duplicate/style/${brandId}`,
      ids,
      getFormatedToken()
    );

    const { data } = response.data;
    return data;
  } else {
    return false;
  }
}

export async function duplicateBrandContent(brandId, ids) {
  if (brandId) {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/duplicate/content/${brandId}`,
      ids,
      getFormatedToken()
    );

    const { data } = response.data;
    return data;
  } else {
    return false;
  }
}
