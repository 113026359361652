/* Components */
import { BigCard, Icons } from "components/atoms";
import Cookies from "universal-cookie";

export default function AccountPag({ setPage, page }) {
  const focus = "bg-yellow";
  const cookies = new Cookies();
  return (
    <>
      {/* DESKTOP FORMAT */}
      <BigCard className="flex hidden w-full flex-row md:block md:w-[250px] md:min-w-[250px] md:flex-col ">
        <div className="flex w-full flex-col items-center border-b py-7">
          <Icons name="user" height="90" />
        </div>

        <div className="my-5 mr-5 flex flex-row gap-4 md:flex-col">
          <button
            className={`rounded-r-full pl-10 text-left transition-all md:py-2 md:py-2 ${
              page == "account" ? focus : "bg-white"
            }`}
            onClick={() => {
              setPage("account");
            }}
          >
            Mon compte
          </button>
          <button
            className={`rounded-r-full pl-10 text-left transition-all md:py-2 md:py-2 ${
              page == "restaurants" ? focus : "bg-white"
            }`}
            onClick={() => {
              setPage("restaurants");
            }}
          >
            Mes établissement
          </button>
          {/* <button
            className={`text-left pl-10 ${page == "sub" && focus}`}
            onClick={() => {
              setPage("sub");
            }}
          >
            Abonnement
          </button> */}
        </div>

        <div className="border-t text-center">
          <button
            className="my-2 cursor-pointer"
            onClick={() => {
              cookies.remove("::token", { path: "/" });
              setTimeout(() => {
                window.location.href = "/";
              }, 500);
            }}
          >
            Se déconnecter
          </button>
        </div>
      </BigCard>

      {/* MOBILE FORMAT */}
      <div className="flex w-full flex-row items-center overflow-x-scroll pl-5 pr-8 pb-3  pt-3  md:hidden">
        <div className=" mr-7 flex w-full flex-col items-center ">
          <Icons name="user" height="35" />
        </div>

        <div className="flex h-full flex-row  gap-2  text-xs ">
          <button
            className={`min-w-max rounded-full px-7 py-2 text-left  ${
              page == "account" && focus
            }`}
            onClick={() => {
              setPage("account");
            }}
          >
            Mon profil
          </button>
          <button
            className={`min-w-max rounded-full px-7 py-2 text-left  ${
              page == "restaurants" && focus
            }`}
            onClick={() => {
              setPage("restaurants");
            }}
          >
            Mes établissement
          </button>
          {/* <button
            className={`text-left px-7  py-2 min-w-max ${
              page == "sub" && focus
            }`}
            onClick={() => {
              setPage("sub");
            }}
          >
            Abonnement
          </button> */}
          <button
            className=" min-w-max cursor-pointer py-2 px-2 pr-5 font-medium"
            onClick={() => {
              cookies.remove("::token", { path: "/" });
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }}
          >
            Se déconnecter
          </button>
        </div>
      </div>
    </>
  );
}
