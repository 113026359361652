import { BasicFooter } from "components";
import { MainNavbar } from "components";

export function Template(props) {
  return (
    <div className="flex h-screen flex-col font-poppins ">
      <header>
        {" "}
        <MainNavbar />{" "}
      </header>
      <main className="mb-auto">{props.children}</main>
      <footer>
        <BasicFooter backgroundColor={"yellow"} />
      </footer>
    </div>
  );
}
