import getFormatedToken from "api/helpers/getFormatedToken";
import axios from "axios";

export async function createFormuleElement(token, id) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/formuleelement`,
    { formuleId: id },

    getFormatedToken()
  );
  const data = response.data;

  return data;
}

export async function updateFormuleElement(id, payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/formuleelement/${id}`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data;
  } catch (error) {}
}

export async function deleteFormuleElement({ id, token }) {
  await axios({
    method: "DELETE",
    url: `${process.env.REACT_APP_API_URL}/formuleelement/${id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
