import { deleteFormule, updateFormule } from "api/formule/formule";
import { createFormuleElement } from "api/formule/formuleElement";
import {
  DefaultButton,
  HorizontalLine,
  Icons,
  ThinHorizontalLine,
  Title,
} from "components/atoms";
import { findFont } from "helpers/findDataCustom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { userSelector } from "store/models/userInfo";
import { FormuleElementCreationPatron } from "./FormuleElementCreationPatron";
import { positionDown, positionUp } from "api/position";

export function FormuleCreationPatron({
  formule,
  formuleId,
  loadData = () => {},
  custom,
  formuleList,
  setLoading,
  index,
  length,
  removeProperty = () => {},
}) {
  const { user } = useSelector(userSelector);

  const sendFormuleElement = async () => {
    const { data } = await createFormuleElement(user.token, formuleId);
    await loadData();
    focusInput("formule-element", data.formuleElement.id);
  };

  const removeFormule = async () => {
    await deleteFormule({ id: formuleId, token: user.token });
    loadData();
  };

  const changeFormuleName = async (name, id) => {
    await updateFormule(id, name);
    loadData();
  };

  const changePosition = async (section, action) => {
    if (action === "up") {
      const firstFormule = document.getElementById(
        `formules${section.positionId - 1}`
      );
      const secondFormule = document.getElementById(
        `formules${section.positionId - 2}`
      );
      firstFormule.style.transition = "transform";
      firstFormule.style.transitionDuration = "0.5s";
      firstFormule.style.transitionTimingFunction = "forwards";
      secondFormule.style.transitionDuration = "0.5s";
      secondFormule.style.transitionTimingFunction = "forwards";
      firstFormule.style.transform = `translateY(-${
        secondFormule.offsetHeight + 64
      }px)`;
      secondFormule.style.transform = `translateY(${
        firstFormule.offsetHeight + 64
      }px)`;

      await positionDown(section.id, "formule");
      setTimeout(function () {
        removeProperty(firstFormule, secondFormule);
      }, 500);
    }

    if (action === "down") {
      const firstFormule = document.getElementById(
        `formules${section.positionId - 1}`
      );
      const secondFormule = document.getElementById(
        `formules${section.positionId}`
      );
      firstFormule.style.transition = "transform";
      firstFormule.style.transitionDuration = "0.5s";
      firstFormule.style.transitionTimingFunction = "forwards";
      secondFormule.style.transitionDuration = "0.5s";
      secondFormule.style.transitionTimingFunction = "forwards";
      firstFormule.style.transform = `translateY(${
        secondFormule.offsetHeight + 64
      }px)`;
      secondFormule.style.transform = `translateY(-${
        firstFormule.offsetHeight + 64
      }px)`;

      await positionUp(section.id, "formule");
      setTimeout(function () {
        removeProperty(firstFormule, secondFormule);
      }, 500);
    }
  };

  return (
    <div
      className=" mt-8 mb-6 mb-6 w-full bg-yellow-lightest p-3 md:my-8 md:p-8 xl:w-4/5"
      style={{ backgroundColor: custom && custom.color[4].custom.color }}
    >
      <div className="flex md:flex-row  md:justify-between">
        <div className="mb-2 flex justify-between">
          <input
            className={` w-9/12 bg-transparent md:w-full 
          ${custom && findFont(custom.police[1].custom.fontFamily)}
          ${custom && custom.police[1].custom.bold && "font-bold"}
          ${custom && custom.police[1].custom.italic && "italic"}
        `}
            style={{
              fontSize: custom && custom.police[1].custom.fontSize,
              letterSpacing: custom && custom.police[1].custom.letterSpacing,
              color: custom && custom.color[0].custom.color,
            }}
            defaultValue={formule.name}
            onBlur={(e) =>
              changeFormuleName({ name: e.target.value.trim() }, formule.id)
            }
          />

          <input
            className={` w-2/12 bg-transparent text-center md:hidden 
              ${custom && findFont(custom.police[1].custom.fontFamily)}
              ${custom && custom.police[1].custom.bold && "font-bold"}
              ${custom && custom.police[1].custom.italic && "italic"}
              ${custom && !custom.display[0].visible && "opacity-50"}
            `}
            style={{
              fontSize: custom && custom.police[1].custom.fontSize,
              letterSpacing: custom && custom.police[1].custom.letterSpacing,
              color: custom && custom.color[0].custom.color,
            }}
            defaultValue={formule.price}
            onBlur={(e) =>
              changeFormuleName({ price: e.target.value.trim() }, formule.id)
            }
          />
        </div>
        <div className="flex items-center justify-end gap-5 pb-3 md:flex md:items-center  md:gap-0 md:pb-0 ">
          <div className="flex justify-end  ">
            <input
              className={`hidden   w-1/3 bg-transparent text-center md:mr-5  md:block
              ${custom && findFont(custom.police[1].custom.fontFamily)}
              ${custom && custom.police[1].custom.bold && "font-bold"}
              ${custom && custom.police[1].custom.italic && "italic"}
              ${custom && !custom.display[0].visible && "opacity-50"}
            `}
              style={{
                fontSize: custom && custom.police[1].custom.fontSize,
                letterSpacing: custom && custom.police[1].custom.letterSpacing,
                color: custom && custom.color[0].custom.color,
              }}
              defaultValue={formule.price}
              onBlur={(e) =>
                changeFormuleName({ price: e.target.value.trim() }, formule.id)
              }
            />

            {custom && !custom.display[0].visible && (
              <div className="absolute top-0 left-0 h-full w-full cursor-not-allowed" />
            )}
          </div>

          <div className="flex flex-col">
            {length !== 1 && (
              <>
                {index != 0 && (
                  <Icons
                    name="arrow-up"
                    height="20"
                    style={{
                      color: custom && custom.color[0].custom.color,
                    }}
                    className=" hover:opacity-50 active:opacity-100 md:mr-5"
                    onClick={() => changePosition(formule, "up")}
                  />
                )}
                {index + 1 != length && (
                  <Icons
                    name="arrow-down"
                    height="20"
                    style={{
                      color: custom && custom.color[0].custom.color,
                    }}
                    className=" hover:opacity-50 active:opacity-100 md:mr-5"
                    onClick={() => changePosition(formule, "down")}
                  />
                )}
              </>
            )}
          </div>
          <Icons
            name="trash"
            height="20"
            style={{
              color: custom && custom.color[0].custom.color,
            }}
            onClick={() => removeFormule()}
            className="px-2.5"
          />
        </div>
      </div>
      <HorizontalLine
        style={{
          borderColor: custom && custom.color[0].custom.color,
        }}
        // Sur la maquette c'est noir (commenté au cas ou faut remettre x) )
        // style={{
        //   borderColor: custom && custom.color[3].custom.color,
        // }}
      />
      {formule.FormuleElement.map((elem, index) => (
        <FormuleElementCreationPatron
          key={elem.id}
          custom={custom}
          formuleElement={elem}
          formuleElementId={elem.id}
          loadData={() => loadData()}
          formuleElementList={formule.FormuleElement}
          setLoading={setLoading}
          index={index}
          length={formule.FormuleElement.length}
        />
      ))}
      <DefaultButton className="my-8" onClick={() => sendFormuleElement()}>
        Ajouter un élément de formule
      </DefaultButton>
    </div>
  );
}
