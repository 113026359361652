import joinClasses from "helpers/joinClasses";

export function CardInput(
  { onChange = () => {}, value = "", placeholder, className = "" },
  props
) {
  return (
    <input
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      {...props}
      className={joinClasses(
        className,
        `max-w-[360px]  border-b border-grey-disabled bg-transparent  pl-1 pb-1  font-poppins text-black  outline-none placeholder:text-grey-dark md:text-sm`
      )}
      style={{
        border: "none",
        borderBottom: `1px solid ${props.borderColor || "#B3B3B3"}`,
      }}
    />
  );
}
