import axios from "axios";
import getFormatedToken from "../helpers/getFormatedToken";

export async function getAllDishes() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/dish`,
    getFormatedToken()
  );

  return response;
}

export async function createDish(id) {
  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/dish`,
    { menuSectionId: id },

    getFormatedToken()
  );
  const data = response.data;
  return data;
}

export async function updateDish(id, payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/dish/${id}`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data;
  } catch (error) {}
}

export async function sendDishLogo({ id, userId, logo }) {
  if (id && logo && userId) {
    const formData = new FormData();
    formData.append("avatar", logo);
    formData.append("userId", userId);
    const contentType = true;
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/dish/${id}/avatar`,
      formData,
      getFormatedToken(contentType)
    );

    const { data } = response;

    return data;
  } else {
    return false;
  }
}

export async function deleteDish({ id, userId }) {
  if (!id) {
    return;
  }
  try {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/dish/${id}/${userId}`,
      getFormatedToken()
    );
  } catch (error) {}
}
