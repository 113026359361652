import Cookies from "universal-cookie";

export default function getFormatedToken(
  contentType = false,
  jsonData = false
) {
  const cookies = new Cookies();

  if (cookies.get("::token") && !contentType && !jsonData) {
    return {
      headers: {
        Authorization: `Bearer ${cookies.get("::token")}`,
      },
    };
  } else if (cookies.get("::token") && contentType && !jsonData) {
    return {
      headers: {
        "Content-Type": "multipart/form-data; boundary=something",
        Authorization: `Bearer ${cookies.get("::token")}`,
      },
    };
  } else if (cookies.get("::token") && !contentType && jsonData) {
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${cookies.get("::token")}`,
      },
    };
  }
}
