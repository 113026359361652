import joinClasses from "helpers/joinClasses";

export function CategorieSeparation({ className = "", color }) {
  return (
    <div
      className={joinClasses(className, "m-auto flex  w-10/12 items-center")}
    >
      <div
        className="h-1 w-1 rotate-45 transform bg-black md:h-2 md:w-2 "
        style={{ backgroundColor: color }}
      ></div>
      <div
        className="mx-6 h-0 w-5/6 border-2 border-solid border-black bg-black md:w-full"
        style={{ borderColor: color }}
      ></div>
      <div
        className="h-1 w-1 rotate-45 transform bg-black md:h-2 md:w-2 "
        style={{ backgroundColor: color }}
      ></div>
    </div>
  );
}
