import { useState } from "react";
import { Icons, Paragraph } from "components/atoms";
import { CropImage } from "components";
import { useEffect } from "react";
import joinClasses from "helpers/joinClasses";

export function LogoPicker({
  defaultLogo,
  onChange,
  mt = true,
  mb = true,
  wh = 40,
  he = "h-auto",
  visual = "",
  setLogo = () => {},
  reset,
  lib = "Ajouter un logo",
  ratio = 4 / 4,
  className = "",
  createH = "auto",
  libColor,
  onClick,
  iconBg,
}) {
  const [avatar, setAvatar] = useState(null);
  const [avatarBlob, setAvatarBlob] = useState(null);

  useEffect(() => {
    setAvatarBlob(null);
  }, [reset]);

  useEffect(() => {
    if (avatarBlob && defaultLogo) {
      setAvatarBlob(null);
    }
  }, [defaultLogo]);
  const renderAvatarForm = () => {
    if (avatarBlob) {
      return avatarBlob;
    } else if (defaultLogo) {
      return `${defaultLogo}`;
    } else {
      return "%icon%";
    }
  };

  return (
    <div
      className={joinClasses(
        className,
        `${
          !renderAvatarForm() && "rounded border border-solid border-grey-light"
        } ${mt && `mt-10`} ${mb && `mb-6`} flex justify-center ${
          visual == "smoothCard" ? "h-32 w-full md:h-80" : "m-auto h-32 w-full"
        } relative`
      )}
    >
      <input
        type="file"
        className="absolute h-full w-full cursor-pointer opacity-0"
        onChange={(e) => {
          setAvatar(e.target.files[0]);
        }}
        name="file"
      />
      {avatar && (
        <CropImage
          sendImage={() => {
            setLogo(avatar);
            // setAvatarBlob(null);
          }}
          setImage={setAvatar}
          setImageBlob={setAvatarBlob}
          src={avatar}
          ratio={ratio}
        />
      )}
      {renderAvatarForm() === "%icon%" ? (
        <div className="flex flex-col items-center justify-center">
          <div className="mx-10 my-3 flex h-16 w-16 items-center justify-center rounded-full  bg-transparent md:h-20 md:w-20 ">
            <Icons name="add-picture" height="28" style={iconBg} />
          </div>
          <p
            className={`text-center font-poppins text-sm font-light`}
            style={{ color: libColor }}
          >
            {lib}
          </p>
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `url('${renderAvatarForm()}')`,
            }}
            className={`flex w-full flex-col items-center justify-center bg-cover bg-no-repeat h-${createH} `}
          />
          <div
            onClick={onClick}
            className="absolute top-0 right-0 flex h-5 w-5 -translate-y-1/4 translate-x-1/4 transform items-center justify-center rounded shadow-md"
            style={{ backgroundColor: "#FE4A49" }}
          >
            <Icons
              name="add"
              className="rotate-45 transform text-white"
              height="13"
            />
          </div>
        </>
      )}
    </div>
  );
}
