import { findFont } from "helpers/findDataCustom";
import joinClasses from "helpers/joinClasses";
const reactStringReplace = require("react-string-replace");

export function FormulePreviewCard({ formule, custom }) {
  return (
    <div
      className="m-auto mb-3 w-11/12 px-4 py-4 md:w-2/3 md:px-10 md:py-8"
      id={`formules${formule.id}`}
      trigger={`formules${formule.id}`}
      style={{
        backgroundColor: custom ? custom.color[4].custom.color : "black",
      }}
    >
      <div className="flex justify-between">
        <h1
          className={joinClasses(
            custom
              ? findFont(custom.police[1].custom.fontFamily)
              : "font-poppins",
            custom.police[1].custom.bold ? "font-bold" : "",
            custom.police[1].custom.italic ? "italic" : "",
            "mb-3 flex flex-col justify-between text-white md:flex-row"
          )}
          style={{
            fontSize: `${custom.police[1].custom.fontSize}px`,
            letterSpacing: `${custom.police[1].custom.letterSpacing}px`,
            color: custom.color[0].custom.color,
          }}
        >
          {formule.name}
        </h1>

        {custom.display[0].visible && (
          <div
            style={{
              fontSize: `${custom.police[1].custom.fontSize}px`,
              letterSpacing: `${custom.police[1].custom.letterSpacing}px`,
              color: custom.color[0].custom.color,
            }}
            className={joinClasses(
              custom
                ? findFont(custom.police[1].custom.fontFamily)
                : "font-poppins",
              custom.police[1].custom.bold ? "font-bold" : "",
              custom.police[1].custom.italic ? "italic" : "",
              "mb-3 flex flex-col justify-between text-white md:flex-row"
            )}
          >
            {formule.price === null || formule.price === "" ? (
              <div>-</div>
            ) : (
              <div>
                {" "}
                {reactStringReplace(`${formule.price}`, "€", (match, i) => "")}€
              </div>
            )}
          </div>
        )}
      </div>
      <div>
        {formule.FormuleElement.sort((a, b) =>
          a.positionId > b.positionId ? 1 : -1
        ).map((element, index) => (
          <>
            <div key={index}>
              <h3
                className={`text-lg text-white ${findFont(
                  custom.police[2].custom.fontFamily
                )}
                ${custom.police[2].custom.bold && "font-bold"}
                ${custom.police[2].custom.italic && "italic"}
                ${!custom.display[0].visible && "opacity-50"}`}
                style={{
                  fontSize: custom.police[2].custom.fontSize,
                  letterSpacing: custom.police[2].custom.letterSpacing,
                  color: custom.color[0].custom.color,
                }}
              >
                {element.name}
              </h3>

              <ul
                className={`my-4 ml-4 text-black ${findFont(
                  custom.police[3].custom.fontFamily
                )}
                ${custom.police[3].custom.bold && "font-bold"}
                ${custom.police[3].custom.italic && "italic"}
                ${!custom.display[0].visible && "opacity-50"} `}
                style={{
                  fontSize: custom.police[3].custom.fontSize,
                  letterSpacing: custom.police[3].custom.letterSpacing,
                  color: custom.color[0].custom.color,
                }}
              >
                {element.subElements &&
                  Object.keys(element.subElements).map((key, index) => (
                    <li key={index} className="mt-4 ">
                      {element.subElements[key]}
                    </li>
                  ))}
              </ul>
            </div>
            {formule.FormuleElement.length !== index + 1 && (
              <div className="relative my-8">
                <div
                  className="h-0 border-t border-orange"
                  style={{
                    borderColor: custom.color[3].custom.color,
                  }}
                />
                <span
                  className="absolute top-1/2 left-1/2 h-[47px] -translate-x-1/2 -translate-y-1/2 transform font-poppins text-5xl font-black md:mt-[1px]"
                  style={{
                    color: custom.color[3].custom.color,
                  }}
                >
                  +
                </span>
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
