import {
  DefaultButton,
  Icons,
  Paragraph,
  SelectButton,
} from "components/atoms";
import { useEffect, useState } from "react";
import { PopUp } from "./popUp";
import { updateBrand } from "api/brand/brand";
import { Link } from "react-router-dom";
import Button from "components/atoms/Buttons/Button";

export function LaunchedCard({
  getOneMenu,
  setGenerateQrCode,
  setUpdated,
  brandId,
  menuId,
  getBrand,
}) {
  const [url, setUrl] = useState("");
  const [copy, setCopy] = useState(false);
  const [security, setSecurity] = useState(false);

  const handleQRCodeGeneration = () => {
    setGenerateQrCode(true);
  };

  const returnEdition = async () => {
    await updateBrand(brandId, { status: "DRAFT" });
    await getBrand();
    setUpdated(false);
  };

  const getUrl = async () => {
    const menu = await getOneMenu(menuId);
    setUrl(`${process.env.REACT_APP_PUBLIC_URL}/carte/` + menu.url);
  };

  useEffect(() => {
    getUrl();
  }, []);

  return (
    <div>
      <div className="mt-4 flex flex-col items-center px-8 md:px-0">
        <h1 className="text-xl font-bold">Félicitations !</h1>
        <p className="my-4 text-xs">
          Vous carte est désormais disponible sur l'adresse suivante :
        </p>
        <div className="flex w-2/3 flex-col items-center gap-2 md:mb-3 md:flex-row md:justify-around md:gap-0">
          <Paragraph className="text-base underline">
            <a href={url} target="_blank">
              {url}
            </a>
          </Paragraph>

          <Button
            className="bg-yellow py-[5px] px-[15px] text-xs font-medium"
            onClick={() => {
              navigator.clipboard.writeText(url);
              setCopy(true);
              setTimeout(() => {
                setCopy(false);
              }, 2000);
            }}
          >
            {copy ? "Copié !" : "Copier"}
          </Button>
        </div>
        <SelectButton
          className=" flex cursor-pointer  items-center gap-2 rounded-full bg-white px-[35px] py-2 !text-sm shadow-light active:shadow-inner md:py-6"
          onClick={() => handleQRCodeGeneration()}
        >
          <Icons name="qr-code" height="14" /> Génerer mon QR code
        </SelectButton>
        <p className="text-center text-xs">
          Vous souhaitez un nom de domaine personnalisé ? <br />
          Vous pouvez à tout moment{" "}
          <a href="# " className="font-medium underline">
            nous contacter
          </a>
          .
        </p>
      </div>
    </div>
  );
}
