import { fetchOneMenu } from "api/menu/menu";
import { fetchSelectedBrand } from "api/brand/brand";

import { Card, HorizontalLine, Title } from "components/atoms";

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userSelector } from "store/models/userInfo";
import { customSelector } from "store/models/customInfo";

//structure
import { ConfigureUrl } from "./configureUrl";
import { LaunchedCard } from "./launchedCard";
import { QrCode } from "./qrCode";
import { findDataCustom } from "helpers/findDataCustom";
import editColor from "utils/editColor";
import editDisplay from "utils/editDisplay";
import editFont from "utils/editFont";

export function LaunchCard({ menuId, brandId, brand }) {
  const { user } = useSelector(userSelector);

  const [menu, setMenu] = useState(brand.Menu[0] || null);

  const [updated, setUpdated] = useState(false);
  const [generateQrCode, setGenerateQrCode] = useState(false);

  const getOneMenu = async () => {
    const newMenu = await fetchOneMenu({ id: menuId });
    setMenu(newMenu);
    return newMenu;
  };
  const getBrand = async () => {
    setBrand(await fetchSelectedBrand({ id: brandId }));
  };

  /* Get existing URL */
  useEffect(() => {
    if (menu && menu.url && brand && brand.status == "PUBLISHED") {
      setUpdated(true);
    }
  }, [menu, brand]);

  return (
    <div className="mt-4 flex justify-center px-3 pb-5 md:p-0">
      <Card className="pb-4 md:w-[900px] ">
        {!updated ? (
          <div>
            <Title TextSize="smaller" className="ml-8 p-4 !font-bold">
              Votre URL
            </Title>
            <HorizontalLine />
            <ConfigureUrl
              menuId={menuId}
              brandId={brandId}
              user={user}
              getOneMenu={getOneMenu}
              existingUrl={menu && menu.url ? menu.url : null}
              setUpdated={setUpdated}
            />
          </div>
        ) : !generateQrCode ? (
          <LaunchedCard
            getOneMenu={getOneMenu}
            setGenerateQrCode={setGenerateQrCode}
            setUpdated={setUpdated}
            brandId={brandId}
            getBrand={getBrand}
          />
        ) : (
          <QrCode menu={menu} />
        )}
      </Card>
    </div>
  );
}
