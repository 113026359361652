import { useEffect, useState } from "react";

export function EditColorRestau({
  index,
  editingColor,
  setEditingColor,
  handleChange,
}) {
  const [color, setColor] = useState(editingColor[index].custom.color);

  const changeColor = async (color) => {
    var tmp = [...editingColor];

    tmp[index] = {
      ...editingColor[index],
      custom: { color: color },
    };
    await handleChange("color", tmp);
    setEditingColor(tmp);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => changeColor(color), 500);
    return () => clearTimeout(timeOutId);
  }, [color]);

  return (
    <div className="flex items-center justify-between rounded-lg bg-yellow bg-opacity-25 py-4 px-4">
      <p className="uppercase">{editingColor[index].title}</p>
      <div className="flex gap-2">
        <div className="relative h-[20px] w-[20px] cursor-pointer overflow-hidden rounded-full">
          <input
            defaultValue={editingColor[index].custom.color}
            onChange={(e) => setColor(e.target.value)}
            type="color"
            className="absolute left-1/2 top-1/2 h-[50px] w-[50px] -translate-y-2/4 -translate-x-2/4 transform cursor-pointer border-0"
          />
        </div>
        <p className="flex h-[20px] w-[120px] items-center rounded-full bg-white pl-3 text-xs uppercase">
          {editingColor[index].custom.color}
        </p>
      </div>
    </div>
  );
}
