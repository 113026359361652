import joinClasses from "helpers/joinClasses";

export function BigCard({ children, className = "", style }) {
  return (
    <div
      style={style}
      className={joinClasses(className, "rounded-xl shadow-2xl md:bg-white")}
    >
      {children}
    </div>
  );
}
