import { BorderedButton, Card, DefaultButton } from "components/atoms";

export function DeletePopUp({
  children,
  button = "Supprimer",
  onValidate = () => {},
  onCancel = () => {},
}) {
  return (
    <div className="fixed top-0 left-0  bottom-0 right-0 z-20 flex h-full w-full items-center justify-center bg-black-opacity">
      <Card className="w-9/12 px-4 md:w-5/12 lg:w-4/12 xl:w-3/12">
        <div className="py-4">
          <div>{children}</div>
          <div className="flex flex-col items-center sm:flex-row md:justify-center lg:justify-start">
            <DefaultButton
              ButtonType="smaller"
              className="mt-6  sm:mr-5 "
              onClick={() => onValidate()}
            >
              {button}
            </DefaultButton>
            <BorderedButton
              borderType="yellow"
              className="mt-6 !py-[7px] !px-7 "
              onClick={() => onCancel()}
            >
              Annuler
            </BorderedButton>
          </div>
        </div>
      </Card>
    </div>
  );
}
