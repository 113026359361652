import joinClasses from "helpers/joinClasses";

export function HorizontalLine({ className = "", ...props }) {
  return (
    <div
      className={joinClasses(
        className,
        "m-auto w-auto rounded-full border border-grey"
      )}
      {...props}
    ></div>
  );
}
