import { deleteMenuSex } from "api/menuSex/menuSex";
import { updateMenuSex } from "api/menuSex/menuSex";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { userSelector } from "store/models/userInfo";
import { Card, Icons, DefaultButton } from "components/atoms";
import { fetchAllmenuSectionV2 } from "api/menuSex/menuSex";
import { HandleCategorie } from "components/atoms";
import { sendMenuSex } from "api/menuSex/menuSex";
import { getBrand } from "store/models/restaurantInfo";

export function CategorieListCard({ selectedBrand, selectedBrandId }) {
  let history = useHistory();
  const dispatch = useDispatch();

  const [menuSex, setMenuSex] = useState(null);
  const [title, setTitle] = useState("");
  const [edit, setEdit] = useState(false);
  const menuId = selectedBrand.Menu[0].id;

  const [loading, setLoading] = useState(false);

  const { user } = useSelector(userSelector);

  async function getMenuSex() {
    setLoading(true);
    setMenuSex(await fetchAllmenuSectionV2(menuId));
    setLoading(false);
  }

  useEffect(() => {
    getMenuSex();
  }, []);

  async function sendMenuSection() {
    return await sendMenuSex(title, user.token, menuId);
  }

  const postMenuSex = async () => {
    {
      if (!title.length) return;
      const newSection = await sendMenuSection();
      history.push(
        `/pages/dashboard/menu/${selectedBrandId}#section-${newSection.menuSection.id}`
      );
      setTitle("");
      getMenuSex();
    }
  };

  const removeMenuSex = async (id) => {
    await deleteMenuSex({ id });
    getMenuSex();
  };

  const changeMenuSex = async (id, data) => {
    await updateMenuSex(id, data);
    getMenuSex();
    if (data.isSelected === true) {
      history.push(`/pages/dashboard/menu/${selectedBrandId}#section-${id}`);
    }
  };

  return (
    <div className=" mx-5 mb-2  flex justify-center md:mx-0  ">
      <Card className=" w-full px-5 sm:max-w-[500px] md:max-w-[850px] md:px-10">
        <div className="mt-5 flex">
          <div className="m-auto flex h-[37px] w-full items-center justify-between border-b border-solid border-black pb-1  md:h-[53px] md:pb-2.5">
            <p className="font-poppins text-base font-medium md:ml-0  md:pb-0  md:text-xl">
              {edit ? "Modifier les catégories" : "Ajouter une catégorie"}
            </p>

            {edit ? (
              <DefaultButton
                ButtonType="smaller"
                className="!font-medium"
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                Retour
              </DefaultButton>
            ) : (
              <button
                className=" relative flex flex-col items-center justify-center  bg-transparent p-0 pb-0 text-sm tracking-normal "
                onClick={() => {
                  setEdit(!edit);
                }}
              >
                <div className="">
                  <Icons
                    name="crayon-1  "
                    height="20"
                    className="text-yellow hover:text-yellow-hover "
                  />
                  <Icons
                    name="crayon-2  "
                    height="20"
                    className="  border-black hover:text-yellow-dark"
                  />
                </div>
                <p className="hidden md:block">éditer</p>
              </button>
            )}
          </div>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-3 md:grid-cols-4 md:gap-5 ">
          {menuSex &&
            menuSex.length > 0 &&
            menuSex.map((row, index) => (
              <HandleCategorie
                edit={edit}
                key={index}
                isGlobal={row.isGlobal}
                isSelected={row.isSelected}
                onClick={() => {
                  edit
                    ? !row.isGlobal && removeMenuSex(row.id)
                    : changeMenuSex(row.id, {
                        isSelected: !row.isSelected,
                      });
                }}
              >
                {row.title}
              </HandleCategorie>
            ))}
        </div>
        <div className="flex-col items-center justify-center text-center md:flex md:flex-row">
          <p className="md:ml-18 mt-4 font-poppins text-xl font-medium md:mt-0 md:mr-11  md:text-2xl  ">
            ou
          </p>
          <div className="m-5 rounded-xl bg-yellow-lightest py-5 md:mt-11 md:mb-11  md:flex md:pt-5 md:pr-6  md:pl-8  ">
            <input
              className=" md:py-15 w-4/5 border border-solid border-black py-3 text-center  text-sm  md:mx-5 md:mt-0  md:mr-5 md:w-[26rem]  md:pl-9 md:text-left"
              placeholder="Catégorie personnalisée"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            ></input>
            <div className="hidden md:block">
              <DefaultButton
                onClick={async (e) => {
                  e.preventDefault();
                  await postMenuSex();
                  await dispatch(getBrand());
                }}
              >
                Ajouter
              </DefaultButton>
            </div>
          </div>
          <div className="md:hidden">
            <DefaultButton
              className="mb-4 md:mb-0"
              onClick={(e) => {
                e.preventDefault();
                postMenuSex();
                dispatch(getBrand());
              }}
            >
              Ajouter
            </DefaultButton>
          </div>
        </div>
      </Card>
      {loading && (
        <div className="fixed top-0 left-0 z-50 flex h-screen w-screen items-center justify-center bg-black bg-opacity-50">
          <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
    </div>
  );
}
