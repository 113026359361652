import joinClasses from "helpers/joinClasses";
import { useEffect, useState } from "react";

export function TimePatron({
  customData,
  children,
  data,
  disabled,
  callBack = () => {},

  isPreview = false,
}) {
  const [json, setJson] = useState({
    0: false, // checkbox
    1: null, // début matin
    2: null, // fin matin
    3: null, // début après midi
    4: null, // fin apres midi
  });
  /*   useEffect(() => {
    callBack(json);
  }, [json]); */
  useEffect(() => {
    if (data) {
      setJson(data);
    }
  }, [data]);

  return (
    <>
      <div className={`flex h-[29px] ${disabled ? "pointer-events-none" : ""}`}>
        {disabled ? (
          <>
            <div className="flex flex-col justify-center">
              <div className="flex w-[100px] items-center gap-1.5">
                <input
                  type="checkbox"
                  onClick={() => setJson({ ...json, ["0"]: !json["0"] })}
                  checked={json["0"]}
                  className={`pointer-events-none"
             } border-grey-disabled bg-transparent
            
            text-grey-disabled`}
                />
                <p className={`text-sm text-grey-disabled`}>{children}</p>
              </div>
            </div>
            <div className="m-auto flex flex-col">
              {json["0"] ? (
                <div className="flex items-center gap-1  ">
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[1]}
                    className={`pointer-events-none h-[29px] w-[49px] border-grey-disabled bg-transparent p-0 text-center text-sm text-grey-disabled`}
                    onChange={(e) =>
                      setJson({ ...json, ["1"]: e.target.value })
                    }
                  />

                  <p className={`text-sm text-grey-disabled`}>-</p>
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[2]}
                    className={` pointer-events-none h-[29px] w-[49px] border-grey-disabled bg-transparent p-0 text-center text-sm text-grey-disabled`}
                    onChange={(e) =>
                      setJson({ ...json, ["2"]: e.target.value })
                    }
                  />
                  <p className={`text-sm  text-grey-disabled `}>,</p>
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[3]}
                    className={` pointer-events-none h-[29px] w-[49px] border-grey-disabled bg-transparent p-0 text-center text-sm text-grey-disabled`}
                    onChange={(e) =>
                      setJson({ ...json, ["3"]: e.target.value })
                    }
                  />
                  <p className={`text-sm  text-grey-disabled`}>-</p>
                  <input
                    type="text"
                    placeholder="--:--"
                    defaultValue={json[4]}
                    className={` pointer-events-none h-[29px] w-[49px] border-grey-disabled bg-transparent p-0 text-center text-sm text-grey-disabled`}
                    onChange={(e) =>
                      setJson({ ...json, ["4"]: e.target.value })
                    }
                  />
                </div>
              ) : (
                <div className="w-[238px] text-sm">
                  {" "}
                  <p className={` italic text-grey-disabled`}>Fermé</p>{" "}
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col justify-center">
              <div className="flex w-[100px] items-center gap-1.5">
                {!isPreview && (
                  <input
                    type="checkbox"
                    onClick={() => setJson({ ...json, ["0"]: !json["0"] })}
                    checked={json["0"]}
                    className={`bg-transparent `}
                    style={{
                      color: customData && customData.color[1].custom.color,
                      borderColor:
                        customData && customData.color[2].custom.color,
                    }}
                  />
                )}

                <p
                  className="text-sm"
                  style={{
                    color: customData && customData.color[2].custom.color,
                  }}
                >
                  {children}
                </p>
              </div>
            </div>
            <div className="m-auto flex flex-col">
              {json["0"] ? (
                <div className="flex items-center gap-1  ">
                  {!isPreview ? (
                    <input
                      type="text"
                      placeholder="--:--"
                      defaultValue={json[1]}
                      className={`h-[29px] w-[49px] bg-transparent p-0 text-center text-sm placeholder-black`}
                      onChange={(e) =>
                        setJson({
                          ...json,
                          ["1"]: e.target.value == "" ? null : e.target.value,
                        })
                      }
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                      onBlur={() => callBack(json)}
                    />
                  ) : (
                    <p
                      className={`flex h-[29px] w-[49px] items-center justify-center bg-transparent p-0 text-sm`}
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[1]}
                    </p>
                  )}

                  <p
                    className="text-sm"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    {!isPreview && "-"}
                    {isPreview && json[1] && json[2] ? "-" : ""}
                  </p>
                  {!isPreview ? (
                    <input
                      type="text"
                      placeholder="--:--"
                      defaultValue={json[2]}
                      className={`h-[29px] w-[49px] bg-transparent p-0 text-center text-sm placeholder-black`}
                      onChange={(e) =>
                        setJson({
                          ...json,
                          ["2"]: e.target.value == "" ? null : e.target.value,
                        })
                      }
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                      onBlur={() => callBack(json)}
                    />
                  ) : (
                    <p
                      className={`flex h-[29px] w-[49px] items-center justify-center bg-transparent p-0 text-sm`}
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[2]}
                    </p>
                  )}
                  <p
                    className="text-sm"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    {!isPreview && ","}
                    {isPreview && (json[1] || (json[2] && json[3]) || json[4])
                      ? ","
                      : ""}
                  </p>
                  {!isPreview ? (
                    <input
                      type="text"
                      placeholder="--:--"
                      defaultValue={json[3]}
                      className={`h-[29px] w-[49px] bg-transparent p-0 text-center text-sm placeholder-black`}
                      onChange={(e) =>
                        setJson({
                          ...json,
                          ["3"]: e.target.value == "" ? null : e.target.value,
                        })
                      }
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                      onBlur={() => callBack(json)}
                    />
                  ) : (
                    <p
                      className={`flex h-[29px] w-[49px] items-center justify-center bg-transparent p-0 text-sm`}
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[3]}
                    </p>
                  )}
                  <p
                    className="text-sm"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    {!isPreview && "-"}
                    {isPreview && json[3] && json[4] ? "-" : ""}
                  </p>
                  {!isPreview ? (
                    <input
                      type="text"
                      placeholder="--:--"
                      defaultValue={json[4]}
                      className={` h-[29px] w-[49px] bg-transparent p-0 text-center text-sm placeholder-black `}
                      onChange={(e) =>
                        setJson({
                          ...json,
                          ["4"]: e.target.value == "" ? null : e.target.value,
                        })
                      }
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                      onBlur={() => callBack(json)}
                    />
                  ) : (
                    <p
                      className={`flex h-[29px] w-[49px] items-center justify-center bg-transparent p-0 text-sm`}
                      style={{
                        borderColor:
                          customData && customData.color[2].custom.color,
                        color: customData && customData.color[2].custom.color,
                        placeholderColor:
                          customData && customData.color[2].custom.color,
                      }}
                    >
                      {json[4]}
                    </p>
                  )}
                </div>
              ) : (
                <div className="w-[238px] text-sm">
                  {" "}
                  <p
                    className="italic"
                    style={{
                      color: customData && customData.color[2].custom.color,
                    }}
                  >
                    Fermé
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
}
