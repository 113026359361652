import { RestoInput } from "components/atoms";
import joinClasses from "helpers/joinClasses";
import { useState } from "react";

export function PasswordCheck({
  setValue,
  value,
  passwordConfirmation = false,
  ...props
}) {
  const [isValid, setIsValid] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;

  const handleChange = (e) => {
    setIsDirty(true);
    if (passwordConfirmation) {
      setIsValid(value.password === e.target.value);
      setValue({
        ...value,
        confirmedPassword: e.target.value,
      });
    } else {
      setIsValid(e.target.value.length >= 8);
      setValue({
        ...value,
        password: e.target.value,
        passwordCheck: e.target.value.length >= 8,
      });
    }
  };

  return (
    <div className="w-full">
      <div className="  justify-content m-auto mb-7 w-full max-w-[398px]  text-sm">
        <input
          {...props}
          type="password"
          style={{
            borderBottom: !isDirty
              ? "1px solid grey"
              : `2px solid ${isValid ? "#1BB764" : "#bd1a1a"}`,
          }}
          onChange={handleChange}
          required
          className="active-none w-full border-none pb-1 pl-1 font-poppins text-sm placeholder-grey-medium !outline-none placeholder:normal-case focus:outline-none"
        />
        {!passwordConfirmation && (
          <span className="text-xs italic text-grey-dark">
            Minimum huit caractères
          </span>
        )}
      </div>
    </div>
  );
}
