import joinClasses from "helpers/joinClasses";

export function Paragraph({ children, className = "", TextSize }) {
  if (TextSize === "smaller") {
    return (
      <h1
        className={joinClasses(
          className,
          "font-poppins  text-sm font-normal text-black"
        )}
      >
        {children}
      </h1>
    );
  }
  return (
    <p
      className={joinClasses(
        className,
        "font-poppins text-xl font-light text-black"
      )}
    >
      {children}
    </p>
  );
}
