import { CategorieListCard } from "components";

export function EditCard({ selectedBrand, selectedBrandId }) {
  return (
    <div>
      <div>
        <div className="mt-7 flex justify-center"></div>
        {selectedBrand && selectedBrandId && (
          <CategorieListCard
            selectedBrand={selectedBrand}
            selectedBrandId={selectedBrandId}
          />
        )}
      </div>
    </div>
  );
}
