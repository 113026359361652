import joinClasses from "helpers/joinClasses";

export function EmptyRestoCard({ children, className = "", style }) {
  return (
    <div
      style={style}
      className={joinClasses(
        className,
        "rounded-xl border border-solid border-grey-dark "
      )}
    >
      {children}
    </div>
  );
}
