import { Card, DefaultButton } from "components/atoms";

export function PopUp({ children }) {
  return (
    <div className="fixed top-0 left-0  bottom-0 right-0 z-20 flex h-full w-full items-center justify-center bg-black-opacity">
      <Card className="flex max-w-[450px] px-4">
        <div className="py-4">{children}</div>
      </Card>
    </div>
  );
}
