import { Paragraph, SubTitle, ThinHorizontalLine } from "components/atoms";
import { CreatedBrandCard } from "components/molecules/Cards/CreatedBrandCard";

export default function Restaurant({ brand }) {
  return (
    <div className="flex flex-col items-center gap-2 px-2">
      <div className="w-4/6 min-w-max text-center  md:border-b">
        <Paragraph className="!md:text-base !md:font-regular !pb-1 !text-sm !font-medium md:pb-0   ">
          Informations sur le(s) restaurant(s)
        </Paragraph>
        <ThinHorizontalLine className="md:hidden" />
      </div>
      <div className="w-10/12 md:flex md:w-full md:gap-5 md:overflow-auto md:px-5 md:py-5">
        {brand.map((item, index) => (
          <CreatedBrandCard
            key={index}
            brandId={item.id}
            name={item.name}
            city={item.city}
            logo={item.avatar}
            status={item.status}
          />
        ))}
      </div>
    </div>
  );
}
