import { useState, useEffect } from "react";

// COMPONENTS
import { DefaultButton, HorizontalLine, Icons } from "components/atoms";
//
import { useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import {
  deleteDrinkCategory,
  updateDrinkCategory,
} from "api/drinkCategory/drinkCategory";
import { Drink } from "components";
import { createDrink } from "api/drink/drink";
import { positionDown, positionUp } from "api/position";
import { findFont } from "helpers/findDataCustom";
import { focusInput } from "../../../helpers/focusInput";

export function DrinkCreationPatron({
  drinkCategory,
  drinkCategoryId,
  menuSectionName,
  loadData = () => {},
  custom,
  setLoading,
  index,
  length,
  volumes,
}) {
  const { user } = useSelector(userSelector);

  const changeDrinkCategory = async (data) => {
    await updateDrinkCategory(drinkCategoryId, data);
  };

  const sendDrink = async () => {
    const { data } = await createDrink(user.token, drinkCategoryId);
    await loadData();
    focusInput("drink", data.drink.id);
  };

  const removeDrinkCategory = async () => {
    await deleteDrinkCategory({ id: drinkCategoryId, token: user.token });
    loadData();
  };

  const changePosition = async (section, action) => {
    setLoading(true);
    if (action === "up") {
      await positionDown(section.id, "drinkcategory");
    }

    if (action === "down") {
      await positionUp(section.id, "drinkcategory");
    }
    loadData();
    setLoading(false);
  };

  return (
    <div key={drinkCategoryId} id={`drink-category-${drinkCategoryId}`}>
      <div className="relative md:mb-8 ">
        <div className="mt-8 ">
          <div className=" flex flex-col md:flex-row ">
            <div className="flex w-full basis-3/5 flex-row  ">
              <div className="flex h-[80px]  w-full flex-col">
                <div className="relative flex h-full w-full max-w-[624px] flex-col-reverse items-end md:flex-row md:justify-between">
                  <input
                    className={` h-7/12 my-1 w-full  truncate rounded border border-solid border-grey-disabled bg-transparent py-5 pl-4 text-sm placeholder-grey-disabled sm:text-base md:w-4/5 md:py-2.5 lg:max-w-[600px]
                    ${custom && findFont(custom.police[2].custom.fontFamily)}
                    ${custom && custom.police[2].custom.bold && "font-bold"}
                    ${custom && custom.police[2].custom.italic && "italic"}`}
                    placeholder="Nom de la sous-categorie"
                    defaultValue={drinkCategory.name}
                    onBlur={(e) =>
                      changeDrinkCategory({ name: e.target.value.trim() })
                    }
                    name={`drink-category-${drinkCategoryId}`}
                    style={{
                      fontSize: custom && custom.police[2].custom.fontSize,
                      letterSpacing:
                        custom && custom.police[2].custom.letterSpacing,
                      color: custom && custom.color[2].custom.color,
                    }}
                  ></input>
                  <div className=" items-col mb-2 flex min-h-[40px] flex-row items-end justify-center gap-5 md:items-center md:gap-0 ">
                    <div className="flex flex-col items-center">
                      {length !== 1 && (
                        <>
                          {index != 0 && (
                            <Icons
                              name="arrow-up"
                              height="20"
                              className="hover:opacity-50 active:opacity-100 md:mr-5"
                              onClick={() =>
                                changePosition(drinkCategory, "up")
                              }
                              style={{
                                color: custom && custom.color[1].custom.color,
                              }}
                            />
                          )}
                          {index + 1 != length && (
                            <Icons
                              name="arrow-down"
                              height="20"
                              className="hover:opacity-50 active:opacity-100 md:mr-5"
                              onClick={() =>
                                changePosition(drinkCategory, "down")
                              }
                              style={{
                                color: custom && custom.color[1].custom.color,
                              }}
                            />
                          )}
                        </>
                      )}
                    </div>
                    <Icons
                      name="trash"
                      height="20"
                      onClick={() => {
                        removeDrinkCategory();
                      }}
                      className="md:px-0"
                      style={{ color: custom && custom.color[1].custom.color }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <p
              style={{ color: custom && custom.color[2].custom.color }}
              className="mb-4 block text-center  text-xs text-grey-disabled md:mb-0 md:hidden md:pl-2 md:text-left"
            >
              (ex: vins rouges, vins blancs, vins rosés,...)
            </p>
            <div className="flex basis-2/5 items-end   justify-between  md:items-start  md:justify-end ">
              {volumes &&
                Object.keys(volumes).map(
                  (key, index) =>
                    volumes[key] && (
                      <div
                        key={index}
                        className="pointer-events-none flex h-20  w-1/5 flex-col items-center  justify-end gap-2 md:w-[68px] md:items-center lg:w-20"
                        style={{
                          color: custom && custom.color[2].custom.color,
                        }}
                      >
                        <Icons
                          name={`drink${key}`}
                          height={
                            {
                              "15cl": "28",
                              "25cl": "32",
                              "37,5cl": "48",
                              "50cl": "40",
                              "75cl": "64",
                            }[key]
                          }
                          className={
                            {
                              "15cl": "w-3",
                              "25cl": "w-4",
                              "37,5cl": "w-3",
                              "50cl": "w-10",
                              "75cl": "w-4",
                            }[key]
                          }
                          style={{
                            color: custom && custom.color[1].custom.color,
                          }}
                        />
                        <p
                          className="ml-4 mr-[15px] text-xs md:mx-0 md:text-sm"
                          style={{
                            color: custom && custom.color[1].custom.color,
                          }}
                        >
                          {key}
                        </p>
                      </div>
                    )
                )}
            </div>
          </div>
          <p
            style={{ color: custom && custom.color[2].custom.color }}
            className="mb-4 hidden text-center  text-xs text-grey-disabled md:mb-0 md:block md:pl-2 md:text-left  "
          >
            (ex: vins rouges, vins blancs, vins rosés,...)
          </p>
        </div>

        {drinkCategory.Drink.map((drink, index) => (
          <Drink
            key={index}
            drink={drink}
            drinkId={drink.id}
            volumes={volumes}
            custom={custom}
            loadData={loadData}
          />
        ))}
        <DefaultButton
          onClick={() => {
            sendDrink();
          }}
          className=" m-auto mt-8 md:mt-12"
        >
          Ajouter un{" "}
          {menuSectionName === "Vins"
            ? menuSectionName
                .toLowerCase()
                .substring(0, menuSectionName.length - 1)
            : menuSectionName.toLowerCase()}
        </DefaultButton>
      </div>
    </div>
  );
}
