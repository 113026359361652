import { useState, useEffect } from "react";
// * Composants * //
import { YellowTemplate } from "components";
import { BrandStepsNavbar } from "components";
import { EditCard } from "components/organisms/dashboard/restaurant/EditCard";
// * REDUX * //
import { useSelector } from "react-redux";
// * REQUETES * //
import { userSelector } from "store/models/userInfo";
import { fetchSelectedBrand } from "api/brand/brand";

export default function OneRestaurantCategoriesPage(props) {
  const { user } = useSelector(userSelector);
  const brandId = props.match.params.id;
  const [brand, setBrand] = useState(null);

  async function getOneBrand() {
    setBrand(await fetchSelectedBrand({ token: user.token, id: brandId }));
  }

  useEffect(() => {
    getOneBrand();
  }, []);

  return (
    <YellowTemplate>
      {brandId && brand && (
        <>
          <BrandStepsNavbar etape={2} selectedBrand={brand} />
          <EditCard selectedBrand={brand} selectedBrandId={brandId} />
        </>
      )}
    </YellowTemplate>
  );
}
