// COMPONENTS
import {
  BorderedButton,
  EmptyRestoCard,
  Icons,
  LogoPicker,
} from "components/atoms";
import { CardInput } from "components";

//
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { sendBrand } from "api/brand/brand";
import { sendBrandLogo } from "api/brand/brand";
import { getBrand } from "store/models/restaurantInfo";
import { useDispatch, useSelector } from "react-redux";
import { userSelector } from "store/models/userInfo";
import { setBrand } from "store/models/restaurantInfo";
import { createMenu } from "api/menu/menu";
import { CreatedBrandCard } from "./CreatedBrandCard";

export function CreateOneBrandCard({ restaurantList = [] }) {
  const { user } = useSelector(userSelector);
  const dispatch = useDispatch();
  const history = useHistory();

  const [logo, setLogo] = useState(null);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);

  const [reset, setReset] = useState(false);
  const [brandInfo, setBrandInfo] = useState([]);

  const postBrand = async () => {
    if (loading) return;
    setLoading(true);
    const newRestaurant = await sendBrand(user.id, name, city);
    if (logo) await sendBrandLogo(newRestaurant.id, user.id, logo);
    setBrandInfo(newRestaurant);
    dispatch(setBrand(brandInfo));
    setLoading(false);
    setName("");
    setCity("");
    setLogo(null);
    history.push(`/pages/dashboard/${newRestaurant.id}`);
  };

  const postBrandLogo = async (brandId) => {};

  // async function createMenuForBrand(id) {
  //   await createMenu(id);
  // }

  useEffect(() => {
    dispatch(getBrand());
  }, []);

  useEffect(() => {
    if (brandInfo && brandInfo.brand && brandInfo.brand.id) {
      // postBrandLogo(brandInfo.brand.id);
      // createMenuForBrand(brandInfo.brand.id);
      dispatch(getBrand());
      setReset(!reset);
    }
  }, [brandInfo]);

  return (
    <div>
      <div className="md:flex md:flex-wrap md:items-center md:justify-start  md:gap-3.5">
        {/* BRAND FORM RESPONSIVE --> */}
        <EmptyRestoCard className="md:hidden md:w-[226px]">
          <div className="m-auto flex h-auto flex-col items-center">
            <button
              className="font-xs rounded-full bg-transparent py-4 px-10 text-sm font-light "
              onClick={(e) => {
                e.preventDefault;
                postBrand(user.token);
              }}
            >
              {loading ? (
                "Chargement..."
              ) : (
                <div className="flex items-center">
                  <Icons
                    name="add"
                    className=" mr-2.5 rounded-full border border-solid border-black p-2"
                  />
                  <p>Ajouter un restaurant</p>
                </div>
              )}
            </button>
          </div>
        </EmptyRestoCard>
        {/* END BRAND FORM RESPONSIVE --> */}

        {/* BRAND FORM DESKTOP --> */}
        <div className="h-full">
          <EmptyRestoCard className=" mt-2 hidden flex-col items-center justify-between md:mt-0 md:flex md:h-[320px] md:w-[226px]">
            <div className="flex w-20 flex-col items-center">
              <LogoPicker
                mt={false}
                createH="20"
                wh={20}
                setLogo={setLogo}
                reset={reset}
                lib={"Ajouter mon logo (facultatif)"}
                className="!mb-5 !mt-7  !max-h-[80px] !max-w-[80px] !border-none !pt-5 !text-xs"
              />
            </div>
            <div>
              <div className="w-[140px]	">
                <div className="flex justify-center">
                  <CardInput
                    onChange={setName}
                    value={name}
                    placeholder={"Nom du restaurant"}
                    className=" text-sm "
                  />
                </div>
              </div>
              <div className="mt-[18px] w-[140px] ">
                <div className="flex justify-center ">
                  <CardInput
                    onChange={setCity}
                    value={city}
                    placeholder={"Ville"}
                    className=" text-sm "
                  />
                </div>
              </div>
            </div>
            <BorderedButton
              className="mb-5 "
              onClick={(e) => {
                e.preventDefault();
                postBrand();
              }}
            >
              {loading ? "Chargement..." : "Ajouter"}
            </BorderedButton>
          </EmptyRestoCard>
        </div>
        {/* END BRAND FORM DESKTOP --> */}

        {/* EXISTING BRAND LIST --> */}
        {restaurantList.map((row, index) => (
          <CreatedBrandCard
            key={index}
            brandId={row.id}
            logo={row.avatar}
            name={row.name}
            position={row.positionId}
            city={row.city}
            status={row.status}
          />
        ))}
      </div>
    </div>
  );
}
