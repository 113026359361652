import getFormatedToken from "api/helpers/getFormatedToken";
import axios from "axios";

export async function fetchAllmenuSex() {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/menusection`,
    getFormatedToken()
  );

  return response.data.data.menuSections;
}

export async function fetchAllmenuSectionV2(id) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/menusection/menu/${id}`,
    getFormatedToken()
  );
  return response.data.data.sections;
}

export async function sendMenuSex(title, token, menuId) {
  try {
    let menuSexData = { menuId: menuId, title: title };
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/menusection`,
      menuSexData,
      getFormatedToken()
    );
    const { data } = response.data;

    return data;
  } catch (error) {}
}

export async function sendMenuSexLogo({ id, userId, logo }) {
  try {
    if (id && logo) {
      const formData = new FormData();
      formData.append("avatar", logo);
      formData.append("userId", userId);
      const contentType = true;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/menusection/${id}/avatar`,
        formData,
        getFormatedToken(contentType)
      );
      const { data } = response;
      return data;
    } else {
      return false;
    }
  } catch (error) {}
}

export async function deleteMenuSex({ id }) {
  if (!id) {
    return;
  }

  try {
    await axios.delete(
      `${process.env.REACT_APP_API_URL}/menusection/${id}`,
      getFormatedToken()
    );
  } catch (error) {}
}

export async function updateMenuSex(id, payload) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/menusection/${id}`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;
    return data;
  } catch (error) {}
}
