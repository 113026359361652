import { useEffect, useState } from "react";
import fontsList from "utils/fontsList";
import FontPicker from "font-picker-react";
import { Icons, Selector } from "../..";

export function EditFontRestau({
  index,
  editingFont,
  setEditingFont,
  fonts,
  handleChange = () => {},
}) {
  const [laValueFontSize, setLaValueFontSize] = useState(
    editingFont[index].custom.fontSize
  );
  const [laValueLetterSpacing, setLaValueLetterSpacing] = useState(
    editingFont[index].custom.letterSpacing
  );

  useEffect(() => {
    changeFontSize(laValueFontSize);
  }, [laValueFontSize]);
  useEffect(() => {
    changeletterSpacing(laValueLetterSpacing);
  }, [laValueLetterSpacing]);

  const changeFont = async (e) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: { ...editingFont[index].custom, fontFamily: e.target.value },
    };

    await handleChange("police", tmp);
    setEditingFont(tmp);
  };

  const changeFontSize = async (valeur) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        fontSize: Number(valeur),
      },
    };

    await handleChange("police", tmp);
    setEditingFont(tmp);
  };

  const changeletterSpacing = async (valeur) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        letterSpacing: Number(valeur),
      },
    };

    await handleChange("police", tmp);
    setEditingFont(tmp);
  };

  const changeBold = async () => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        bold: !editingFont[index].custom.bold,
      },
    };

    await handleChange("police", tmp);
    setEditingFont(tmp);
  };

  const changeItalic = async (e) => {
    var tmp = [...editingFont];

    tmp[index] = {
      ...editingFont[index],
      custom: {
        ...editingFont[index].custom,
        italic: !editingFont[index].custom.italic,
      },
    };

    await handleChange("police", tmp);
    setEditingFont(tmp);
  };

  return (
    <div className="flex items-center justify-between rounded-lg bg-yellow bg-opacity-25 py-2 px-4">
      <p className="uppercase">{editingFont[index].title}</p>
      <div className="flex flex-col gap-2">
        {/* <select
          onChange={changeFont}
          className="border-0 rounded-full py-1.5 text-xs capitalize w-11/12"
        >
          {fontsList.map((font, indexdemerde) => (
            <option
              key={indexdemerde}
              value={font.name}
              selected={editingFont[index].custom.fontFamily == font.name}
              className={`font-${font.name}`}
            >
              {font.name}
            </option>
          ))}
        </select> */}
        <Selector
          defaultValue={editingFont[index].custom.fontFamily}
          onChange={changeFont}
          list={fonts}
          limit={50}
          key={index}
        />
        <div className="flex gap-8">
          <div className="relative flex">
            <input
              type="text"
              onChange={(e) => {
                setLaValueFontSize(Number(e.target.value));
              }}
              value={laValueFontSize}
              className="w-auto max-w-[40px] border-0 border-b bg-transparent px-1 py-0 text-xs capitalize "
              min="1"
            />
            <Icons
              name="arrow-up"
              onClick={() => setLaValueFontSize(laValueFontSize + 1)}
              className="absolute top-1 right-0 h-2 w-3 text-center hover:bg-grey"
            ></Icons>
            <Icons
              name="arrow-down"
              onClick={() => setLaValueFontSize(laValueFontSize - 1)}
              className="absolute bottom-1 right-0 h-2 w-3 text-center hover:bg-grey"
            ></Icons>
          </div>
          <div className="flex gap-4">
            <div className="relative flex items-center">
              <Icons name="letter-spacing" height="10" />
              <input
                type="text"
                onChange={(e) => {
                  setLaValueLetterSpacing(Number(e.target.value));
                }}
                value={laValueLetterSpacing}
                className="h-full w-auto max-w-[40px] border-0 border-b bg-transparent px-1 py-0 text-xs capitalize"
              />
              <Icons
                name="arrow-up"
                onClick={() =>
                  setLaValueLetterSpacing(laValueLetterSpacing + 1)
                }
                className="absolute top-1 right-0 h-2 w-3 text-center hover:bg-grey"
              ></Icons>
              <Icons
                name="arrow-down"
                onClick={() =>
                  setLaValueLetterSpacing(laValueLetterSpacing - 1)
                }
                className="absolute bottom-1 right-0 h-2 w-3 text-center hover:bg-grey"
              ></Icons>
            </div>
            <button
              className={`flex h-[25px] w-[25px] items-center justify-center ${
                editingFont[index].custom.bold ? "bg-white" : "bg-transparent"
              } rounded-full `}
              onClick={changeBold}
            >
              <Icons name="bold" height="10" />
            </button>
            <button
              className={`flex h-[25px] w-[25px] items-center justify-center ${
                editingFont[index].custom.italic ? "bg-white" : "bg-transparent"
              } rounded-full `}
              onClick={changeItalic}
            >
              <Icons name="italic" height="10" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
