import { useState } from "react";
import { Container } from "components";
import { PasswordCheck } from "./InputPasswordCheck";
import { DefaultButton } from "components/atoms";
import joinClasses from "helpers/joinClasses";

export default function PasswordStep({
  show = false,
  setStep = () => {},
  form = {},
  setForm = () => {},
}) {
  const [errorMessage, setErrorMessage] = useState(null);

  const sendForm = async (e) => {
    e.preventDefault();
    if (!form.CGV) setErrorMessage("Veuillez accepter les CGV.");
    if (
      form.password &&
      form.password.length &&
      form.confirmedPassword &&
      form.confirmedPassword.length
    ) {
      if (!form.passwordCheck)
        return setErrorMessage(
          "Le mot de passe doit comporter au minimum huit caractères, au moins une lettre et un chiffre"
        );
      if (form.password === form.confirmedPassword) {
        setErrorMessage(null);
        setStep(3);
      } else {
        setErrorMessage("Les mots de passe ne correspondent pas.");
      }
    } else {
      setErrorMessage("Veuillez renseigner votre mot de passe.");
    }
  };

  return (
    <div
      className={joinClasses(
        show
          ? "animate__animated animate__faster animate__fadeInRight"
          : "hidden"
      )}
    >
      <Container
        title={"Commencez votre essai Bonappli gratuit"}
        className="!max-w-[600px] p-8 pb-[25px]"
      >
        <p className="mb-10 text-center">
          Choisissez à présent un mot de passe
        </p>
        <form
          className="m-auto flex max-w-[398px] flex-col  items-center"
          onSubmit={sendForm}
        >
          <PasswordCheck
            name="password"
            value={form}
            setValue={setForm}
            placeholder="Créer un mot de passe"
          />
          <PasswordCheck
            name="confirmPassword"
            value={form}
            setValue={setForm}
            placeholder="Confirmez votre mot de passe"
            passwordConfirmation
          />

          <div className="mt-6 flex justify-between  gap-8 ">
            <input
              className="form-checkbox mt-[2px] text-xl text-black"
              type="checkbox"
              id="CGV"
              name="CGV"
              onChange={(e) => setForm({ ...form, CGV: e.target.checked })}
              required
            />

            <div className="flex text-justify ">
              <label
                style={{ color: "black" }}
                className="text-smallest font-light sm:text-xs"
                htmlFor="CGV"
              >
                J'accepte les Conditions et la Politique de confidentialité de
                Bonappli. En continuant, vous acceptez les conditions de
                l'Accord de l'utilisateur et de la Politique de confidentialité.
              </label>
            </div>
          </div>
          {errorMessage && (
            <p className="px-4 py-1 text-sm text-red animate__animated animate__faster animate__fadeIn">
              {errorMessage}
            </p>
          )}
          <DefaultButton className="mt-10">Valider</DefaultButton>
        </form>
      </Container>
    </div>
  );
}
