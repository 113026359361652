import { findFont } from "helpers/findDataCustom";
import joinClasses from "helpers/joinClasses";

export function PricesPreview({ custom, table = false, children, className }) {
  if (table) {
    return (
      <td
        className={
          (joinClasses(
            className,
            findFont(custom.police[4].custom.fontFamily),
            custom.police[4].custom.bold ? "font-bold" : "!font-normal",
            custom.police[4].custom.italic ? "italic" : ""
          ),
          "w-[70px] text-center ")
        }
        style={{
          fontSize: `${custom.police[4].custom.fontSize}px`,
          letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
          color: custom.color[2].custom.color,
        }}
      >
        {children}
      </td>
    );
  }
  return (
    <div
      className={
        (joinClasses(
          className,
          findFont(custom.police[4].custom.fontFamily),
          custom.police[4].custom.bold ? "font-bold" : "!font-normal",
          custom.police[4].custom.italic ? "italic" : ""
        ),
        " text-center ")
      }
      style={{
        fontSize: `${custom.police[4].custom.fontSize}px`,
        letterSpacing: `${custom.police[4].custom.letterSpacing}px`,
        color: custom.color[2].custom.color,
      }}
    >
      {children}
    </div>
  );
}
