import { Icons } from "components/atoms";
import { Link } from "react-router-dom";

export function BasicFooter({ backgroundColor, className }) {
  if (backgroundColor === "yellow") {
    return (
      <nav className="bottom-0 flex border-t border-solid border-black bg-yellow-lightest  py-6 md:w-full ">
        <div className="flex md:ml-52 md:items-center">
          <img src="/assets/brand/logo.svg" alt="" className=" " />
          <a className="font-regular mx-14 font-poppins text-sm underline">
            Mentions légales
          </a>
          <a
            className="font-regular font-poppins text-sm underline"
            href="mailto:nathan@agencerjs.com?subject=Remontée de bug Bonappli.fr&body=Pouvez-vous nous décrire précisément le bug rencontré.%0D%0AVous pouvez joindre des captures d’écrans, cela peut nous aider :)%0D%0A%0D%0ADESCRIPTION DU BUG RENCONTRÉ :%0D%0A"
          >
            Nous contacter
          </a>
        </div>
      </nav>
    );
  } else {
    return (
      <nav className="bottom-0 w-full border-t border-solid border-black bg-white  py-4  md:py-6  ">
        <div className="m-auto flex md:w-2/3 md:justify-between">
          <div className="ml-11 flex w-2/3 flex-col md:ml-0 md:flex md:w-auto md:flex-row md:items-center ">
            <div className="mb-5 flex items-center md:mb-0">
              <img src="/assets/brand/logo.svg" alt="" className="h-3 md:h-5" />
              <span className="ml-3 font-poppins text-xs text-grey-medium">
                v{process.env.REACT_APP_VERSION}
              </span>
            </div>
            <div className="flex md:mx-14 ">
              <Link
                className="mr-2.5 font-poppins text-smallest font-medium underline md:mr-14 md:text-sm "
                to={"/mentions-legales"}
              >
                Mentions légales
              </Link>
              <Link
                className="mr-2.5 font-poppins text-smallest font-medium underline md:mr-14 md:text-sm "
                to={"/CGU"}
              >
                CGU
              </Link>
              <a
                className="font-poppins text-smallest font-medium underline md:text-sm"
                href="mailto:nathan@agencerjs.com?subject=Remontée de bug Bonappli.fr&body=Pouvez-vous nous décrire précisément le bug rencontré.%0D%0AVous pouvez joindre des captures d’écrans, cela peut nous aider :)%0D%0A%0D%0ADESCRIPTION DU BUG RENCONTRÉ :%0D%0A"
              >
                Nous contacter
              </a>
            </div>
          </div>
          <div className="flex items-end md:items-center ">
            <a href="#">
              <Icons
                name="facebook"
                className=" mr-3 md:mr-10"
                height="md:20"
              />
            </a>

            <a href="#">
              <Icons name="instagram" height="md:20" />
            </a>
          </div>
        </div>
      </nav>
    );
  }
}
