import { FormulePreviewCard } from "components";

export function FormulesPreview({ formules, custom }) {
  return (
    <div className="mt-18 m-auto flex w-full max-w-6xl flex-col justify-center gap-2">
      {formules
        .sort((a, b) => (a.positionId > b.positionId ? 1 : -1))
        .map((formule, index) => (
          <FormulePreviewCard formule={formule} key={index} custom={custom} />
        ))}
    </div>
  );
}
