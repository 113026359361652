import Button from "./Button";
import joinClasses from "helpers/joinClasses";

export function BorderedButton({
  children,
  borderType,
  className = "",
  onClick,
  ...props
}) {
  if (borderType === "yellow") {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(
          className,
          "block border border-yellow bg-transparent py-[9px] px-[30px]  font-poppins text-xs font-medium hover:bg-yellow hover:duration-300  md:text-sm  "
        )}
        {...props}
      >
        {children}
      </Button>
    );
  }

  return (
    <Button
      onClick={onClick}
      className={joinClasses(
        className,
        "border border-grey-dark  bg-transparent py-[14px] px-10 font-poppins  text-xs hover:border-yellow-lightest hover:bg-black hover:text-white hover:duration-300 md:text-sm "
      )}
      {...props}
    >
      {children}
    </Button>
  );
}
