import React, { useEffect } from "react";
import DefaultRouter from "routers/default";
import AuthenticationRouter from "routers/authentication";
import { useSelector, useDispatch } from "react-redux";
import { validateUser, userSelector } from "store/models/userInfo";
import LoadingRouter from "routers/_loading";

function App() {
  const dispatch = useDispatch();
  const { header } = useSelector(userSelector);

  useEffect(() => {
    dispatch(validateUser());
  }, [dispatch]);

  return (
    <React.Fragment>
      {header.status === ("pending" || "nothing") ? (
        <LoadingRouter />
      ) : header.connected ? (
        <DefaultRouter />
      ) : (
        <AuthenticationRouter />
      )}
    </React.Fragment>
  );
}

export default App;
