import joinClasses from "helpers/joinClasses";

export function RestoInput({
  children,
  name,
  placeholder,
  className = "",
  onClick,
  onBlur,
  type,
  inputType,
  borderColor,
  defaultValue,
  ...props
}) {
  if (inputType === "register") {
    return (
      <div className="  m-auto flex h-[60px] w-full sm:w-1/2 md:text-sm ">
        <input
          type={type}
          {...props}
          name={name}
          placeholder={placeholder}
          onBlur={onBlur}
          defaultValue={defaultValue}
          className={joinClasses(
            className,
            "active-none m-auto w-5/6 border-none pb-1 pl-1 font-poppins placeholder-grey-medium focus:outline-none sm:w-4/5 md:text-sm"
          )}
          style={{
            border: "none",
            borderBottom: `1px solid ${props.borderColor || "#B3B3B3"}`,
          }}
        />
      </div>
    );
  }
  return (
    <div className="  justify-content m-auto mb-7 w-full max-w-[398px]  md:text-sm">
      <input
        type={type}
        {...props}
        name={name}
        placeholder={placeholder}
        onBlur={onBlur}
        defaultValue={defaultValue}
        className={joinClasses(
          className,
          "active-none w-full border-none pb-1 pl-1 font-poppins placeholder-grey-medium placeholder:normal-case focus:outline-none md:text-sm"
        )}
        style={{
          border: "none",
          borderBottom: `1px solid ${props.borderColor || "#B3B3B3"}`,
        }}
      />
    </div>
  );
}
