const fontsList = [
  {
    family: "gloss and bloom",
    category: "handwriting",
    files: {
      regular: "/assets/fonts/Gloss_And_Bloom.ttf",
    },
  },
  {
    family: "melta",
    category: "sans-serif",
    files: {
      regular: "/assets/fonts/Telma-Regular.otf",
    },
  },
  {
    family: "helvetica",
    category: "sans-serif",
    files: {
      regular: "/assets/fonts/helvetica.ttf",
    },
  },
];

export default fontsList;
