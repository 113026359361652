import joinClasses from "helpers/joinClasses";

export function Title({ children, className = "", TextSize, ...props }) {
  if (TextSize === "smaller") {
    return (
      <h1
        className={joinClasses(className, "font-poppins  text-xl font-medium")}
        {...props}
      >
        {children}
      </h1>
    );
  }
  return (
    <h1
      className={joinClasses(
        className,
        "font-poppins  text-[40px] font-semibold"
      )}
      {...props}
    >
      {children}
    </h1>
  );
}
