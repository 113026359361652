import { fetchSelectedBrand } from "api/brand/brand";
import { useState, useEffect } from "react";
import {
  CustomMenuCard,
  BrandInfoPratiquePreview,
  YellowTemplate,
} from "components";
import { MenuPreview } from "components";
import { useDispatch, useSelector } from "react-redux";
import { customSelector, fetchAllCustom } from "store/models/customInfo";
import { findDataCustom, findFont } from "helpers/findDataCustom";
import { EditColorRestau } from "components/atoms";
import { userSelector } from "store/models/userInfo";

import { Icons, ShowMenuSideButton } from "components/atoms";
import "../components/molecules/Navbars/animation.css";

import { useHistory, useLocation } from "react-router";
import {
  getPositionInPercent,
  getPositionWithPercent,
} from "helpers/scrollConvert";
import editColor from "utils/editColor";
import editDisplay from "utils/editDisplay";
import editFont from "utils/editFont";

export function PreviewMenu(props) {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [isCustomOpen, setIsCustomOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [theBrandId, setTheBrandId] = useState(null);
  const [isOpen, setIsOpen] = useState("basic");

  const history = useHistory();
  const location = useLocation();

  const custom = useSelector(customSelector);
  const [customData, setCustomData] = useState({
    color: editColor,
    display: editDisplay,
    police: editFont,
  });
  useEffect(() => {
    if (custom) {
      const findCustom = findDataCustom(
        custom.customs,
        Number(props.match.params.id)
      );
      if (findCustom && findCustom.id) setCustomData(findCustom);
    }
  }, [custom]);

  const getBrand = async (id) => {
    const brand = await fetchSelectedBrand({ id });
    setSelectedBrand(brand);
    const customSearch = findDataCustom(
      brand.Custom,
      Number(props.match.params.id)
    );
    if (customSearch) setCustomData(customSearch);
    setTheBrandId(id);
  };

  useEffect(() => {
    if (selectedBrand && location && location.state && location.state.section) {
      const section = document.getElementById(location.state.section);

      if (section) {
        window.scrollTo(0, section.offsetTop - window.innerHeight / 2);
      }
    }
  }, [selectedBrand]);

  useEffect(() => {
    if (props && props.match && props.match.params && props.match.params.id) {
      const brandId = props.match.params.id;
      getBrand(brandId);
    }
  }, [props.match.params.id]);

  useEffect(() => {}, [isCustomOpen]);
  useEffect(() => {}, [isInfoOpen]);

  function displayInfoMenu() {
    setIsInfoOpen(true);
  }

  function displayCustomMenu() {
    setIsCustomOpen(true);
  }

  function toOpen() {
    if (isOpen === "basic") {
      setIsOpen(true);
    } else {
      setIsOpen(!isOpen);
    }
  }

  const navItems = [
    {
      id: 1,
      icon: "brush-fill",
      onClick: () => displayCustomMenu(),
      className: " rounded-md flex items-center justify-center",
    },
    {
      id: 2,
      icon: "add",
      onClick: () => changeToEditPage(),
      className: " rounded-md flex items-center justify-center",
      iconClassName: "transform -rotate-45",
    },
    {
      id: 3,
      icon: "info",
      onClick: () => displayInfoMenu(),
      className: " rounded-md flex items-center justify-center",
    },
  ];

  function changeToEditPage() {
    history.push({
      pathname: `/pages/dashboard/menu/${props.match.params.id}`,
      state: {
        scroll: getPositionInPercent(),
        savedScroll: (location.state && location.state.section) || null,
        section: (location.state && location.state.section) || null,
        isOpenSideMenu: isOpen,
      },
    });
  }

  useEffect(() => {
    if (location.state && location.state.isOpenSideMenu) {
      setIsOpen(location.state.isOpenSideMenu);
    }
  }, [location]);

  return (
    <YellowTemplate
      style={{
        backgroundColor: customData.color[0].custom.color,
      }}
    >
      <ShowMenuSideButton
        className="z-50 flex items-center justify-center  lg:hidden"
        onClick={() => toOpen()}
        stateOpen={isOpen}
        style={{
          backgroundColor: customData.color[1].custom.color,
        }}
      >
        <Icons
          name="add"
          height={25}
          className={` ${
            isOpen === "basic"
              ? ""
              : isOpen === true
              ? "rotate-45 duration-300"
              : "-rotate-0 duration-300"
          }`}
          style={{
            color: customData.color[0].custom.color,
          }}
        />
      </ShowMenuSideButton>

      <div className="my-16 w-full">
        <div
          className={`fixed right-5 bottom-5 z-30 flex  w-[40px]  items-end justify-center lg:hidden ${
            isOpen === "basic"
              ? "   "
              : isOpen === true
              ? "containerHeightUp "
              : " containerHeightDown"
          }`}
        >
          <nav
            className={`flex flex-col   ${
              isOpen === "basic"
                ? "   "
                : isOpen === true
                ? "transition "
                : " reverseTransition"
            }`}
          >
            {navItems.map((item, index) => (
              <button
                key={index}
                className={`flex items-center justify-center rounded-md ${
                  isOpen === "basic"
                    ? " "
                    : isOpen === true
                    ? "squareUp p-2.5"
                    : "squareDown p-0 "
                } ${item.icon === "add" ? "bg-yellow" : "bg-white"}`}
                onClick={item.onClick}
              >
                <Icons
                  iconType={"transition"}
                  name={item.icon}
                  className={`${
                    isOpen === "basic"
                      ? "text-[0px]"
                      : isOpen === true
                      ? "fontSizeUp"
                      : "fontSizeDown"
                  } ${item.icon === "add" && item.iconClassName} `}
                />
              </button>
            ))}
          </nav>
        </div>

        <nav className="hidden lg:fixed lg:right-0 lg:top-1/2 lg:z-20 lg:flex lg:-translate-y-2/4 lg:transform lg:flex-col lg:gap-5">
          <button
            className="flex items-center justify-center rounded-md bg-white p-2.5 shadow-special"
            onClick={() => displayCustomMenu()}
          >
            <Icons name="brush-fill" height={30} />
          </button>
          <button
            className="flex items-center justify-center rounded-md bg-yellow  p-2.5 shadow-special"
            onClick={() => changeToEditPage()}
          >
            <Icons name="add" className="rotate-45 transform" height={30} />
          </button>
          <button
            className="flex items-center justify-center rounded-md bg-white p-2.5 shadow-special"
            onClick={() => displayInfoMenu()}
          >
            <Icons name="info" height={30} />
          </button>
        </nav>
        {selectedBrand ? (
          <div className="flex w-full items-center justify-center">
            <div className="flex w-full flex-col justify-center">
              <header className="m-auto mb-4 flex max-w-[135px] justify-center">
                {selectedBrand.avatar ? (
                  <img src={selectedBrand.avatar} alt="brand-avatar" />
                ) : (
                  ""
                )}
              </header>
              <div className="mb-5 flex flex-col items-center">
                <p
                  className={`mb-2 font-poppins  text-2xl ${
                    selectedBrand.city
                      ? "border-b border-solid border-grey-dark"
                      : ""
                  }  w-full pb-2 text-center md:w-1/3
                ${findFont(
                  selectedBrand.avatar
                    ? customData.police[2].custom.fontFamily
                    : customData.police[0].custom.fontFamily
                )}
                ${
                  selectedBrand.avatar
                    ? customData.police[2].custom.bold
                      ? "font-bold"
                      : ""
                    : customData.police[0].custom.bold
                    ? "font-bold"
                    : ""
                }
                ${
                  selectedBrand.avatar
                    ? customData.police[2].custom.italic
                      ? "italic"
                      : ""
                    : customData.police[0].custom.italic
                }
              `}
                  style={{
                    fontSize: `${
                      selectedBrand.avatar
                        ? customData.police[2].custom.fontSize
                        : customData.police[0].custom.fontSize
                    }px`,

                    letterSpacing: `${
                      selectedBrand.avatar
                        ? customData.police[2].custom.letterSpacing
                        : customData.police[0].custom.letterSpacing
                    }px`,
                    color: selectedBrand.avatar
                      ? customData.color[2].custom.color
                      : customData.color[1].custom.color,
                    borderColor: customData.color[3].custom.color,
                  }}
                >
                  {selectedBrand.name}
                </p>
                <p
                  className={`font-poppins text-sm
                ${findFont(customData.police[3].custom.fontFamily)}
                ${customData.police[3].custom.bold ? "font-bold" : ""}
                ${customData.police[3].custom.italic ? "italic" : ""}
              `}
                  style={{
                    fontSize: `${customData.police[3].custom.fontSize}px`,
                    letterSpacing: `${customData.police[3].custom.letterSpacing}px`,
                    color: customData.color[2].custom.color,
                  }}
                >
                  {selectedBrand.city}
                </p>
              </div>
              <div className="w-full">
                {custom && (
                  <MenuPreview
                    data={selectedBrand.Menu[0]}
                    custom={customData}
                    selectedSection={
                      (location.state && location.state.section) || null
                    }
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>Loading...</div>
        )}
      </div>

      <BrandInfoPratiquePreview
        isInfoOpen={isInfoOpen}
        setIsInfoOpen={setIsInfoOpen}
        selectedBrand={selectedBrand}
        customData={customData}
      />

      <CustomMenuCard
        isOpen={isCustomOpen}
        setIsOpen={setIsCustomOpen}
        brandId={Number(props.match.params.id)}
      />
    </YellowTemplate>
  );
}
