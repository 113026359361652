// COMPONENTS
import {
  SelectButton,
  DefaultButton,
  RestoInput,
  Icons,
} from "components/atoms";
import { Container, YellowTemplate } from "components";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { fetch0Auth, fetchUser, userSelector } from "store/models/userInfo";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

export default function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const recaptchaRef = useRef();
  const { header } = useSelector(userSelector);
  const [loading, setLoading] = useState(false);
  const [userForm, setUserForm] = useState({
    email: "",
    password: "",
  });

  const sendLogin = async () => {
    if (loading) return;
    const token = await recaptchaRef.current.executeAsync();
    if (!token) return;
    recaptchaRef.current.reset();
    setLoading(true);
    await dispatch(fetchUser({ ...userForm, token: token }));
    setLoading(false);
  };

  /* handle 0auth2 callback */
  const handleAuthCode = async () => {
    if (
      header.status !== "nothing" &&
      header.status !== "pending" &&
      header.status !== "error"
    ) {
      const provider = localStorage.getItem("bonappli_provider");
      let params = new URL(window.location.href).searchParams;
      const code = params.get("code");
      if (code) {
        await dispatch(fetch0Auth({ code, provider }));
      }
    }
  };
  useEffect(() => {
    handleAuthCode();
  }, []);

  const handleProviderClick = (e, provider) => {
    e.preventDefault();
    localStorage.setItem("bonappli_provider", provider);
    window.open(
      `${process.env.REACT_APP_API_URL}/authentication/${provider}`,
      "_self"
    );
  };

  return (
    <>
      <YellowTemplate isConnected={false}>
        <Container
          title={"Connexion au dashboard"}
          className={"!max-w-[600px] p-8  pb-10"}
        >
          <form
            className="mt-7 flex flex-col items-center"
            onSubmit={(e) => {
              e.preventDefault();
              sendLogin();
            }}
          >
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY}
            />
            <RestoInput
              name="email"
              placeholder="Adresse e-mail"
              type="email"
              required
              className="lowercase"
              onChange={(e) =>
                setUserForm({
                  ...userForm,
                  email: e.target.value.toLowerCase(),
                })
              }
              required
            />
            <RestoInput
              name="password"
              placeholder="Mot de passe"
              type="password"
              required
              onChange={(e) =>
                setUserForm({ ...userForm, password: e.target.value })
              }
              required
            />
            {header.status === "error" && (
              <p className="px-4 py-1 text-sm text-red">
                Identifiant ou mot de passe invalide.
              </p>
            )}
            <DefaultButton type="submit">
              {loading ? "Chargement..." : "Connexion"}
            </DefaultButton>
            <div className="mt-[50px] flex w-full max-w-[398px] flex-col items-center">
              <div className="flex w-full  items-center ">
                <div className="w-full border-b-[1px] border-grey-disabled"></div>
                <p className="mx-[13.5px] min-w-max text-sm">
                  Ou identifiez-vous avec
                </p>
                <div className="w-full border-b-[1px] border-grey-disabled"></div>
              </div>
              <div className="w-full max-w-[230px]">
                <button
                  className="mt-[20px]  flex w-full  items-center border border-black"
                  onClick={(e) => {
                    handleProviderClick(e, "google");
                  }}
                >
                  <div className="flex w-1/5 justify-center ">
                    <Icons height={20} name="google1" />
                    <Icons height={20} name="google2" />
                    <Icons height={20} name="google3" />
                    <Icons height={20} name="google4" />
                  </div>
                  <p className="py-[14px] text-smallest uppercase">
                    Continuez avec Google
                  </p>
                </button>

                <button
                  className="mt-[20px]  flex w-full  cursor-not-allowed items-center border border-black opacity-40"
                  onClick={(e) => {
                    e.preventDefault();
                    // handleProviderClick(e, "facebook");
                  }}
                >
                  <div className="flex w-1/5 justify-center  ">
                    <Icons height={20} name="facebookk1" />
                    <Icons height={20} name="facebookk2" />
                  </div>
                  <p className="py-[14px] text-smallest uppercase">
                    Continuez avec Facebook
                  </p>
                </button>
              </div>
            </div>
            <div className=" text-center sm:mt-10 ">
              <p className="mt-10 text-sm sm:mt-0">
                Vous n'avez pas de compte ?
              </p>
              <SelectButton
                onClick={() => {
                  history.push("/auth/register");
                }}
                className={`!mt-2 !mb-0 !font-medium`}
              >
                S'inscrire
              </SelectButton>
            </div>
          </form>
        </Container>
      </YellowTemplate>
    </>
  );
}
