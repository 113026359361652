import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { validateToken } from "api/authentication";
import { fetchSelectedBrand } from "api/brand/brand";
import { fetchMe } from "api/users/Me";
import { getUserInfo } from "./userInfo";

const initialState = {
  error: "",
  status: "done",
  data: [],
};

export const getSelectedRestaurantInfo = createAsyncThunk(
  "restaurant/fetch",
  async (token, id) => {
    if (!token) return;
    const selectedRestaurantData = await fetchSelectedBrand(token, id);
    return selectedRestaurantData;
  }
);

export const checkUserToken = createAsyncThunk(
  "brand/checkToken",
  async (arg, { getState }) => {
    const state = getState();

    const response = await validateToken(state.data.token);
    if (response.response.data) {
      if (!state.data.data.id.length) {
        const { id } = response.response.data;
        const userData = await fetchMe();
        const { userFind } = userData.response.data;
        return userFind;
      }
      return;
    }
    return response.response;
  }
);

export const setSelectedRestaurantInfo = createSlice({
  name: "brand",
  initialState,
  reducers: {
    removeUserInfo: (state) => {
      state.token = null;
      state.error = "";
      state.status = "done";
      state.data = {
        Brand: {},
      };
      cookies.remove("::token");
    },
    addSelectedRestaurantInfo: (state, action) => {
      state.error = "";
      state.status = "done";
      state.data = {
        Brand: action.payload.Brand,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(checkUserToken.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(checkUserToken.fulfilled, (state, action) => {
        if (action.payload && action.payload.status === "error") {
          state.error = action.payload.response;
        } else if (action.payload) {
          state.error = "";
          state.status = "done";
          state.Brand = {
            Brand: action.payload.Brand,
          };
        }
      });
    builder
      .addCase(getSelectedRestaurantInfo.pending, (state) => {
        state.error = "";
        state.status = "loading";
      })
      .addCase(getSelectedRestaurantInfo.fulfilled, (state, action) => {
        if (action.payload.status && action.payload.status === "error") {
          state.error = action.payload.response;
        } else {
          state.error = "";
          state.status = "done";
          state.data = [...action.payload];
          // state.data = action.payload.data;
          // if (action.payload.stay_connected) {
          //   cookies.set("::token", action.payload.response.token, {
          //     path: "/",
          //   });
          // }
        }
      });
  },
});

export const { removeUserInfo, addSelectedRestaurantInfo } =
  setSelectedRestaurantInfo.actions;

export const selectedRestaurantState = (state) => state.restaurant;

export default setSelectedRestaurantInfo.reducer;
