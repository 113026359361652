import Button from "./Button";
import joinClasses from "helpers/joinClasses";

export function NavStepsButton({
  children,
  ButtonType,
  isActive,
  className,
  setNumberNav = () => {},
  data,
}) {
  if (ButtonType === "validated") {
    return (
      <div className="sm:flew-row flex cursor-default">
        <div className=" flex  sm:flex sm:w-auto">
          <div
            onClick={() => setNumberNav(data.number)}
            className={`pointer-events-auto cursor-pointer py-1.5 text-center font-poppins text-sm font-medium uppercase ${
              className ? className : ""
            }`}
          >
            <div className="flex flex-col items-center sm:flex sm:items-center">
              <div className="flex h-9 w-9 items-center justify-center rounded-full bg-yellow p-3 align-middle ">
                {data && data.number ? data.number : ""}
              </div>
              <div className="flex text-smallest sm:flex sm:align-middle sm:text-sm ">
                <p className="whitespace-nowrap">
                  {data && data.name ? data.name : ""}
                </p>
              </div>
            </div>
          </div>
          <p className="hidden cursor-default sm:mx-2 sm:flex sm:justify-center sm:self-center sm:text-grey-disabled">
            {data && data.dots ? data.dots : ""}
          </p>
          <p className="mx-[7px] mb-1 flex cursor-default items-end justify-center text-grey-disabled sm:hidden">
            {data && data.smallDots ? data.smallDots : ""}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className=" flex cursor-default ">
      <div className=" flex sm:w-auto ">
        <div
          onClick={() => setNumberNav(data.number)}
          className={`pointer-events-auto cursor-pointer py-1.5 text-center font-poppins text-sm font-medium uppercase text-grey-disabled ${
            className ? className : ""
          }`}
        >
          <div className="flex  flex-col items-center sm:flex sm:items-center">
            <div className="flex h-9  w-9 items-center justify-center rounded-full border border-solid border-yellow bg-white align-middle hover:bg-yellow hover:transition hover:duration-300">
              {data && data.number ? data.number : ""}
            </div>
            <div className="flex text-smallest sm:flex sm:align-middle sm:text-sm ">
              <p className="">{data && data.name ? data.name : ""}</p>
            </div>
          </div>
        </div>
        <p className="hidden cursor-default sm:mx-2 sm:flex sm:justify-center sm:self-center sm:text-grey-disabled ">
          {data && data.dots ? data.dots : ""}
        </p>
        <p className="mx-[7px] mb-1 flex cursor-default items-end justify-center text-grey-disabled sm:hidden">
          {data && data.smallDots ? data.smallDots : ""}
        </p>
      </div>
    </div>
  );
}
