export function EditDisplayRestau({
  index,
  editingDisplay,
  setEditingDisplay,
  handleChange,
}) {
  const changeDisplay = async (e) => {
    var tmp = [...editingDisplay];
    tmp[index] = {
      ...editingDisplay[index],
      visible: e.target.checked,
    };
    await handleChange("display", tmp);
    setEditingDisplay(tmp);
  };

  return (
    <div className="flex items-center justify-between rounded-lg bg-yellow bg-opacity-25 py-4 px-8">
      <label className="flex cursor-pointer items-center gap-4">
        <input
          defaultChecked={editingDisplay[index].visible}
          type="checkbox"
          onChange={changeDisplay}
          className="bg-white text-black"
        />

        <p className="uppercase">{editingDisplay[index].title}</p>
      </label>
    </div>
  );
}
