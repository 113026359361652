import {
  Route,
  BrowserRouter as Router,
  Switch,
  BrowserRouter,
} from "react-router-dom";

import LoaderPage from "pages/_loader";
import { LegalNotice } from "pages/LegalNotice";

export default function LoadingRouter() {
  return (
    <>
      <BrowserRouter>
        <Router>
          <Switch>
            <Route path="/" component={LoaderPage} />
            <Route exact path="/mentions-legales" component={LegalNotice} />
          </Switch>
        </Router>
      </BrowserRouter>
    </>
  );
}
