import axios from "axios";
import getFormatedToken from "api/helpers/getFormatedToken";

export async function fetchOneMenu({ id }) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/menu/${id}`,

    getFormatedToken()
  );
  const { data } = response.data;
  return data.menu;
}

export async function createMenu(id) {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/menu`,
      { brandId: id },

      getFormatedToken()
    );

    const data = response.data;

    return data;
  } catch (error) {
    return false;
  }
}

export async function updateMenu({ token, id, payload }) {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/menu/${id}`,
      payload,
      getFormatedToken()
    );

    const { data } = response.data;

    return data;
  } catch (error) {
    return false;
  }
}

export async function fetchOneMenuByUrl({ token, url }) {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/view/menu/${url}`
  );
  const { data } = response.data;
  return data.menu;
}
