import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchCustom,
  sendEditCustom,
  sendCreateCustom,
} from "api/custom/custom";
import axios from "axios";
import fontsList from "utils/fontsList";
import googleFontsList from "utils/googleFontsLIst";

const initialState = {
  header: { error: false, status: "pending" },
  customs: [],
  fonts: [],
};

export const fetchAllFonts = createAsyncThunk("custom/getfont", async () => {
  // const response = await axios.get(
  //   `https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=${process.env.REACT_APP_GOOGLE_FONT_KEY}`
  // );
  //response.data.items

  const doubleTab = [...googleFontsList, ...fontsList];
  return doubleTab.sort(function (a, b) {
    if (a.family.toLowerCase() < b.family.toLowerCase()) {
      return -1;
    }
    if (a.family.toLowerCase() > b.family.toLowerCase()) {
      return 1;
    }
    return 0;
  });
});

export const fetchAllCustom = createAsyncThunk("custom/get", async (userid) => {
  const custom = await fetchCustom(userid);
  console.log("custom", custom);
  return custom;
});

export const fetchEditCustom = createAsyncThunk(
  "custom/edit",
  async (data, { getState }) => {
    const state = getState();

    const index = state.custom.customs.findIndex(
      (item) => item.brandId === data.brandId
    );
    // si le custom existe déjà dans le store
    if (state.custom.customs[index]) {
      const custom = await sendEditCustom({
        ...data,
        id: state.custom.customs[index].id,
      });

      return {
        type: "edit",
        index: index,
        custom: { ...data, id: custom.id },
      };
    } else {
      // sinon on recupère tous les customs
      let custom = await fetchCustom(data.userId);

      const indexTmp = custom.findIndex(
        (item) => item.brandId === data.brandId
      );

      // si le custom existe déjà en db mais pas dans le store, on le modifie
      if (custom[indexTmp]) {
        const customUpdated = await sendEditCustom({
          ...data,
          id: custom[indexTmp].id,
        });
        custom[indexTmp] = customUpdated;
        return {
          type: "edit-update",
          customs: custom,
        };
      } else {
        // sinon on le crée
        const custom = await sendCreateCustom(data);

        return {
          type: "create",
          custom: { ...data, id: custom.id },
        };
      }
    }
  }
);

export const customSlice = createSlice({
  name: "custom",
  initialState,
  reducers: {
    // removeUser: (state) => {
    //   state.header = { ...initialState.header };
    //   state.user = { ...initialState.user };
    //   cookies.remove("::token");
    // },
  },
  extraReducers: (builder) => {
    /* Google fonts successfully fetched */
    builder.addCase(fetchAllFonts.fulfilled, (state, action) => {
      state.fonts = action.payload;
    });

    /* Waiting for customs to be fetched .... */
    builder.addCase(fetchAllCustom.pending, (state) => {
      state.header = { error: false, status: "pending" };
    });

    /* Custom successfully fetched */
    builder.addCase(fetchAllCustom.fulfilled, (state, action) => {
      state.customs = action.payload;
      state.header = { error: false, status: "success" };
    });

    /* An error appeared */
    builder.addCase(fetchAllCustom.rejected, (state, action) => {
      state.header = { error: true, status: "error" };
    });

    /* EditCustom successfully fetched */
    builder.addCase(fetchEditCustom.fulfilled, (state, action) => {
      if (action.payload.type === "edit") {
        state.customs[action.payload.index] = action.payload.custom;
      } else if (action.payload.type === "edit-update") {
        state.customs = action.payload.customs;
      } else {
        state.customs.push(action.payload.custom);
      }
      state.header = { error: false, status: "success" };
    });
  },
});

// export const { removeUser, setUser } = customSlice.actions;
export const customSelector = (state) => state.custom;
export default customSlice.reducer;
