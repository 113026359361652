import { Card, Icons, LogoPicker } from "components/atoms";
import { useEffect, useState } from "react";
import { TimePatron } from "../Patrons/TimePatron";
import "./switch.css";
import addAlpha from "helpers/addAlpha";
import { fetchSelectedBrand, updateBrand } from "api/brand/brand";
import { Link } from "react-router-dom";

export function BrandInfoPratique({
  customData,
  isInfoOpen = false,
  setIsInfoOpen = () => {},
  selectedBrand,
  currentSection = false,
  setCurrentSection = () => {},
}) {
  const [disabled, setDisabled] = useState(false);
  const [schedule, setSchedule] = useState({
    Lundi: null,
    Mardi: null,
    Mercredi: null,
    Jeudi: null,
    Vendredi: null,
    Samedi: null,
    Dimanche: null,
  });
  const [links, setLinks] = useState([""]);

  useEffect(() => {
    if (selectedBrand) {
      setDisabled(selectedBrand.isSchedule);
      setSchedule({
        Lundi: selectedBrand.schedule && selectedBrand.schedule.Lundi,
        Mardi: selectedBrand.schedule && selectedBrand.schedule.Mardi,
        Mercredi: selectedBrand.schedule && selectedBrand.schedule.Mercredi,
        Jeudi: selectedBrand.schedule && selectedBrand.schedule.Jeudi,
        Vendredi: selectedBrand.schedule && selectedBrand.schedule.Vendredi,
        Samedi: selectedBrand.schedule && selectedBrand.schedule.Samedi,
        Dimanche: selectedBrand.schedule && selectedBrand.schedule.Dimanche,
      });
      setLinks(JSON.parse(selectedBrand.links) || []);
    }
  }, [selectedBrand]);

  useEffect(() => {
    changeBrand({ schedule: schedule });
  }, [schedule]);

  useEffect(() => {
    changeBrand({ links: JSON.stringify(links) });
  }, [links]);

  /*   const getBrand = async () => {
    setBrand(
      await fetchSelectedBrand({ id: selectedBrand && selectedBrand.id })
    );
  }; */

  const changeBrand = async (data) => {
    if (selectedBrand) {
      await updateBrand(selectedBrand.id, data);
    }
  };

  return (
    <Card
      className={`fixed right-0 top-1/2 h-full -translate-y-2/4 transform  font-poppins md:h-auto  ${
        !isInfoOpen && "hidden"
      } z-50 w-full bg-white shadow-special  transition-all  sm:w-[400px]`}
      style={{
        backgroundColor:
          customData && addAlpha(customData.color[0].custom.color, 0.85),
      }}
    >
      {/* <LogoPicker className={` w-full !my-0 relative h-[240px]`}/> */}
      <div
        className="top-0 left-0 flex w-full items-center justify-between  "
        style={{
          backgroundColor:
            customData && addAlpha(customData.color[0].custom.color, 0.85),
        }}
      >
        <div className="ml-4 flex gap-4">
          <Icons
            name="info"
            className=" pointer-events-none right-0 top-0"
            style={{
              color: customData && customData.color[2].custom.color,
            }}
            height={25}
          />
          <h3
            className="flex items-center  text-sm font-bold"
            style={{
              color: customData && customData.color[2].custom.color,
            }}
          >
            Infos pratiques
          </h3>
        </div>
        <button
          className="bg-yellow"
          style={{
            width: "52px",
            height: "52px",
            backgroundColor: customData && customData.color[1].custom.color,
          }}
          onClick={() => {
            setIsInfoOpen(false), setCurrentSection(0);
          }}
        >
          <Icons
            name="add"
            height={20}
            className="block  rotate-45 transform"
            style={{ color: customData && customData.color[0].custom.color }}
          />
        </button>
      </div>
      <div className="w-full overflow-y-auto overflow-x-hidden rounded-xl  bg-transparent">
        <div className=" m-auto flex w-11/12 flex-col ">
          <div className="relative m-auto mt-3  flex max-h-[126px] min-h-[40px] w-full flex-col justify-center rounded border bg-transparent py-2 pl-[14px]">
            <p className="mb-2 text-2xl font-bold">
              {selectedBrand && selectedBrand.name}
            </p>
            <p>
              {selectedBrand && selectedBrand.address1}
              {selectedBrand && selectedBrand.address2 && (
                <p>{selectedBrand.address2}</p>
              )}
            </p>
            <p>
              {selectedBrand &&
                selectedBrand.zip + " " + selectedBrand &&
                selectedBrand.city}
            </p>
            <Link to={`/pages/dashboard/${selectedBrand && selectedBrand.id}`}>
              <div className="absolute top-2 right-2 hover:opacity-50">
                <Icons
                  name="crayon-1"
                  style={{
                    color: customData && customData.color[1].custom.color,
                  }}
                  height="20"
                />
                <Icons
                  name="crayon-2"
                  style={{
                    color: customData && customData.color[1].custom.color,
                  }}
                  height="20"
                />
              </div>
            </Link>
          </div>
          <div className="m-auto  mt-2.5 flex w-11/12 items-center justify-between">
            <Icons
              name="phone1"
              height={30}
              className="pointer-events-none"
              style={{ color: customData && customData.color[1].custom.color }}
            />
            <input
              placeholder="numéro de téléphone"
              defaultValue={selectedBrand && selectedBrand.phone}
              type="phone"
              className="focus:active mr-2 w-3/4 rounded border-2 border-black bg-transparent text-xl font-bold"
              style={{
                padding: "0.5em 0 0.5em 15px",
                color: customData && customData.color[1].custom.color,
                borderColor: customData && customData.color[1].custom.color,
              }}
              onBlur={(e) => changeBrand({ phone: e.target.value })}
            ></input>
          </div>
          <div className="mt-[22px] flex flex-col gap-1 ">
            <div className="mb-1 flex items-center gap-2">
              <h4
                className="text-xs font-bold"
                style={{
                  color: customData && customData.color[1].custom.color,
                }}
              >
                HORAIRES :
              </h4>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={disabled}
                  onChange={(e) => {
                    setDisabled(e.target.checked),
                      changeBrand({ isSchedule: e.target.checked });
                  }}
                />
                {disabled ? (
                  <span
                    className="slider round"
                    style={{
                      backgroundColor:
                        customData && customData.color[1].custom.color,
                    }}
                  ></span>
                ) : (
                  <span
                    className="slider round border"
                    style={{
                      backgroundColor:
                        customData && customData.color[0].custom.color,
                      borderColor:
                        customData && customData.color[2].custom.color,
                    }}
                  ></span>
                )}
              </label>
            </div>
            {Object.keys(schedule).map((day, index) => (
              <TimePatron
                customData={customData}
                data={schedule[day]}
                disabled={!disabled}
                key={index}
                callBack={(hours) => {
                  setSchedule({ ...schedule, [day]: hours });
                }}
              >
                {day}
              </TimePatron>
            ))}

            <div></div>
          </div>
          <div>
            <div className="flex items-center  gap-2 ">
              <p
                className="text-xs font-bold"
                style={{
                  color: customData && customData.color[1].custom.color,
                }}
              >
                Commander :{" "}
              </p>
              <button className="py-2">
                <Icons
                  name="add"
                  height={13}
                  className="rounded-full bg-yellow p-2"
                  onClick={() => setLinks([...links, ""])}
                  style={{
                    color: customData && customData.color[0].custom.color,
                    backgroundColor:
                      customData && customData.color[1].custom.color,
                  }}
                ></Icons>
              </button>
            </div>
            {links &&
              links.map((value, index) => (
                <div className="mb-2 flex " key={index}>
                  <input
                    className="border-1 w-full max-w-[300px] truncate rounded border border-black bg-yellow bg-transparent pl-2 text-sm "
                    style={{
                      borderColor:
                        customData && customData.color[1].custom.color,
                    }}
                    placeholder="Lien"
                    defaultValue={value}
                    onBlur={(e) => {
                      const newLinks = [...links];
                      newLinks[index] = e.target.value;
                      setLinks(newLinks);
                    }}
                  />
                  <Icons
                    name="add"
                    className="m-auto rotate-45 rounded-full bg-red p-1"
                    style={{
                      backgroundColor:
                        customData && customData.color[1].custom.color,
                      color: customData && customData.color[0].custom.color,
                    }}
                    height={12}
                    onClick={() => {
                      const newLinks = [...links];
                      newLinks.splice(index, 1);
                      setLinks(newLinks);
                    }}
                  >
                    X
                  </Icons>
                </div>
              ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
