import { useState, useEffect } from "react";
import { billingPortal } from "../../api/authentication";

/* Components */
import { BigCard, SubTitle } from "components/atoms";
import { YellowTemplate } from "components";
import AccountMenu from "./accountMenu";
import PopUp from "./popUp";
import { Icons } from "components/atoms";

/* Slice */
import Restaurant from "./slice/restaurants";

/* API */
import { updateUser } from "api/users";

import { useSelector, useDispatch } from "react-redux";
import { setUser, userSelector } from "store/models/userInfo";
import { brandSelector, getBrand } from "store/models/restaurantInfo";

export default function AccountPag() {
  const [billingPortalURL, setBillingPortalURL] = useState("");
  const [updatePopup, setUpdatePopup] = useState(false);
  const [page, setPage] = useState("account");

  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);
  const { brand } = useSelector(brandSelector);

  const [userForm, setUserForm] = useState({
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    phone: undefined,
  });

  const getbillingPortal = async () => {
    //setBillingPortalURL(await billingPortal());
  };

  const triggerUpdateUser = async () => {
    const newUser = await updateUser(user.id, userForm);
    dispatch(setUser(newUser));
  };

  useEffect(() => {
    dispatch(getBrand());
    getbillingPortal();
  }, []);

  return (
    <YellowTemplate title="mon compte">
      {/* <a href={billingPortalURL}>Gérer mon abonnement</a> */}
      <div className=" md:mx-auto md:w-10/12">
        <div className="mt-2 flex h-full flex-row items-center justify-center md:mt-10">
          <div className="flex h-full w-full flex-col items-start justify-center gap-5 md:w-3/4 md:flex-row">
            <AccountMenu setPage={setPage} page={page} />
            <BigCard className="mx-auto  w-11/12 bg-white md:w-2/3">
              {user && page == "account" ? (
                <div className="h-full w-full">
                  <div className="flex w-full flex-col items-center">
                    <div className="my-5 flex w-full flex-col items-center gap-2 pt-4 md:pt-6">
                      <Icons
                        name="user"
                        height="60"
                        className="hidden md:block"
                      />
                      <Icons name="user" height="60" className="md:hidden" />
                      <SubTitle>
                        {user.firstname + " " + user.lastname}
                      </SubTitle>
                      <p>{user.email}</p>
                      <p className="tracking-wider">{user.phone}</p>
                      <button
                        onClick={() => {
                          setUpdatePopup(true);
                        }}
                        className="cursor-pointer text-grey-medium"
                      >
                        Modifier
                      </button>
                    </div>
                  </div>
                </div>
              ) : page === "restaurants" ? (
                <div className="h-full w-full py-5">
                  <Restaurant brand={brand} />
                </div>
              ) : page === "sub" ? (
                <div>orders</div>
              ) : null}
            </BigCard>
          </div>

          {/* Update PopUp */}
          {updatePopup && (
            <PopUp
              oldData={user}
              setUserForm={setUserForm}
              userForm={userForm}
              saveData={triggerUpdateUser}
              triggerOff={setUpdatePopup}
            />
          )}
        </div>
      </div>
    </YellowTemplate>
  );
}
