import Button from "./Button";
import joinClasses from "helpers/joinClasses";
import { Icons } from "components/atoms";

export function HandleCategorie({
  children,
  ButtonType,
  className = "",
  onClick,
  edit,
  isGlobal,
  isSelected,
}) {
  return (
    <Button
      onClick={onClick}
      className={joinClasses(
        className,
        " flex  h-[38px] items-center justify-between gap-2 border border-solid border-black py-[7px] pl-5 pr-[7px] font-poppins  text-sm  md:h-12   md:max-w-[180px] md:pr-2  ",
        isSelected ? "bg-grey" : "bg-white"
      )}
    >
      <p className="truncate text-left md:w-full">{children}</p>
      {edit ? (
        !isGlobal && (
          <Icons
            name="trash"
            height={"14"}
            className="rounded-full bg-red p-2 text-white"
          />
        )
      ) : isSelected ? (
        <Icons
          name="valide"
          height="14"
          className="rounded-full border-2 border-yellow bg-grey py-0.5  text-yellow md:border-4 md:py-1  md:px-0.5 "
        />
      ) : (
        <Icons
          name="add"
          height="14"
          className="rounded-full bg-yellow p-1 text-white  md:p-2"
        />
      )}
    </Button>
  );
}
