import {
  BorderedButton,
  Card,
  DefaultButton,
  SubParagraph,
} from "components/atoms";
import { useState } from "react";

export default function PopUp({
  oldData,
  saveData = () => {},
  setUserForm,
  userForm,
  triggerOff,
}) {
  return (
    <div className="absolute bottom-0 top-0 flex  w-full items-center justify-center bg-black-opacity ">
      <Card className="flex w-full flex-col items-center px-4 py-10 mobileL:block mobileL:w-auto  mobileL:p-10  ">
        <div className="w-full text-center mobileL:text-left">
          <div className="my-3 border-b border-black">
            Modifier votre profil
          </div>
        </div>
        <div>
          <div className="mobileL:my-6 ">
            <SubParagraph>Prénom</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base "
              defaultValue={oldData.firstname}
              onChange={(e) => {
                setUserForm({ ...userForm, firstname: e.target.value });
              }}
            />
          </div>
          <div className="my-6">
            <SubParagraph>Nom</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base"
              defaultValue={oldData.lastname}
              onChange={(e) => {
                setUserForm({ ...userForm, lastname: e.target.value });
              }}
            />
          </div>
          <div className="my-6">
            <SubParagraph>E-mail</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base"
              defaultValue={oldData.email}
              onChange={(e) => {
                setUserForm({ ...userForm, email: e.target.value });
              }}
            />
          </div>
          <div className="my-6">
            <SubParagraph>Numéro de téléphone</SubParagraph>
            <input
              type="text"
              className="h-8 text-sm mobileL:text-base"
              defaultValue={oldData.phone}
              onChange={(e) => {
                setUserForm({ ...userForm, phone: e.target.value });
              }}
            />
          </div>
        </div>
        <div className="flex">
          <div className="mt-6 flex w-full justify-end gap-1 mobileL:gap-3">
            <BorderedButton
              borderType="small"
              onClick={() => {
                triggerOff(false);
              }}
            >
              Annuler
            </BorderedButton>
            <DefaultButton
              className="bg-yellow"
              onClick={(e) => {
                e.preventDefault();
                saveData();
                triggerOff(false);
              }}
            >
              Enregistrer
            </DefaultButton>
          </div>
        </div>
      </Card>
    </div>
  );
}
