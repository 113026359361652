import { Card, Title } from "components/atoms";

export function Container({ children, title, className }) {
  return (
    <div className=" mt-10 ">
      <section className="px-[29px]  ">
        <Title TextSize="smaller" className="mb-[22px] text-center">
          {title}
        </Title>

        <Card
          className={`  m-auto max-w-[500px] pt-7
              ${className}`}
        >
          {children}
        </Card>
      </section>
    </div>
  );
}
