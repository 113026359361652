// COMPONENTS
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Icons, NavStepsButton } from "components/atoms";
import "./animation.css";
import { Link } from "react-router-dom";
import { CustomMenuCard, PreviewMenu } from "components";

import { useHistory, useLocation } from "react-router";
import { getBrand } from "store/models/restaurantInfo";
import { useDispatch } from "react-redux";
import { getPositionInPercent } from "helpers/scrollConvert";
import { BrandInfoPratique } from "../Cards/BrandInfoPratique";
import { customSelector } from "store/models/customInfo";
import { findDataCustom, findFont } from "helpers/findDataCustom";
import { getCloseSection } from "helpers/getCloseSection";

export function MenuSideButtons({
  brandId,
  selectedBrand,
  isAppear = "basic",
  historyState = {},
}) {
  const [currentSection, setCurrentSection] = useState(0);
  const [closeSection, setCloseSection] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const [customData, setCustomData] = useState(null);

  const history = useHistory();
  const custom = useSelector(customSelector);
  const location = useLocation();

  const navItems = [
    {
      id: 1,
      icon: "brush-fill",
    },
    {
      id: 2,
      icon: "view",
    },
    {
      id: 3,
      icon: "info",
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {}, [isInfoOpen]);

  useEffect(() => {
    if (custom) {
      setCustomData(findDataCustom(custom.customs, brandId));
    }
  }),
    [custom];

  useEffect(() => {
    window.addEventListener("scroll", function () {
      let closeSection = 0;
      (closeSection = getCloseSection()) && setCloseSection(closeSection);
    });
  }, []);

  function displayInfoMenu() {
    setIsInfoOpen(true);
  }
  useEffect(() => {
    if (currentSection === 1) setIsOpen(true);
    else if (currentSection === 2) {
      history.push({
        pathname: `/dashboard/brand/preview/${brandId}`,
        state: {
          ...historyState,
          scroll: getPositionInPercent(),
          section:
            closeSection !== 0
              ? closeSection
              : location.state
              ? location.state.section
              : "",
          savedScroll: window.scrollY,
        },
      });
    } else if (currentSection === 3) {
      setIsInfoOpen(true);
    } else setIsOpen(false);
  }, [currentSection]);

  useEffect(() => {
    if (currentSection === 1 && isOpen === false) {
      setCurrentSection(0);
    }
  }, [isOpen]);

  // OnChange avec un délai de 1 seconde

  return (
    <>
      <div
        className={`fixed right-5 bottom-5 z-30 flex w-[40px]  items-end justify-center lg:hidden ${
          isOpen === "basic"
            ? "   "
            : isOpen === true
            ? "containerHeightUp "
            : " containerHeightDown"
        }`}
      >
        <nav
          className={`flex flex-col   ${
            isAppear === "basic"
              ? "   "
              : isAppear === true
              ? "transition "
              : " reverseTransition"
          }`}
        >
          {navItems.map((item, index) => (
            <button
              key={index}
              className={` flex items-center justify-center rounded-md    ${
                !item.icon && "opacity-0"
              } ${
                isAppear === "basic"
                  ? " "
                  : isAppear === true
                  ? "squareUp p-2.5"
                  : "squareDown p-0 "
              } ${currentSection === item.id ? "bg-yellow" : "bg-white"} `}
              onClick={() => setCurrentSection(item.id)}
            >
              <Icons
                iconType={"transition"}
                name={item.icon}
                className={
                  isAppear === "basic"
                    ? "text-[0px]"
                    : isAppear === true
                    ? "fontSizeUp"
                    : "fontSizeDown"
                }
              />
            </button>
          ))}
        </nav>
      </div>

      <nav className="hidden lg:fixed lg:right-0 lg:top-1/2 lg:z-20 lg:flex lg:-translate-y-2/4 lg:transform lg:flex-col lg:gap-5">
        {navItems.map((item, index) => (
          <button
            key={index}
            className={`flex h-[50px] w-[55px]  items-center justify-center rounded-md p-2.5 shadow-special hover:bg-grey-light hover:duration-300   ${
              currentSection != "3"
                ? currentSection === item.id
                  ? "bg-yellow"
                  : "bg-white"
                : "bg-white"
            } `}
            onClick={() => setCurrentSection(item.id)}
          >
            <Icons name={item.icon} height={30} />
          </button>
        ))}
      </nav>

      <CustomMenuCard isOpen={isOpen} setIsOpen={setIsOpen} brandId={brandId} />
      <BrandInfoPratique
        customData={customData}
        isInfoOpen={isInfoOpen}
        setIsInfoOpen={setIsInfoOpen}
        currentSection={currentSection}
        setCurrentSection={setCurrentSection}
        selectedBrand={selectedBrand}
      />
    </>
  );
}
