import { deleteDrink, updateDrink } from "api/drink/drink";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { userSelector } from "store/models/userInfo";
import { Icons } from "components/atoms";
import { findFont } from "helpers/findDataCustom";

export function Drink({ drink, drinkId, volumes, custom }) {
  const { user } = useSelector(userSelector);

  const [prices, setPrices] = useState({});
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (drink) {
      setPrices({
        x15cl: drink.x15cl,
        x25cl: drink.x25cl,
        x375cl: drink.x375cl,
        x50cl: drink.x50cl,
        x75cl: drink.x75cl,
      });
    }
  }, [drink]);

  const changeDrink = async (data) => {
    await updateDrink({
      id: drinkId,
      token: user.token,
      payload: data,
    });
  };

  const removeDrink = async () => {
    await deleteDrink({ id: drinkId });
    setShow(false);
  };

  useEffect(() => {
    if (prices) {
      changeDrink(prices);
    }
  }, [prices]);
  return show ? (
    <div
      className="relative mb-5 flex  flex-col md:mb-0 md:flex md:flex-row md:items-center"
      id={`drink-${drinkId}`}
    >
      <div className="flex items-center justify-between md:w-2/3 md:justify-start ">
        <input
          className={`my-1 h-1/3 w-4/5 truncate rounded border border-solid border-grey-disabled bg-transparent py-2.5 pl-2 text-sm md:w-full md:pl-4 lg:text-base ${
            custom && findFont(custom.police[3].custom.fontFamily)
          }
        ${custom && custom.police[3].custom.bold && "font-bold"}
        ${custom && custom.police[3].custom.italic && "italic"}`}
          placeholder="Nom du Vin"
          defaultValue={drink.name}
          name={`drink-${drinkId}`}
          onBlur={(e) => changeDrink({ name: e.target.value.trim() })}
          style={{
            fontSize: custom && custom.police[3].custom.fontSize,
            letterSpacing: custom && custom.police[3].custom.letterSpacing,
            color: custom && custom.color[2].custom.color,
          }}
        ></input>
        <Icons
          name="trash"
          height="18"
          className="ml-2 pr-2 md:pr-0"
          onClick={(e) => {
            e.preventDefault();
            removeDrink();
          }}
          style={{ color: custom && custom.color[1].custom.color }}
        />
      </div>
      <div
        className={`flex w-full items-center justify-between gap-2 md:relative md:w-full md:items-center md:justify-end md:gap-0 md:overflow-hidden md:align-middle ${
          custom && !custom.display[0].visible && "opacity-50"
        }`}
      >
        {custom && !custom.display[0].visible && (
          <div className="absolute h-full w-full cursor-not-allowed" />
        )}
        <div
          className="hidden w-full border-t-2 border-dotted border-black md:mx-2 md:block"
          style={{ borderColor: custom && custom.color[1].custom.color }}
        ></div>
        {volumes &&
          Object.keys(volumes).map(
            (row, index) =>
              volumes[row] && (
                <input
                  key={index}
                  className={`my-1 w-1/5 rounded border border-solid border-grey-disabled bg-transparent pt-2 pb-1.5 text-center text-xs md:mx-1 md:w-[60px] md:py-2.5  md:text-base lg:mx-2 lg:w-16 
                    ${custom && findFont(custom.police[4].custom.fontFamily)}
                    ${custom && custom.police[4].custom.bold ? "font-bold" : ""}
                    ${
                      custom && custom.police[4].custom.italic ? "italic" : ""
                    }`}
                  placeholder="Prix"
                  style={{
                    fontSize: `${custom && custom.police[4].custom.fontSize}px`,
                    letterSpacing: `${
                      custom && custom.police[4].custom.letterSpacing
                    }px`,
                    color: custom && custom.color[2].custom.color,
                  }}
                  defaultValue={prices[`x${row.replace(",", "")}`]}
                  onBlur={(e) =>
                    setPrices({
                      ...prices,
                      [`x${row.replace(",", "")}`]: e.target.value.trim(),
                      change: true,
                    })
                  }
                ></input>
              )
          )}
      </div>
    </div>
  ) : (
    <></>
  );
}
