import { ThinHorizontalLine, Title } from "components/atoms";
import { findFont } from "helpers/findDataCustom";
import { PricesPreview, DrinksPreview } from "components";
import FontPicker from "font-picker-react";
import { DefaultModal } from "components";
import { useState } from "react";
const reactStringReplace = require("react-string-replace");

// import reactStringReplace from "react-string-replace"
const drinkBorderCSS = "border-l-2 border-dotted";

const bottle = [
  { cl: "15cl", width: "3", height: 28 },
  { cl: "25cl", width: "4", height: 32 },
  { cl: "37,5cl", width: "3", height: 48 },
  { cl: "50cl", width: "9", height: 40 },
  { cl: "75cl", width: "4", height: 64 },
];

export function MenuSectionsPreview({ data, custom }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedDish, setselectedDish] = useState({
    title: null,
    image: null,
  });

  return (
    <div className="m-auto flex w-full max-w-6xl flex-col justify-center gap-1">
      {data
        .sort((a, b) => (a.positionId > b.positionId ? 1 : -1))
        .map((dish, index) => (
          /* men10u section */
          <div
            key={index}
            className="mt-10 mb-10 flex  w-full  flex-col items-center"
            id={`section-${dish.id}`}
            trigger={`section-${dish.id}`}
          >
            {dish.avatar && (
              <img src={dish.avatar} className="w-11/12 md:w-2/3" />
            )}
            <div
              className="mb-3 w-11/12 border-b-2 md:w-2/3"
              style={{ borderColor: custom.color[3].custom.color }}
            >
              <Title
                className={` break-words
                ${findFont(custom.police[0].custom.fontFamily)}
                ${custom.police[0].custom.bold ? "font-bold" : "!font-normal"}
                ${custom.police[0].custom.italic ? "italic" : ""}
              `}
                style={{
                  marginTop: "0.4em",
                  fontSize: `${custom.police[0].custom.fontSize}px`,
                  letterSpacing: `${custom.police[0].custom.letterSpacing}px`,
                  color: custom.color[1].custom.color,
                }}
              >
                {dish.title}
              </Title>
            </div>
            {dish.isGlobal && dish.DrinkVolume.length != 0 ? (
              /* IF ITS A DRINKS SECTION */
              <DrinksPreview dish={dish} index={index} custom={custom} />
            ) : (
              /* IF ITS A DISH SECTION */
              <div className="w-11/12 md:w-2/3">
                {/* PLATS */}
                {dish &&
                  dish.Dish &&
                  dish.Dish.sort((a, b) =>
                    a.positionId > b.positionId ? 1 : -1
                  ).map((item, index) => (
                    <div
                      key={index}
                      className={`my-3 mb-5 flex w-full gap-5  ${
                        item.name === null || item.name === "" ? "hidden" : ""
                      }`}
                    >
                      {custom.display[1].visible && item.avatar && (
                        <>
                          <img
                            src={item.avatar}
                            style={{ width: "90px", height: "90px" }}
                            className="h-max cursor-pointer"
                            onClick={() => {
                              setIsOpen(true),
                                setselectedDish({
                                  title: item.name,
                                  image: item.avatar,
                                });
                            }}
                          />
                        </>
                      )}

                      <div
                        className={`flex w-full flex-col justify-start ${
                          item.name === null || item.name === "" ? "hidden" : ""
                        }`}
                      >
                        <div
                          className={`flex flex-col justify-start md:flex-row md:items-center md:justify-between md:gap-5  `}
                        >
                          {/* TITLE OF DISH */}

                          <div
                            className={`md:min-w-max
                                ${
                                  custom &&
                                  findFont(custom.police[2].custom.fontFamily)
                                }
                                ${
                                  custom.police[2].custom.bold
                                    ? "font-bold"
                                    : ""
                                }
                                ${
                                  custom.police[2].custom.italic ? "italic" : ""
                                }
                                
                              `}
                            style={{
                              fontSize: `${custom.police[2].custom.fontSize}px`,
                              letterSpacing: `${custom.police[2].custom.letterSpacing}px`,
                              color: custom.color[2].custom.color,
                            }}
                          >
                            {item.name}
                          </div>
                          {/* PRICE OF DISH */}
                          {custom.display[0].visible && (
                            <>
                              <div
                                className="hidden h-0 w-full border-t border-dotted border-black md:block "
                                style={{
                                  borderColor: custom.color[3].custom.color,
                                }}
                              />
                              <PricesPreview
                                custom={custom}
                                className=" min-w-max "
                              >
                                {item.price === null || item.price === "" ? (
                                  <div
                                    className={`text-left md:min-w-[50px] md:text-center
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                                    style={{
                                      fontSize: `${
                                        custom &&
                                        custom.police[4].custom.fontSize
                                      }px`,
                                      letterSpacing: `${
                                        custom &&
                                        custom.police[4].custom.letterSpacing
                                      }px`,
                                      color: custom.color[2].custom.color,
                                    }}
                                  >
                                    -
                                  </div>
                                ) : (
                                  <div
                                    className={`text-left md:min-w-[50px] md:text-center
                             ${
                               custom &&
                               findFont(custom.police[4].custom.fontFamily)
                             }
                             ${custom.police[4].custom.bold ? "font-bold" : ""}
                             ${custom.police[4].custom.italic ? "italic" : ""}
                           `}
                                    style={{
                                      fontSize: `${
                                        custom &&
                                        custom.police[4].custom.fontSize
                                      }px`,
                                      letterSpacing: `${
                                        custom &&
                                        custom.police[4].custom.letterSpacing
                                      }px`,
                                      color: custom.color[2].custom.color,
                                    }}
                                  >
                                    {" "}
                                    {reactStringReplace(
                                      `${item.price}`,
                                      "€",
                                      (match, i) => ""
                                    )}
                                    €
                                  </div>
                                )}
                              </PricesPreview>
                            </>
                          )}
                        </div>
                        <div className="flex gap-1">
                          {/* INGREDIENTS */}
                          {item.ingredients.map((ingredient, index) => (
                            <h3
                              key={index}
                              className={`
                            ${
                              custom &&
                              findFont(custom.police[3].custom.fontFamily)
                            }
                            ${custom.police[3].custom.bold ? "font-bold" : ""}
                            ${custom.police[3].custom.italic ? "italic" : ""}
                          `}
                              style={{
                                fontSize: `${custom.police[3].custom.fontSize}px`,
                                letterSpacing: `${
                                  custom &&
                                  custom.police[3].custom.letterSpacing
                                }px`,
                                color: custom.color[2].custom.color,
                              }}
                            >
                              {ingredient}
                              {index + 1 !== item.ingredients.length && ", "}
                            </h3>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
            <DefaultModal
              title={selectedDish.title}
              isOpen={isOpen}
              setIsOpen={(action) => setIsOpen(action)}
              className={`pb-5`}
            >
              <img
                src={selectedDish.image}
                style={{ width: "270px", height: "270px" }}
                className="m-auto h-max"
              />
            </DefaultModal>

            <div className="w-11/12 md:w-2/3">
              {dish && dish.Comment.length && dish.title != "Commentaires" ? (
                <div
                  className="h-[1px] w-full border border-dashed"
                  style={{ borderColor: custom.color[3].custom.color }}
                />
              ) : (
                ""
              )}
              {dish &&
                dish.Comment.map((item, index) => (
                  <div
                    className={`mt-3
                            ${
                              custom &&
                              findFont(custom.police[3].custom.fontFamily)
                            }
                            ${custom.police[3].custom.bold ? "font-bold" : ""}
                              ${custom.police[3].custom.italic ? "italic" : ""}
                          `}
                    style={{
                      fontSize: `${custom.police[3].custom.fontSize}px`,
                      letterSpacing: `${custom.police[3].custom.letterSpacing}px`,
                      color: custom.color[2].custom.color,
                    }}
                    key={index}
                  >
                    {item.content.split("\n").map((str, index) => (
                      <p key={index}>{str}</p>
                    ))}
                  </div>
                ))}
              {dish && dish.Comment.length && dish.title != "Commentaires" ? (
                <div
                  className="mt-3 h-[1px] w-full border border-dashed"
                  style={{ borderColor: custom.color[3].custom.color }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
    </div>
  );
}
