import Button from "./Button";
import joinClasses from "helpers/joinClasses";
import { Icons } from "components/atoms";

export function SelectButton({
  children,
  ButtonType,
  isActive,
  className = "",
  onClick,
}) {
  if (isActive) {
    return (
      <Button
        onClick={onClick}
        className={joinClasses(
          className,
          "mt-30 pb-moins1 mb-7 flex w-max items-center  justify-center gap-3 bg-grey pl-5 pr-2  pt-1.5  font-poppins text-sm font-light text-black shadow-double md:gap-10 md:pl-9"
        )}
      >
        {children}
        <Icons
          name="valide"
          className="border-green-500 flex items-center justify-center border-4 border-solid border-opacity-100  py-1 px-0.5 text-green"
          height="15"
        />
      </Button>
    );
  }
  return (
    <Button
      onClick={onClick}
      className={joinClasses(
        className,
        "md:mt-30 mt-5 mb-7 w-max bg-white py-3 px-9 font-poppins text-sm font-light text-black shadow-light hover:shadow hover:duration-200 "
      )}
    >
      {children}
    </Button>
  );
}
