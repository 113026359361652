import { MenuSectionsPreview } from "components";
import { findFont } from "helpers/findDataCustom";
import { useState, useRef, useEffect } from "react";
import { FormulesPreview } from "components";
import "../..";
import { getCloseSection } from "helpers/getCloseSection";
import { useLocation } from "react-router";

export function MenuPreview({ data, custom, selectedSection = null }) {
  const [secSelectedIndex, setSecSelectedIndex] = useState(selectedSection);
  const [secSelected, setSecSelected] = useState(false);
  const location = useLocation();
  const navBarScroll = useRef(null);
  const navBarParent = useRef(null);

  const getScrollNavBarWidth = () => {
    if (
      navBarScroll &&
      navBarScroll.current &&
      navBarParent &&
      navBarParent.current
    ) {
      if (navBarScroll.current.clientWidth > window.screen.width) {
        navBarParent.current.style.width = "100vw";
        navBarParent.current.style.overflow = "auto";
        navBarParent.current.style.justifyContent = "flex-start";
        navBarParent.current.style.display = "flex";
      } else {
        navBarParent.current.style.display = "flex";
        navBarParent.current.style.overflow = "none";
        navBarParent.current.style.justifyContent = "flex-start";
      }
    }
  };

  useEffect(() => {
    getScrollNavBarWidth();
    let closeSection = "";

    // scroll to
    navBarParent.current.addEventListener("click", function (e) {
      if (location.state && location.state.section) setSecSelected(true);
      window.scroll(
        0,
        document.querySelector(e.target.id.replace("-trigger", "")).offsetTop
      );
    });

    window.addEventListener("scroll", function () {
      closeSection = getCloseSection();
      if (closeSection && closeSection.length) {
        setSecSelectedIndex(closeSection);
      }
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getScrollNavBarWidth);
  });

  const getColorforNavbar = () => {
    return {
      active: {
        backgroundColor: custom.color[1].custom.color || "#fff",
        color: custom.color[0].custom.color || "#000",
      },
      inactive: {
        backgroundColor: "transparent",
        color: custom.color[1].custom.color || "#fff",
      },
    };
  };

  return (
    <>
      <style>
        {location.state && location.state.section && !secSelected
          ? `html {scroll-behavior: initial;}`
          : ""}
      </style>
      {data && (
        <div className="w-full  ">
          {data.MenuSection && data.MenuSection.length > 0 && (
            <div
              ref={navBarParent}
              className="hideScrollBar anim-scroll sticky top-0 z-10 m-auto flex  w-full justify-start  overflow-auto  border-b-2 border-solid border-black"
              style={{
                borderColor: custom.color[1].custom.color,
                backgroundColor: custom.color[0].custom.color,
              }}
            >
              <div
                ref={navBarScroll}
                className={`   sticky top-0 z-10 m-auto flex h-[40px] items-end bg-white text-center
              ${findFont(custom.police[0].custom.fontFamily)}
                  ${custom.police[0].custom.bold ? "font-bold" : "font-normal"}
                  ${custom.police[0].custom.italic ? "italic" : ""}
              `}
                style={{
                  backgroundColor: custom.color[0].custom.color,
                  borderColor: custom.color[1].custom.color,
                }}
              >
                {data.MenuSection &&
                  data.MenuSection.sort((a, b) =>
                    a.positionId > b.positionId ? 1 : -1
                  ).map((row, index) => (
                    <a
                      className={`md:text-md flex h-10 min-w-max items-center px-3 text-sm ${
                        ((!secSelectedIndex && `section-${index}` === 0) ||
                          secSelectedIndex === `section-${row.id}`) &&
                        "bg-black text-white "
                      }  cursor-pointer `}
                      style={
                        (!secSelectedIndex && index === 0) ||
                        secSelectedIndex === `section-${row.id}`
                          ? { ...getColorforNavbar().active }
                          : { ...getColorforNavbar().inactive }
                      }
                      id={`#section-${row.id}-trigger`}
                      key={index}
                    >
                      {row.title}
                    </a>
                  ))}
                {data.Formule &&
                  data.Formule.map((row, index) => (
                    <a
                      key={row.id}
                      className={`flex h-[40px] min-w-max cursor-pointer items-center  px-3 `}
                      style={
                        secSelectedIndex === `formules${row.id}`
                          ? { ...getColorforNavbar().active }
                          : { ...getColorforNavbar().inactive }
                      }
                      id={`#formules${row.id}-trigger`}
                    >
                      {row.name}
                    </a>
                  ))}
              </div>
            </div>
          )}

          {/* <div className="border-b w-full" /> */}
          <MenuSectionsPreview data={data.MenuSection} custom={custom} />
          <FormulesPreview formules={data.Formule} custom={custom} />
        </div>
      )}
    </>
  );
}
